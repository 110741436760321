import { SxProps } from "@mui/material";
import Table from "@mui/material/Table/Table";
import TableBody from "@mui/material/TableBody/TableBody";
import TableContainer from "@mui/material/TableContainer/TableContainer";
import TableHead from "@mui/material/TableHead/TableHead";
import { ReactNode } from "react";
import { generalBrightGrey } from "../../../styles/colors";

interface ComercialTableProps {
  header: ReactNode;
  body: ReactNode;
}

const ComercialTable = (props: ComercialTableProps) => {
  const { header, body } = props;

  return (
    <TableContainer sx={styles.storeItemContainer}>
      <Table>
        <TableHead>{header}</TableHead>
        <TableBody>{body}</TableBody>
      </Table>
    </TableContainer>
  );
};

export default ComercialTable;

const styles = {
  storeItemContainer: {
    margin: "30px 0px",
    width: "100%",
    border: `1px solid ${generalBrightGrey}`,
    borderRadius: "15px",
  } as SxProps,
};
