import { ICartItem } from "@/services/api/CartItem/type";
import cartApi from "../../../services/api/Cart";
import cartItemApi from "../../../services/api/CartItem";

export const handleRemoveCartItem = async (itemId: string) => {
  if (confirm(`Tem certeza que deseja excluir item do carrinho?`)) {
    try {
      await cartItemApi.deleteCartItem(itemId);
    } catch (error) {
      console.error("Error removing cart item", error);
    }
  }
};

export const handleClearCartClick = async (cartId: string) => {
  if (confirm("Tem certeza que deseja excluir todos os itens do carrinho?")) {
    try {
      await cartApi.deleteCart(cartId);
    } catch (error) {
      console.warn("Error cleaning items from cart: ", error);
    }
  }
};

export const handleRemoveAllCartItems = async (items: ICartItem[]) => {
  if (confirm(`Tem certeza que deseja excluir todos os itens do carrinho?`)) {
    try {
      await Promise.all(
        items.map((item) => cartItemApi.deleteCartItem(item.id))
      );
    } catch (error) {
      console.error("Error removing cart item", error);
    }
  }
};
