import { Box, CircularProgress, List, SxProps } from "@mui/material";
import { IStore } from "../../../services/api/Store/types";
import { useState } from "react";
import NearbyStoreListItem from "./NearbyStoreListItem";
import { useNavigate } from "react-router-dom";

interface NearbyServicesList {
  stores: IStore[];
  userCoordinates: [number, number];
  mapReference: any;
}

const NearbyStoreList = (props: NearbyServicesList) => {
  const navigate = useNavigate();

  const { stores, userCoordinates } = props;

  const handleListStoreClick = (index: number) =>
    navigate(`/store?id=${stores[index].id}`);

  return (
    <Box sx={styles.container}>
      {stores.length > 0 ? (
        <List sx={styles.list}>
          {stores.map((store: IStore, index: number) => (
            <NearbyStoreListItem
              onClick={() => handleListStoreClick(index)}
              store={store}
              userCoordinates={userCoordinates}
              isSelected={false}
              key={store.id}
            />
          ))}
        </List>
      ) : (
        <Box sx={styles.loadingContainer}>
          <CircularProgress />
        </Box>
      )}
    </Box>
  );
};

export default NearbyStoreList;

const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    scrollBehavior: "smooth",
  } as SxProps,

  list: {
    display: "flex",
    flexDirection: "column",
    gap: "5px",
    padding: "5px",
  } as SxProps,

  loadingContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    margin: "100px",
  } as SxProps,
};
