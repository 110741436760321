import { NavigateFunction } from "react-router-dom";
import { IContext } from "../context/AuthProvider/types";

export const handleLogout = (auth: IContext, navigate: NavigateFunction) => {
  localStorage.removeItem("token");
  auth.logout();
  navigate("/login");
};

export const areStringifiedObjectsEqual = (a: any, b: any) =>
  JSON.stringify(a) === JSON.stringify(b);

export const pushItemToArrayIfItsNotIncluded = (array: any[], newItem: any) =>
  !array.includes(newItem) && array.push(newItem);
