import { useEffect, useState } from "react";
import IExcursion from "../../services/api/Excursion/IExcursion";
import { fetchExcursionsFromStore } from "../../utils/fetchEntities";
import { areStringifiedObjectsEqual } from "../../utils/miscelaneous";

const useFetchExcursionsFromStore = (storeId: string | null) => {
  const [excursions, setExcursions] = useState<IExcursion[] | null>(null);
  const [excursionsLoading, setExcursionsLoading] = useState(false);

  const fetchExcursions = () => {
    if (!storeId) return;

    setExcursionsLoading(true);

    fetchExcursionsFromStore(storeId)
      .then((response) => {
        !areStringifiedObjectsEqual(response, excursions) &&
          setExcursions(response);
      })
      .finally(() => setExcursionsLoading(false));
  };

  useEffect(() => {
    fetchExcursions();
  }, [storeId]);

  return { excursions, excursionsLoading, fetchExcursions };
};

export default useFetchExcursionsFromStore;
