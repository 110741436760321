import { useEffect, useState } from "react";
import { ICart } from "../../services/api/Cart/types";
import { useAuth } from "../../context/AuthProvider/useAuth";
import { fetchShoppingCart } from "../../utils/fetchEntities";

const useFetchCart = () => {
  const { user } = useAuth();

  const [shoppingCart, setShoppingCart] = useState<ICart | null>(null);
  const [isCartLoading, setIsCartLoading] = useState(false);

  const fetchUserShoppingCart = () => {
    setIsCartLoading(true);

    fetchShoppingCart(user.id).then((response) => setShoppingCart(response));

    setIsCartLoading(false);
  };

  useEffect(() => {
    if (user.id) fetchUserShoppingCart();
  }, [user.id]);

  return { shoppingCart, isCartLoading, fetchUserShoppingCart };
};

export default useFetchCart;
