export const resizeImage = (
  image: HTMLCanvasElement,
  width: number,
  height: number
): HTMLCanvasElement => {
  const resizedImage = document.createElement("canvas");
  const context = resizedImage.getContext("2d");

  const scaleX = width / image.width;
  const scaleY = height / image.height;

  const scale = Math.min(scaleX, scaleY);

  const newWidth = image.width * scale;
  const newHeight = image.height * scale;

  const offsetX = (width - newWidth) / 2;
  const offsetY = (height - newHeight) / 2;

  resizedImage.width = width;
  resizedImage.height = height;

  context.clearRect(0, 0, resizedImage.width, resizedImage.height);
  context.drawImage(image, offsetX, offsetY, newWidth, newHeight);

  return resizedImage;
};

export const resizeMultipleImages = (
  images: HTMLCanvasElement[],
  width: number,
  height: number
): HTMLCanvasElement[] => {
  const resizedImages: HTMLCanvasElement[] = [];

  images.forEach((image) => {
    resizedImages.push(resizeImage(image, width, height));
  });

  return resizedImages;
};
