import { Dispatch, ReactNode, SetStateAction } from "react";
import { handleSelectHTMLCanvaImage } from "../../../utils/image/imageFunctions";
import { IconButton } from "@mui/material";

interface AvatarImageIconButtonProps {
  icon: ReactNode;
  setImages: Dispatch<SetStateAction<HTMLCanvasElement[]>>;
}

const AvatarImageIconButton = (props: AvatarImageIconButtonProps) => {
  return (
    <IconButton
      component="span"
      style={{ position: "relative", textTransform: "none", width: "100%" }}
      color="primary"
    >
      <input
        type="file"
        accept="image/*"
        style={{
          position: "absolute",
          left: 0,
          top: 0,
          width: "100%",
          height: "100%",
          opacity: 0,
          cursor: "pointer",
        }}
        onChange={(e) => handleSelectHTMLCanvaImage(e, props.setImages)}
      />
      {props.icon}
    </IconButton>
  );
};

export default AvatarImageIconButton;
