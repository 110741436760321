import { HashRouter, Routes, Route, BrowserRouter } from "react-router-dom";
import AccountDetails from "../pages/common/AccountDetails";
import PasswordRecovery from "../pages/common/PasswordRecovery";
import Register from "../pages/common/Register";
import ComercialAppointments from "../pages/petAndBeauty/Comercial/Appointments";
import AddEmployee from "../pages/petAndBeauty/Comercial/EmployeesPages/AddEmployee";
import EditEmployee from "../pages/petAndBeauty/Comercial/EmployeesPages/EditEmployee";
import EmployeeView from "../pages/petAndBeauty/Comercial/EmployeesPages/EmployeeView";
import Employees from "../pages/petAndBeauty/Comercial/EmployeesPages/Employees";
import MyWorkscales from "../pages/petAndBeauty/Comercial/MyWorkscales";
import AddService from "../pages/petAndBeauty/Comercial/ServicesPages/AddService";
import EditService from "../pages/petAndBeauty/Comercial/ServicesPages/EditService";
import ServiceDetails from "../pages/petAndBeauty/Comercial/ServicesPages/ServiceDetails";
import ServicesPage from "../pages/petAndBeauty/Comercial/ServicesPages/ServicesPage";
import AddStore from "../pages/petAndBeauty/Comercial/StoresPages/AddStore";
import EditStore from "../pages/petAndBeauty/Comercial/StoresPages/EditStore";
import Stores from "../pages/petAndBeauty/Comercial/StoresPages/Stores";
import CartPayment from "../pages/petAndBeauty/Client/CartPayment";
import ClientAppointments from "../pages/petAndBeauty/Client/ClientAppointments";
import EmployeePage from "../pages/petAndBeauty/Client/EmployeePage";
import ScheduilingPage from "../pages/petAndBeauty/Client/ScheduilingPage";
import SearchPage from "../pages/petAndBeauty/Client/SearchPage";
import ServicePage from "../pages/petAndBeauty/Client/ServicePage";
import StorePage from "../pages/petAndBeauty/Client/StorePage";
import PetLandingPage from "../pages/petAndBeauty/LandingPages/pet";
import AddAgency from "../pages/turism/Comercial/Agencies/AddAgency";
import Agencies from "../pages/turism/Comercial/Agencies/Agencies";
import AgencyDetails from "../pages/turism/Comercial/Agencies/AgencyDetails";
import EditAgency from "../pages/turism/Comercial/Agencies/EditAgency";
import AddExcursion from "../pages/turism/Comercial/Excursions/AddExcursion";
import EditExcursion from "../pages/turism/Comercial/Excursions/EditExcursion";
import ExcursionDetails from "../pages/turism/Comercial/Excursions/ExcursionDetails";
import Excursions from "../pages/turism/Comercial/Excursions/Excursions";
import { DEMANDORIA_SUBDOMAINS, CURRENT_SUBDOMAIN } from "../utils/stringUtils";
import Home from "../pages/common/Home";
import ShoppingCart from "../pages/common/ShoppingCart";
import Login from "../pages/common/Login";
import TurismLandingPage from "../pages/turism/LandingPage";
import BeautyLandingPage from "../pages/petAndBeauty/LandingPages/beauty";
import GeneralLandingPage from "../pages/common/GeneralLandingPage";
import ExcursionPage from "../pages/turism/Client/ExcursionPage";
import TourismSearchPage from "../pages/turism/Client/SearchPage";
import TourismStorePage from "../pages/turism/Client/TurismStorePage";
import StoreDetails from "../pages/petAndBeauty/Comercial/StoresPages/StoreDetails";
import ManagerStoresProvider from "../context/ManagerStores/provider";
import { useAuth } from "@/context/AuthProvider/useAuth";
import Checkout from "@/pages/turism/Client/Checkout";
import ExcursionsHistory from "@/pages/turism/Client/ExcursionsHistory";
import ProtectedComponent from "@/components/general/ProtectedComponent";

const petAndBeautyClientRoutes = (
  <>
    <Route
      path="/stores"
      element={
        <ManagerStoresProvider>
          <Stores />
        </ManagerStoresProvider>
      }
    />
    <Route
      path="/stores/add"
      element={
        <ManagerStoresProvider>
          <AddStore />
        </ManagerStoresProvider>
      }
    />
    <Route
      path="/stores/edit"
      element={
        <ManagerStoresProvider>
          <EditStore />
        </ManagerStoresProvider>
      }
    />
    <Route
      path="/stores/details"
      element={
        <ManagerStoresProvider>
          <StoreDetails />
        </ManagerStoresProvider>
      }
    />
    <Route
      path="/services"
      element={
        <ManagerStoresProvider>
          <ServicesPage />
        </ManagerStoresProvider>
      }
    />
    <Route
      path="/services/add"
      element={
        <ManagerStoresProvider>
          <AddService />
        </ManagerStoresProvider>
      }
    />
    <Route
      path="/services/edit"
      element={
        <ManagerStoresProvider>
          <EditService />
        </ManagerStoresProvider>
      }
    />
    <Route
      path="/services/details"
      element={
        <ManagerStoresProvider>
          <ServiceDetails />
        </ManagerStoresProvider>
      }
    />
    <Route
      path="/employees"
      element={
        <ManagerStoresProvider>
          <Employees />
        </ManagerStoresProvider>
      }
    />
    <Route
      path="/employees/add"
      element={
        <ManagerStoresProvider>
          <AddEmployee />
        </ManagerStoresProvider>
      }
    />
    <Route
      path="/employees/edit"
      element={
        <ManagerStoresProvider>
          <EditEmployee />
        </ManagerStoresProvider>
      }
    />
    <Route
      path="/employees/details"
      element={
        <ManagerStoresProvider>
          <EmployeeView />
        </ManagerStoresProvider>
      }
    />
    <Route
      path="/my-workscales"
      element={
        <ManagerStoresProvider>
          <MyWorkscales />
        </ManagerStoresProvider>
      }
    />
    <Route
      path="/appointments"
      element={
        <ManagerStoresProvider>
          <ComercialAppointments />
        </ManagerStoresProvider>
      }
    />
    <Route path="/search" element={<SearchPage />} />
    <Route path="/store" element={<StorePage />} />
    <Route path="/service" element={<ServicePage />} />
    <Route path="/my-appointments" element={<ClientAppointments />} />
    <Route path="/employee" element={<EmployeePage />} />
    <Route path="/scheduiling" element={<ScheduilingPage />} />
    <Route path="/payment" element={<CartPayment />} />
  </>
);

const turismRoutes = <></>;

const AppRoutes = () => {
  return (
    <BrowserRouter>
      <Routes>
        {/* {CURRENT_SUBDOMAIN === DEMANDORIA_SUBDOMAINS[0] ? (
          <>
            {petAndBeautyClientRoutes}
            <Route path="/" element={<BeautyLandingPage />} />
          </>
        ) : CURRENT_SUBDOMAIN === DEMANDORIA_SUBDOMAINS[1] ? (
          <>
            {petAndBeautyClientRoutes}
            <Route path="/" element={<PetLandingPage />} />
          </>
        ) : CURRENT_SUBDOMAIN === DEMANDORIA_SUBDOMAINS[2] ? (
          <>
            {turismRoutes}
            <Route path="/" element={<TurismLandingPage />} />
          </>
        ) : null} */}

        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/password-recovery" element={<PasswordRecovery />} />
        <Route path="/" element={<TurismLandingPage />} />

        <Route path="" element={<ProtectedComponent />}>
          <Route path="/home" element={<Home />} />
          <Route path="/user/details" element={<AccountDetails />} />
          <Route path="/cart" element={<ShoppingCart />} />
          <Route path="/cart/payment" element={<CartPayment />} />
          <Route
            path="/agencies"
            element={
              <ManagerStoresProvider>
                <Agencies />
              </ManagerStoresProvider>
            }
          />
          <Route
            path="/agencies/add"
            element={
              <ManagerStoresProvider>
                <AddAgency />
              </ManagerStoresProvider>
            }
          />
          <Route
            path="/agencies/edit"
            element={
              <ManagerStoresProvider>
                <EditAgency />
              </ManagerStoresProvider>
            }
          />
          <Route
            path="/agencies/details"
            element={
              <ManagerStoresProvider>
                <AgencyDetails />
              </ManagerStoresProvider>
            }
          />
          <Route
            path="/excursions"
            element={
              <ManagerStoresProvider>
                <Excursions />
              </ManagerStoresProvider>
            }
          />
          <Route
            path="/excursions/add"
            element={
              <ManagerStoresProvider>
                <AddExcursion />
              </ManagerStoresProvider>
            }
          />
          <Route
            path="/excursions/edit"
            element={
              <ManagerStoresProvider>
                <EditExcursion />
              </ManagerStoresProvider>
            }
          />
          <Route
            path="/excursions/details"
            element={
              <ManagerStoresProvider>
                <ExcursionDetails />
              </ManagerStoresProvider>
            }
          />
          <Route path="/search" element={<TourismSearchPage />} />
          <Route path="/store" element={<TourismStorePage />} />
          <Route path="/excursion" element={<ExcursionPage />} />
          <Route path="/checkout" element={<Checkout />} />
          <Route path="/history" element={<ExcursionsHistory />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default AppRoutes;
