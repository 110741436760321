import { Box, Link, SxProps, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { sharedStyles } from "../../../styles/shared";
import image from "@/assets/img/turism-landing-page.jpg";
import { PrimaryButton } from "@/components/general/buttons/PrimaryButton";
import LoginIcon from "@mui/icons-material/Login";
import BrandLogo from "@/components/turism/BrandLogo";
import { filteredTurismSecondary } from "@/styles/colors";
import turist from "@/assets/img/turist.png";
import turistGuide from "@/assets/img/turistGuide.png";
import PeregrinFooter from "@/components/general/Footer/PeregrinFooter";
import peregrinFalcon from "@/assets/img/peregrin-falcon1.jpg";

const TurismLandingPage = () => {
  const navigate = useNavigate();

  const roleOptions = [
    {
      image: turist,
      label: "Cliente",
      text: "Explore destinos surpreendentes com o nosso vasto catálogo de excursões.",
    },
    {
      image: turistGuide,
      label: "Comercial",
      text: "Cadastre suas agências e excursões e conecte-se com viajantes em busca de experiências únicas.",
    },
  ];

  const header = (
    <Box sx={headerStyles}>
      <BrandLogo
        style={{
          width: 40,
          height: 40,
        }}
        color={filteredTurismSecondary}
      />

      <Typography>
        Já possui conta?{" "}
        <Typography
          onClick={() => navigate("/login")}
          sx={{ cursor: "pointer", fontWeight: "bold" }}
          color="primary"
          variant="span"
        >
          Logar-se
        </Typography>
      </Typography>
    </Box>
  );

  const firstSection = (
    <Box
      sx={{
        ...sharedStyles.innerContainer,
        minHeight: "92vh",
        textAlign: "center",
        backgroundImage: `url(${image})`,
        objectFit: "cover",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "center",
        color: "secondary.main",
        paddingX: { xs: 5, md: 5 },
      }}
    >
      {header}

      <Box
        sx={{
          ...sharedStyles.verticalContent,
          alignItems: "center",
          gap: 5,
          width: "100%",
        }}
      >
        <Box sx={sharedStyles.verticalContent}>
          <Typography variant="h4" fontWeight="bold">
            Eleve suas aventuras com o{" "}
            <Typography
              color="primary"
              variant={"span"}
              sx={{
                fontFamily: "Poppins",
                fontWeight: 600,
                fontStyle: "italic",
              }}
            >
              Peregrin
            </Typography>
            .
          </Typography>

          <Typography>
            Transforme suas viagens dos sonhos em realidade com nossos pacotes
            de excursão
          </Typography>
        </Box>

        <Box
          sx={{
            width: "100%",
            maxWidth: "350px",
          }}
        >
          <PrimaryButton
            label="Cadastrar-se"
            icon={<LoginIcon />}
            onClickAction={() => navigate("/register")}
            styles={{ fontWeight: "bold", color: "secondary.main" }}
          />
        </Box>
      </Box>
    </Box>
  );

  const secondSection = (
    <Box
      sx={{
        ...sharedStyles.innerContainer,
        minHeight: "70vh",
        width: "100%",
        textAlign: "center",
        paddingX: { xs: 5, md: 5 },
        backgroundColor: "secondary.main",
        color: "text.primary",
      }}
    >
      <Typography variant="h5" fontWeight="bold">
        Seja você um viajante ou um agente de excursões
      </Typography>

      <Box
        sx={{
          ...sharedStyles.horizontalContent,
          flexDirection: { xs: "column", lg: "row" },
          gap: { xs: 10, lg: 5 },
          width: "100%",
          justifyContent: "space-evenly",
        }}
      >
        {roleOptions.map((option, index) => (
          <Box
            maxWidth="400px"
            width="100%"
            sx={{ ...sharedStyles.verticalContent, alignItems: "center" }}
          >
            <img
              src={option.image}
              alt={`Role ${index} icon`}
              style={{ width: 130 }}
            />

            <Box sx={sharedStyles.verticalContent}>
              <Typography variant="h6" fontWeight="bold">
                {option.label}
              </Typography>
              <Typography>{option.text}</Typography>
            </Box>
          </Box>
        ))}
      </Box>
    </Box>
  );

  const aboutSection = (
    <Box
      sx={{
        backgroundColor: "primary.main",
        color: "secondary.main",
      }}
    >
      <Box
        sx={{
          ...sharedStyles.horizontalContent,
          width: "100%",
          height: "100%",
        }}
      >
        <Box
          sx={{
            display: { xs: "none", lg: "block" },
            height: "60vh",
          }}
        >
          <img
            src={peregrinFalcon}
            style={{
              height: "100%",
            }}
          />
        </Box>

        <Box
          sx={{
            ...sharedStyles.verticalContent,
            alignItems: "center",
            textAlign: "center",
            width: "100%",
            paddingX: { xs: 5, lg: 10 },
            paddingY: { xs: 10, lg: 5 },
            gap: 5,
          }}
        >
          <Typography variant="h5" fontWeight="bold">
            Sobre nós
          </Typography>

          <Typography textAlign="justify">
            O Peregrin é um marketplace de excursões que conecta viajantes a uma
            ampla variedade de pacotes turísticos. Nosso objetivo é tornar mais
            simples e intuitivo o processo de reserva de excursões,
            proporcionando uma experiência segura, prática e acessível para quem
            deseja explorar novos destinos.
          </Typography>

          <Box
            sx={{
              ...sharedStyles.verticalContent,
              gap: 0,
            }}
          >
            <Typography>Versão: 1.0.0</Typography>

            <Typography>
              Contato:{" "}
              <Link
                href="mailto:peregrin@gmail.com"
                underline="none"
                color="secondary"
                sx={{ fontWeight: "bold" }}
              >
                peregrin@gmail.com
              </Link>
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );

  return (
    <>
      <Box sx={sharedStyles.generalContainer}>
        {firstSection}

        {secondSection}

        {aboutSection}

        <PeregrinFooter />
      </Box>
    </>
  );
};

const headerStyles: SxProps = {
  ...sharedStyles.horizontalContent,
  justifyContent: "space-between",
  width: "100%",
  position: "absolute",
  gap: 3,
  top: 0,
  paddingX: 2,
  paddingY: 2,
};

export default TurismLandingPage;
