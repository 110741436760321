import DOMPurify from "dompurify";

interface RichTextRendererProps {
  htmlContent: string;
}

const RichTextRenderer = (props: RichTextRendererProps) => {
  const { htmlContent } = props;

  const sanitizedHtml = DOMPurify.sanitize(htmlContent);
  
  return (
    <div
      dangerouslySetInnerHTML={{ __html: sanitizedHtml }}
      style={{ textAlign: "justify" }}
    />
  );
};

export default RichTextRenderer;
