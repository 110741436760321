import {
  Box,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SxProps,
  TableCell,
  TableRow,
  Avatar,
  ListItemText,
  Tooltip,
  IconButton,
  Typography,
  useMediaQuery,
  Button,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import ComercialDrawer from "../../../../../components/pet-beauty/CormercialDrawer";
import { cleanLocalStorage } from "../../../../../utils/cleanLocalStorage";
import useManagerStores from "../../../../../hooks/context-hooks/useManagerStores";
import useFetchManagerStores from "../../../../../hooks/entities/useFetchManagerStores";
import ComercialInterfaceHeader from "../../../../../components/comercial/ComercialInterfaceHeader";
import { useState } from "react";
import { PrimaryButton } from "../../../../../components/general/buttons/PrimaryButton";
import { handleSelectStore } from "../../StoresPages/Stores/functions";
import useFetchServiceStoreFromStore from "../../../../../hooks/entities/useFetchServiceStoreFromStore";
import { priceFormatter } from "../../../../../utils/priceFormatter";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from "@mui/icons-material/Visibility";
import {
  handleDeleteServiceStore,
  handleEditServiceStoreClick,
  handleServiceStoreDetailsClick,
} from "./functions";
import defaultServiceImage from "../../../../../assets/svg/defaultService.svg";
import ComercialTable from "../../../../../components/comercial/ComercialTable";
import { sharedStyles } from "../../../../../styles/shared";
import AddIcon from "@mui/icons-material/Add";

const CURRENT_DAY_INDEX = new Date().getDay();

const ServicesPage = () => {
  cleanLocalStorage();

  const navigate = useNavigate();

  const managerStoresContext = useManagerStores();
  const { stores } = managerStoresContext;
  const { loadingManagerStores } = useFetchManagerStores();

  const [selectedStoreIndex, setSelectedStoreIndex] = useState(0);

  const { serviceStores, fetchServiceStores, loading } =
    useFetchServiceStoreFromStore(stores && stores[selectedStoreIndex]?.id);

  const isMobile = useMediaQuery("(max-width:800px)");

  const handleAddServiceClick = () => {
    handleSelectStore(stores[selectedStoreIndex]);
    navigate("/services/add");
  };

  const servicesTableHeader = (
    <>
      {!loading && serviceStores && (
        <TableRow>
          <TableCell align="left">Serviço</TableCell>
          <TableCell align="right">Ações</TableCell>
        </TableRow>
      )}
    </>
  );

  const servicesTableBody = (
    <>
      {!loading ? (
        <>
          {serviceStores?.length > 0 ? (
            <>
              {serviceStores.map((serviceStore) => (
                <TableRow
                  key={serviceStore.id}
                  sx={{
                    "&:last-child td, &:last-child th": {
                      border: 0,
                    },
                  }}
                >
                  <TableCell
                    component="th"
                    sx={{ ...sharedStyles.horizontalContent }}
                    align="left"
                  >
                    <Avatar
                      src={serviceStore.imagePreview[0] ?? defaultServiceImage}
                      sx={sharedStyles.tableAvatarStyle}
                    />

                    <ListItemText
                      primary={serviceStore.Service.name}
                      secondary={`Preço (Hoje): R$ ${priceFormatter(
                        serviceStore.priceDay[CURRENT_DAY_INDEX]
                      )}`}
                      primaryTypographyProps={{
                        fontWeight: 500,
                      }}
                    />
                  </TableCell>
                  <TableCell align="right">
                    <Tooltip title="Detalhes">
                      <IconButton
                        color="primary"
                        onClick={() => {
                          handleServiceStoreDetailsClick(serviceStore);
                          navigate(`/services/details?id=${serviceStore.id}`);
                        }}
                      >
                        <VisibilityIcon />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Editar">
                      <IconButton
                        color="primary"
                        onClick={() => {
                          handleEditServiceStoreClick(serviceStore);
                          navigate(`/services/edit?id=${serviceStore.id}`);
                        }}
                      >
                        <EditIcon />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Excluir">
                      <IconButton
                        color="primary"
                        onClick={() =>
                          handleDeleteServiceStore(
                            serviceStore,
                            fetchServiceStores
                          )
                        }
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))}
            </>
          ) : (
            <TableRow>
              <TableCell>
                <Typography color="text.secondary" variant="body2">
                  Não há serviços cadastrados neste estabelecimento
                </Typography>
              </TableCell>
            </TableRow>
          )}
        </>
      ) : (
        <TableRow>
          <TableCell align="center">
            <CircularProgress />
          </TableCell>
        </TableRow>
      )}
    </>
  );

  const renderServicesPageContent = (
    <Box>
      {!loadingManagerStores ? (
        <Box sx={styles.container}>
          {stores && stores.length > 0 ? (
            <>
              <ComercialInterfaceHeader
                title={`Serviços de ${
                  stores[selectedStoreIndex]?.name ?? "Carregando..."
                }`}
              />
              <Box>
                <Box sx={styles.filterAndAddContainer}>
                  <Box sx={{ maxWidth: "150px", width: "100%" }}>
                    <FormControl fullWidth>
                      <InputLabel>Estabelecimento</InputLabel>
                      <Select
                        label="Estabelecimento"
                        value={stores && selectedStoreIndex}
                        onChange={(e: any) =>
                          setSelectedStoreIndex(e.target.value as number)
                        }
                        size="small"
                      >
                        {stores &&
                          stores.map((store, index) => (
                            <MenuItem value={index} key={index}>
                              {store?.name}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </Box>
                  <Box>
                    {!isMobile ? (
                      <PrimaryButton
                        label="Adicionar serviço"
                        onClickAction={handleAddServiceClick}
                        icon={<AddIcon />}
                      />
                    ) : (
                      <Button
                        onClick={handleAddServiceClick}
                        color="primary"
                        variant="contained"
                        disableElevation
                      >
                        <AddIcon />
                      </Button>
                    )}
                  </Box>
                </Box>

                <Box>
                  <ComercialTable
                    header={servicesTableHeader}
                    body={servicesTableBody}
                  />
                </Box>
              </Box>
            </>
          ) : (
            <Typography textAlign="justify">
              Para cadastrar serviços é necessário{" "}
              <Typography
                color="primary"
                fontWeight="bold"
                onClick={() => navigate("/stores/add")}
                sx={{ cursor: "pointer" }}
                variant="span"
              >
                cadastrar um estabelecimento
              </Typography>
              .
            </Typography>
          )}
        </Box>
      ) : (
        <Box sx={styles.loadingContainer}>
          <CircularProgress />
        </Box>
      )}
    </Box>
  );

  return (
    <Box sx={sharedStyles.sharedMainContainer}>
      <ComercialDrawer content={renderServicesPageContent} />
    </Box>
  );
};

export default ServicesPage;

const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    gap: "30px",
  } as SxProps,

  avatarStyle: {
    width: "60px",
    height: "60px",
  },

  filterAndAddContainer: {
    display: "flex",
    justifyContent: "space-between",
    gap: "50px",
    alignItems: "center",
  } as SxProps,

  loadingContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    margin: "50px 0px",
  },
};
