import axios from "axios";
import { getTokenLocalStorage } from "../context/AuthProvider/util";
import { refreshToken } from "./api/Auth";
import userApi from "./api/User";
import { DEMANDORIA_SUBDOMAINS, getDemandoriaSubdomain } from "../utils/stringUtils";

//${getDemandoriaSubdomain()}
export const Api = axios.create({
  baseURL: `https://${DEMANDORIA_SUBDOMAINS[2]}.demandoria.com/api/v1/`,
});

Api.interceptors.request.use((config) => {
  const token = getTokenLocalStorage();
  if (token) config.headers!.Authorization = "Bearer " + token;

  return config;
});

Api.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const originalRequest = error.config;
    const token = getTokenLocalStorage();
    if (error.response.status === 401 && token && !originalRequest._retry) {
      originalRequest._retry = true;
      console.log("refreshing token");
      void refreshToken(token).then((res) => {
        console.log(res);
      });
    }
    return Promise.reject(error);
  }
);

export { userApi };
