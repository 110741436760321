/* ===== COMMON ===== */
export const generalBlack = "#242424";
export const generalDarkGrey = "#8F8F8F";
export const generalBrightGrey = "#DADADA";
export const generalWhite = "#FFFFFF";
/* ================== */

/* ===== DEMANDORIA ===== */
export const dmYellow = "#FFCC8C";
export const dmBrighterOrange = "#F99446";
export const dmPrimaryOrange = "#E86740";
/* ====================== */

/* ===== TURISM ===== */
export const turismPrimary = "#FF9100";
export const turismSecondary = "#FFFBE6";

export const filteredTurismPrimary =
  "invert(71%) sepia(44%) saturate(5755%) hue-rotate(360deg) brightness(102%) contrast(104%)";
export const filteredTurismSecondary =
  "invert(99%) sepia(53%) saturate(423%) hue-rotate(16deg) brightness(109%) contrast(100%)";
/* ====================== */

/* ===== PET ===== */
export const petPrimaryGreen = "#16697A";
export const petSecondaryGreen = "#91DDCF";
/* =============== */

/* ===== BEAUTY ===== */
export const beautyPrimaryPurple = "#8F3985";
export const beautySecondaryPurple = "#FFADDA";
/* ================== */

export const validAvailability = "#ACFCD9";
export const invalidAvailability = "#FCACC5";
export const validAvailabilityBorder = "#4D994F";
export const invalidAvailabilityBorder = "#9A275A";

export const darkTextOverlay = "rgba(0,0,0,0.7)";
