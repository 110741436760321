import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Menu from "@mui/material/Menu";
import AccountCircle from "@mui/icons-material/AccountCircle";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import MoreIcon from "@mui/icons-material/MoreVert";
import { useAuth } from "../../../../context/AuthProvider/useAuth";
import { useNavigate } from "react-router-dom";
import ManageSearchIcon from "@mui/icons-material/ManageSearch";
import LogoutIcon from "@mui/icons-material/Logout";
import {
  Button,
  Divider,
  PopoverOrigin,
  SxProps,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  filteredTurismSecondary,
  generalWhite,
} from "../../../../styles/colors";
import { UserRole } from "../../../../types/types";
import {
  CURRENT_SUBDOMAIN,
  DEMANDORIA_SUBDOMAINS,
} from "../../../../utils/stringUtils";
import { PrimaryButton } from "../../buttons/PrimaryButton";
import MobileMenuItem from "./MobileMenuItem";
import { useState } from "react";
import MenuItem from "./MenuItem";
import { handleLogout } from "../../../../utils/miscelaneous";
import BrandLogo from "@/components/turism/BrandLogo";
import { sharedStyles } from "@/styles/shared";
import BackpackIcon from "@mui/icons-material/Backpack";

interface ClientNavbarProps {
  isUserComercial?: boolean;
  hideMidButton?: boolean;
  hideMenuButton?: boolean;
  hideCartButton?: boolean;
}

const anchorProps: PopoverOrigin = {
  vertical: "top",
  horizontal: "right",
};

const menuItemStyle = { color: generalWhite };

const ClientNavbar = (props: ClientNavbarProps) => {
  const { hideCartButton, hideMenuButton, hideMidButton, isUserComercial } =
    props;

  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] =
    useState<null | HTMLElement>(null);

  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const auth = useAuth();
  const navigate = useNavigate();

  const handleMobileMenuClose = () => setMobileMoreAnchorEl(null);

  const handleMenuClose = () => handleMobileMenuClose();

  const handleMobileMenuOpen = (event: React.MouseEvent<HTMLElement>) =>
    setMobileMoreAnchorEl(event.currentTarget);

  const handleLogoutClick = () => {
    window.confirm("Tem certeza que deseja sair?") &&
      handleLogout(auth, navigate);
    handleMenuClose();
  };

  const mobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={anchorProps}
      keepMounted
      transformOrigin={anchorProps}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MobileMenuItem
        icon={<AccountCircle sx={{ color: "text.primary" }} />}
        onClick={() => navigate("/user/details")}
        label={<Typography>Meu perfil</Typography>}
      />

      {/* {CURRENT_SUBDOMAIN !== DEMANDORIA_SUBDOMAINS[2] && (
        <MobileMenuItem
          icon={<CalendarMonthIcon sx={{ color: "text.primary" }} />}
          onClick={() => navigate("/my-appointments")}
          label={<Typography>Agendamentos</Typography>}
        />
      )} */}

      <MobileMenuItem
        icon={<BackpackIcon sx={{ color: "text.primary" }} />}
        onClick={() => navigate("/checkout")}
        label={<Typography>Mala</Typography>}
      />

      <MobileMenuItem
        icon={<CalendarMonthIcon sx={{ color: "text.primary" }} />}
        onClick={() => navigate("/history")}
        label={<Typography>Histórico de excursões</Typography>}
      />

      {/* {!isUserComercial && !hideCartButton && (
        <MobileMenuItem
          icon={<ShoppingCartIcon sx={{ color: "text.primary" }} />}
          onClick={() => navigate("/cart")}
          label={<Typography>Carrinho de compras</Typography>}
        />
      )} */}

      <Divider />

      <MobileMenuItem
        icon={<LogoutIcon color="error" />}
        onClick={handleLogoutClick}
        label={<Typography color="error">Sair</Typography>}
      />
    </Menu>
  );

  return (
    <>
      <Toolbar
        sx={{
          ...sharedStyles.horizontalContent,
          justifyContent: "space-between",
          alignItems: "center",
          backgroundColor: "primary.main",
        }}
      >
        <Button sx={styles.logo} disableRipple>
          <div
            onClick={() =>
              auth.user.role === UserRole.CLIENT
                ? navigate("/home")
                : CURRENT_SUBDOMAIN === DEMANDORIA_SUBDOMAINS[0] ||
                    CURRENT_SUBDOMAIN === DEMANDORIA_SUBDOMAINS[1]
                  ? navigate("/stores")
                  : navigate("/agencies")
            }
          >
            {/* {CURRENT_SUBDOMAIN === DEMANDORIA_SUBDOMAINS[2] ? (
              <BrandLogo
                style={{
                  width: 35,
                  height: 35,
                }}
                color={filteredTurismSecondary}
              />
            ) : (
              "D"
            )} */}
            <BrandLogo
              style={{
                width: 35,
                height: 35,
              }}
              color={filteredTurismSecondary}
            />
          </div>
        </Button>

        <Box sx={{ flexGrow: 1 }} />

        {!hideMidButton && (
          <Box>
            <PrimaryButton
              label="Busca avançada"
              icon={<ManageSearchIcon />}
              onClickAction={() => navigate("/search")}
              styles={styles.advancedSearchButton}
            />
          </Box>
        )}

        <Box sx={{ flexGrow: 1 }} />

        {!hideMenuButton && (
          <>
            <Box sx={{ display: { xs: "none", md: "flex" } }}>
              {/* {CURRENT_SUBDOMAIN !== DEMANDORIA_SUBDOMAINS[2] && (
                <Tooltip title="Agendamentos">
                  <MenuItem
                    icon={<CalendarMonthIcon sx={menuItemStyle} />}
                    onClick={() => navigate("/my-appointments")}
                  />
                </Tooltip>
              )} */}

              <Tooltip title="Checkout">
                <MenuItem
                  icon={<BackpackIcon sx={menuItemStyle} />}
                  onClick={() => navigate("/checkout")}
                />
              </Tooltip>

              <Tooltip title="Histórico de excursões">
                <MenuItem
                  icon={<CalendarMonthIcon sx={menuItemStyle} />}
                  onClick={() => navigate("/history")}
                />
              </Tooltip>

              {/* {!isUserComercial && !hideCartButton && (
                <Tooltip title="Carrinho de compras">
                  <MenuItem
                    icon={<ShoppingCartIcon sx={menuItemStyle} />}
                    onClick={() => navigate("/cart")}
                  />
                </Tooltip>
              )} */}

              <Tooltip title="Minha conta">
                <MenuItem
                  icon={<AccountCircle sx={menuItemStyle} />}
                  onClick={() => navigate("/user/details")}
                />
              </Tooltip>
            </Box>

            <Box sx={{ display: { xs: "flex", md: "none" } }}>
              <MenuItem
                icon={<MoreIcon sx={menuItemStyle} />}
                onClick={handleMobileMenuOpen}
              />
            </Box>
          </>
        )}
      </Toolbar>

      {!hideMenuButton && mobileMenu}
    </>
  );
};

export default ClientNavbar;

const styles = {
  logo: {
    color: generalWhite,
    textTransform: "none",
  } as SxProps,

  advancedSearchButton: {
    backgroundColor: "secondary.main",
    color: "primary.main",
    fontWeight: "bold",
    maxHeight: "40px",

    ":hover": {
      backgroundColor: "secondary.main",
    },
  } as SxProps,
};
