import {
  Box,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  Toolbar,
  Typography,
} from "@mui/material";
import StoreIcon from "@mui/icons-material/Store";
import ExploreIcon from "@mui/icons-material/Explore";
import LogoutIcon from "@mui/icons-material/Logout";
import { useLocation, useNavigate } from "react-router-dom";
import { handleLogout } from "@/utils/miscelaneous";
import useManagerStores from "@/hooks/context-hooks/useManagerStores";
import { useAuth } from "@/context/AuthProvider/useAuth";
import { useState } from "react";
import FullBrandLogo from "../FullBrandLogo";
import { filteredTurismPrimary } from "@/styles/colors";

const Sidebar = () => {
  const navigate = useNavigate();
  let managerStoresContext = useManagerStores();

  const [selectedSessionIndex, setSelectedSessionIndex] = useState(null);

  const auth = useAuth();
  const location = useLocation();

  const options = [
    { icon: <StoreIcon />, text: "Agências", path: "/agencies" },
    { icon: <ExploreIcon />, text: "Excursões", path: "/excursions" },
    { icon: <LogoutIcon />, text: "Sair", path: "/login" },
  ];

  const handleQuitClick = () => {
    if (confirm("Tem certeza que deseja sair?")) {
      managerStoresContext = null;
      handleLogout(auth, navigate);
    }
  };

  const handleChangeSelectedSessionIndex = (index: number) =>
    setSelectedSessionIndex(index);

  const checkIfIsASelectablePath = (path: string) =>
    location.pathname === path ||
    location.pathname === `${path}/add` ||
    location.pathname === `${path}/edit` ||
    location.pathname === `${path}/edit` ||
    location.pathname === `${path}/details`;

  const selectedBackgroundColor = (path) =>
    checkIfIsASelectablePath(path) ? "primary.main" : "secondary.main";

  const selectedTextColor = (path) =>
    checkIfIsASelectablePath(path) ? "secondary.main" : "primary.main";

  return (
    <Box
      sx={{
        backgroundColor: "secondary.main",
        height: "100%",
        color: "primary.main",
      }}
    >
      <Toolbar
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginY: 1,
        }}
      >
        <FullBrandLogo
          style={{
            width: 120,
            height: "auto",
          }}
          color={filteredTurismPrimary}
        />
      </Toolbar>

      <List sx={{ marginBottom: "5px" }}>
        {options.map(({ icon, text, path }, index) => (
          <ListItem
            key={text}
            disablePadding
            sx={{
              width: "300px",
              bottom: text === "Sair" ? "0" : "auto",
              position: text === "Sair" ? "fixed" : "static",
              marginBottom: "5px",
              backgroundColor: selectedBackgroundColor(path),
              color: selectedTextColor(path),
              ":hover": { backgroundColor: selectedBackgroundColor(path) },
            }}
          >
            <ListItemButton
              onClick={() =>
                text === "Sair"
                  ? handleQuitClick()
                  : navigate(path) && handleChangeSelectedSessionIndex(index)
              }
            >
              <ListItemIcon sx={{ color: selectedTextColor(path) }}>
                {icon}
              </ListItemIcon>

              <Typography fontWeight="bold">{text}</Typography>
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );
};

export default Sidebar;
