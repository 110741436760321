import {
  Avatar,
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import InputMask from "react-input-mask";
import { UserRole, UserStatus } from "../../../types/types";
import { useEffect, useRef, useState } from "react";
import { PrimaryButton } from "../../../components/general/buttons/PrimaryButton";
import { userApi } from "../../../services";
import EditIcon from "@mui/icons-material/Edit";
import { useAuth } from "../../../context/AuthProvider/useAuth";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import AvatarImageIconButton from "../../../components/general/buttons/AvatarImageIconButton";

import BackPageButton, {
  backButtonContainer,
} from "../../../components/general/buttons/BackPageButton";
import {
  AddressProps,
  getCoordinatesFromAddress,
} from "../../../hooks/getCoodinatesByAddress";
import EmailIcon from "@mui/icons-material/Email";
import BadgeIcon from "@mui/icons-material/Badge";
import {
  CURRENT_SUBDOMAIN,
  DEMANDORIA_SUBDOMAINS,
  getUserFirebasePath,
} from "../../../utils/stringUtils";
import ClientNavbar from "../../../components/general/Navbars/ClientNavbar";
import { useNavigate } from "react-router-dom";
import { handleLogout } from "../../../utils/miscelaneous";
import LogoutIcon from "@mui/icons-material/Logout";
import {
  deleteImageFromFirebase,
  uploadOriginalAndPreviewImagesToFirebase,
} from "../../../utils/firebase/functions";
import { sharedStyles } from "@/styles/shared";
import useCep from "../../../hooks/useCep";

//TODO: refactor entire CEP flow
const AccountDetails = () => {
  const auth = useAuth();
  const navigate = useNavigate();

  const [profileImage, setProfileImage] = useState<HTMLCanvasElement[]>([]);

  const [userUpdateLoading, setUserUpdateLoading] = useState(false);

  const nameRef = useRef<HTMLInputElement>(null);
  const [role, setRole] = useState<UserRole>(auth.user.role);
  const phoneRef = useRef<HTMLInputElement>(null);

  const [cep, setCep] = useState<string>(auth.user.CEP);

  const streetRef = useRef<HTMLInputElement>(null);
  const numberRef = useRef<HTMLInputElement>(null);
  const districtRef = useRef<HTMLInputElement>(null);
  const cityRef = useRef<HTMLInputElement>(null);
  const stateRef = useRef<HTMLInputElement>(null);

  const [firstTimeFetched, setFirstTimeFetched] = useState(false);

  const handleUpdateUser = async () => {
    setUserUpdateLoading(true);

    let profileImageOriginal = auth.user.imageOriginal;
    let profileImagePreview = auth.user.imagePreview;

    const hasChangedRole = auth.user.role !== role;

    try {
      if (profileImage.length > 0) {
        if (
          auth.user.imageOriginal.startsWith(
            import.meta.env.VITE_FIREBASE_STORAGE_PROJECT_URL
          )
        )
          await Promise.all([
            deleteImageFromFirebase(auth.user.imageOriginal),
            deleteImageFromFirebase(auth.user.imagePreview),
          ]);

        const uploadedOriginalAndPreviewImagesResponse =
          await uploadOriginalAndPreviewImagesToFirebase(
            profileImage,
            getUserFirebasePath(auth.user.name, auth.user.id)
          );

        profileImageOriginal =
          uploadedOriginalAndPreviewImagesResponse.originalImageUrls[0];
        profileImagePreview =
          uploadedOriginalAndPreviewImagesResponse.previewImageUrls[0];
      }

      const coordinates = await getCoordinatesFromAddress({
        street: streetRef.current.value ?? "",
        number: numberRef.current.value ?? "",
        district: districtRef.current.value ?? "",
        city: cityRef.current.value ?? "",
        state: stateRef.current.value ?? "",
        country: "Brasil",
      } as AddressProps);

      const updatedUser = {
        name: nameRef.current.value ?? "",
        role: role,
        phone: phoneRef.current.value ?? "",
        CEP: cep,
        logradouro: streetRef.current.value,
        number: numberRef.current.value,
        district: districtRef.current.value,
        city: cityRef.current.value,
        state: stateRef.current.value,
        country: "Brasil",
        imageOriginal: profileImageOriginal,
        imagePreview: profileImagePreview,
        lat: Number(coordinates[0]),
        lng: Number(coordinates[1]),
        status: UserStatus.OK,
      };

      const updatedUserResponse = await userApi.updateUser(
        auth.user.id,
        updatedUser
      );

      auth.updateCurrentUser(updatedUserResponse);

      updatedUserResponse && alert("Usuário atualizado com sucesso!");

      if (hasChangedRole)
        navigate(
          role === UserRole.CLIENT
            ? "/home"
            : CURRENT_SUBDOMAIN === DEMANDORIA_SUBDOMAINS[0] ||
                CURRENT_SUBDOMAIN === DEMANDORIA_SUBDOMAINS[1]
              ? "/stores"
              : "/agencies"
        );
    } catch (error) {
      console.warn("Error updating user: ", error);
    }

    setUserUpdateLoading(false);
  };

  useEffect(() => {
    if (profileImage.length > 1)
      setProfileImage([profileImage[profileImage.length - 1]]);
  }, [auth.user, profileImage]);

  const fetchedCep = useCep(
    cep,
    streetRef,
    districtRef,
    cityRef,
    stateRef,
    firstTimeFetched
  );

  useEffect(() => {
    if (auth.user.CEP !== cep && !firstTimeFetched) setFirstTimeFetched(true);
  }, [cep]);

  const renderSidebar = (
    <Box
      sx={{
        ...sharedStyles.verticalContent,
        alignItems: "center",
        width: "100%",
        paddingX: 3,
      }}
    >
      <Box>
        <Avatar
          src={
            profileImage.length === 0
              ? auth.user.imageOriginal
              : profileImage[0].toDataURL()
          }
          style={{ width: 100, height: 100 }}
        />

        <Tooltip title="Alterar foto de perfil">
          <Box>
            <AvatarImageIconButton
              icon={<CameraAltIcon />}
              setImages={setProfileImage}
            />
          </Box>
        </Tooltip>
      </Box>

      <Typography fontSize="14pt" fontWeight="bold" textAlign="center">
        {auth.user.name}
      </Typography>

      <Box sx={sharedStyles.verticalContent}>
        {auth.user.docNumber && (
          <Box sx={{ ...sharedStyles.horizontalContent }}>
            <BadgeIcon color="primary" />
            <Typography variant="body1" color="text.primary">
              {auth.user.docNumber}
            </Typography>
          </Box>
        )}

        <Box sx={{ ...sharedStyles.horizontalContent }}>
          <EmailIcon color="primary" />
          <Typography variant="body1" textAlign="justify" color="text.primary">
            {auth.user.email}
          </Typography>
        </Box>

        <Button
          onClick={() =>
            confirm("Tem certeza que deseja sair?") &&
            handleLogout(auth, navigate)
          }
          disableRipple
          sx={{ textTransform: "none" }}
          startIcon={<LogoutIcon />}
          color="error"
        >
          Sair
        </Button>
      </Box>
    </Box>
  );

  const renderPersonalData = (
    <Box
      sx={{
        ...sharedStyles.verticalContent,
        gap: 3,
        paddingX: { xs: 0, md: 0, lg: 15 },
      }}
    >
      <Typography variant="h6">Editar dados pessoais</Typography>

      <Box sx={sharedStyles.verticalContent}>
        <TextField
          label="Nome completo *"
          size="small"
          fullWidth
          inputRef={nameRef}
          defaultValue={auth.user.name}
        />

        <FormControl size="small" fullWidth>
          <InputLabel>Tipo de conta *</InputLabel>
          <Select
            label="Tipo de conta *"
            value={role}
            onChange={(e: any) => setRole(e.target.value)}
          >
            <MenuItem value={UserRole.CLIENT}>Cliente</MenuItem>
            <MenuItem value={UserRole.COMERCIAL}>Comercial</MenuItem>
          </Select>
        </FormControl>

        <InputMask mask="(99) 9.9999-9999" maskChar="_">
          <TextField
            label="Telefone"
            size="small"
            fullWidth
            inputRef={phoneRef}
            autoComplete="nope"
            defaultValue={auth.user.phone}
          />
        </InputMask>
      </Box>

      <Typography variant="h6">Editar endereço</Typography>

      <Box sx={sharedStyles.verticalContent}>
        <InputMask
          mask="99999-999"
          disabled={false}
          maskChar="_"
          value={cep}
          onChange={(e) => setCep(e.target.value)}
        >
          <TextField size="small" label="CEP" fullWidth autoComplete="nope" />
        </InputMask>

        <Box sx={sharedStyles.horizontalContent}>
          <TextField
            size="small"
            label="Logradouro"
            sx={{ width: "75%" }}
            InputLabelProps={{ shrink: true }}
            inputRef={streetRef}
            disabled={
              fetchedCep?.street !== null && streetRef?.current?.value !== ""
            }
            defaultValue={auth.user.logradouro}
          />
          <TextField
            size="small"
            label="N°"
            sx={{ width: "25%" }}
            InputLabelProps={{ shrink: true }}
            inputRef={numberRef}
            defaultValue={auth.user.number}
          />
        </Box>

        <TextField
          size="small"
          label="Bairro"
          fullWidth
          InputLabelProps={{ shrink: true }}
          inputRef={districtRef}
          disabled={
            fetchedCep?.neighborhood !== null &&
            districtRef?.current?.value !== ""
          }
          defaultValue={auth.user.district}
        />

        <Box sx={sharedStyles.verticalContent}>
          <TextField
            size="small"
            label="Cidade"
            fullWidth
            InputLabelProps={{ shrink: true }}
            inputRef={cityRef}
            disabled={
              fetchedCep?.city !== null && cityRef?.current?.value !== ""
            }
            defaultValue={auth.user.city}
          />
          <TextField
            size="small"
            label="Estado"
            fullWidth
            InputLabelProps={{ shrink: true }}
            inputRef={stateRef}
            disabled={
              fetchedCep?.state !== null && stateRef?.current?.value !== ""
            }
            defaultValue={auth.user.state}
          />
        </Box>

        <PrimaryButton
          label="Confirmar edição"
          onClickAction={handleUpdateUser}
          isLoading={userUpdateLoading}
          icon={<EditIcon />}
          styles={{ height: "50px", color: "secondary.main", marginTop: 2 }}
        />
      </Box>
    </Box>
  );

  return (
    <Box
      sx={{
        overflowX: "hidden",
        height: "100vh",
      }}
    >
      <ClientNavbar hideMidButton hideMenuButton />

      <Box sx={backButtonContainer}>
        <BackPageButton />
      </Box>

      <Box
        sx={{
          ...sharedStyles.horizontalContent,
          width: "100%",
          paddingX: 3,
          marginBottom: 5,
          justifyContent: "center",
          gap: { xs: 5, md: 3, lg: 1 },
          flexDirection: { xs: "column", md: "row", lg: "row" },
        }}
      >
        <Box
          sx={{
            width: { xs: "100%", md: "30%", lg: "30%" },
            alignSelf: "self-start",
          }}
        >
          {renderSidebar}
        </Box>

        <Box sx={{ width: { xs: "100%", md: "70%", lg: "70%" } }}>
          {renderPersonalData}
        </Box>
      </Box>
    </Box>
  );
};

export default AccountDetails;
