import { IconButton, Tooltip } from "@mui/material";
import { handleSelectHTMLCanvaImage } from "../../../utils/image/imageFunctions";
import { Dispatch, ReactNode, SetStateAction } from "react";

interface AddImageInputProps {
  icon?: ReactNode;
  setImages: Dispatch<SetStateAction<HTMLCanvasElement[]>>;
}

const AddImageInput = (props: AddImageInputProps) => {
  const { icon, setImages } = props;

  return (
    <Tooltip title="Adicionar imagem">
      <IconButton color="primary">
        <input
          type="file"
          accept="image/*"
          multiple
          style={{
            position: "absolute",
            left: 0,
            top: 0,
            width: "100%",
            height: "100%",
            opacity: 0,
            cursor: "pointer",
          }}
          onChange={(e) => handleSelectHTMLCanvaImage(e, setImages)}
        />
        {icon}
      </IconButton>
    </Tooltip>
  );
};

export default AddImageInput;
