import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import ComercialDrawer from "../../../../../components/turism/ComercialDrawer";
import ComercialInterfaceHeader from "../../../../../components/comercial/ComercialInterfaceHeader";
import { PrimaryButton } from "../../../../../components/general/buttons/PrimaryButton";
import { SecondaryButton } from "../../../../../components/general/buttons/SecondaryButton";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ExcursionStatus, FoodService } from "../../../../../types/types";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import ImageGalleryHeader from "../../../../../components/comercial/ImageGallery/ImageGalleryHeader";
import GalleryImageList from "../../../../../components/comercial/ImageGallery";
import dayjs, { Dayjs } from "dayjs";
import excursionApi from "../../../../../services/api/Excursion";
import IExcursion from "../../../../../services/api/Excursion/IExcursion";
import { useForm } from "react-hook-form";
import AddIcon from "@mui/icons-material/Add";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  addAndEditExcursionSchema,
  isPriceInvalid,
} from "../excursionValidationSchemas";
import PriceInput from "../../../../../components/general/PriceInput";
import { IStore } from "../../../../../services/api/Store/types";
import { getLocalStorageItem } from "../../../../../hooks/localStorageHooks";
import { sharedStyles } from "../../../../../styles/shared";
import { uploadOriginalAndPreviewImagesToFirebase } from "../../../../../utils/firebase/functions";
import { getExcursionsFirebasePath } from "../../../../../utils/stringUtils";
import ImageGalleryContainer from "../../../../../components/comercial/ImageGallery/ImageGalleryContainer";
import { LeftMarginBackButton } from "@/components/general/buttons/BackPageButton";
import Tiptap from "@/components/general/Tiptap";
import DOMPurify from "dompurify";
import EmptyGallery from "@/components/comercial/ImageGallery/EmptyGallery";
import ExcursionRouter from "@/components/turism/ExcursionRouter";

const AddExcursion = () => {
  const navigate = useNavigate();

  const selectedAgency: IStore = getLocalStorageItem("selectedStore");

  const [submitLoading, setSubmitLoading] = useState<boolean>(false);

  const [images, setImages] = useState<HTMLCanvasElement[]>([]);

  const titleRef = useRef<HTMLInputElement>(null);
  const descriptionRef = useRef("");
  const agentRef = useRef<HTMLInputElement>(null);
  const [price, setPrice] = useState<number | null>(null);
  const categoryRef = useRef<HTMLInputElement>(null);
  const restrictionRef = useRef<HTMLInputElement>(null);
  const [router, setRouter] = useState([]);

  const [startDate, setStartDate] = useState<Dayjs | null>(() =>
    dayjs().add(1, "day")
  );
  const [endDate, setEndDate] = useState<Dayjs | null>(() =>
    startDate.add(1, "day")
  );

  const maxVacanciesRef = useRef<HTMLInputElement>(null);
  const minVacanciesRef = useRef<HTMLInputElement>(null);
  const transportServiceRef = useRef<HTMLInputElement>(null);
  const accommodationServiceRef = useRef<HTMLInputElement>(null);
  const foodServiceRef = useRef<HTMLSelectElement>(null);
  const groupLinkRef = useRef<HTMLInputElement>(null);
  const excursionContractRef = useRef<HTMLInputElement>(null);

  const cityRef = useRef<HTMLInputElement>(null);
  const stateRef = useRef<HTMLInputElement>(null);
  const regionRef = useRef<HTMLInputElement>(null);
  const countryRef = useRef<HTMLInputElement>(null);

  const [startDateIsBeforeEndDateError, setStartDateIsBeforeEndDateError] =
    useState(false);

  const [submittedOnce, setSubmittedOnce] = useState(false);

  const {
    register,
    handleSubmit: onSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(addAndEditExcursionSchema) });

  const handleExcursionCreation = async () => {
    if (!submittedOnce) setSubmittedOnce(true);

    if (price > 0 && price) {
      setSubmitLoading(true);

      try {
        const newExcursion: IExcursion = {
          storeId: selectedAgency.id,
          title: titleRef.current.value,
          description: DOMPurify.sanitize(descriptionRef.current),
          restriction: restrictionRef.current.value,
          accommodationService: accommodationServiceRef.current.value,
          transportService: transportServiceRef.current.value,
          foodService: foodServiceRef.current.value,
          price: price * 100,
          agent: agentRef.current.value,
          startDate: startDate.toISOString(),
          endDate: endDate.toISOString(),
          mainCategory: categoryRef.current.value,
          router: router,
          country: countryRef.current.value,
          region: regionRef.current.value,
          state: stateRef.current.value,
          city: cityRef.current.value,
          maxVacancies: Number(maxVacanciesRef.current.value),
          minVacancies: Number(minVacanciesRef.current.value),
          groupLink: groupLinkRef.current.value,
          excursionContract: excursionContractRef.current.value,
          excursionStatus: ExcursionStatus.ACTIVE,
          listTags: [],
          imageOriginal: [],
          imagePreview: [],
        };

        const createdExcursionResponse =
          await excursionApi.createExcursion(newExcursion);

        if (images.length > 0) {
          const { originalImageUrls, previewImageUrls } =
            await uploadOriginalAndPreviewImagesToFirebase(
              images,
              getExcursionsFirebasePath(
                selectedAgency.name,
                selectedAgency.id,
                createdExcursionResponse.title,
                createdExcursionResponse.id
              )
            );

          newExcursion.imageOriginal = originalImageUrls;
          newExcursion.imagePreview = previewImageUrls;

          await excursionApi.updateExcursion(
            createdExcursionResponse.id,
            newExcursion
          );
        }

        navigate("/excursions");
      } catch (error) {
        console.error("Error adding an excursion: ", error);
      }

      setSubmitLoading(false);
    }
  };

  useEffect(() => {
    setStartDateIsBeforeEndDateError(endDate.isBefore(startDate));
  }, [startDate, endDate]);

  const renderExcursionData = (
    <>
      <ComercialInterfaceHeader title="Dados gerais da excursão" />
      <Box sx={sharedStyles.dividedFormContainer}>
        <Box sx={{ ...sharedStyles.verticalContent, width: "100%" }}>
          <TextField
            label="Título *"
            size="small"
            fullWidth
            inputRef={titleRef}
            {...register("title")}
            error={!!errors.title}
            helperText={errors.title?.message}
            autoComplete="off"
          />

          <Box sx={sharedStyles.dividedFormContainer}>
            <TextField
              label="Categoria *"
              size="small"
              fullWidth
              inputRef={categoryRef}
              {...register("mainCategory")}
              error={!!errors.mainCategory}
              helperText={errors.mainCategory?.message}
              autoComplete="off"
            />

            <TextField
              label="Agente *"
              size="small"
              fullWidth
              inputRef={agentRef}
              {...register("agent")}
              error={!!errors.agent}
              helperText={errors.agent?.message}
              autoComplete="off"
            />
          </Box>
        </Box>

        <Box sx={{ ...sharedStyles.verticalContent, width: "100%" }}>
          <PriceInput
            label="Preço (R$) *"
            size="small"
            placeholder="Ex.: R$ 500,00"
            value={price}
            onChange={(e) => setPrice(Number(e.target.value))}
            error={isPriceInvalid(submittedOnce, price)}
            autoComplete="off"
            helperText={
              isPriceInvalid(submittedOnce, price) &&
              "O preço deve ser maior que R$ 0,00"
            }
            inputRef={(input) =>
              input && isPriceInvalid(submittedOnce, price) && input.focus()
            }
          />

          <TextField
            label="Restrição"
            size="small"
            fullWidth
            inputRef={restrictionRef}
            autoComplete="off"
          />
        </Box>
      </Box>
    </>
  );

  const renderExcursionDetails = (
    <>
      <ComercialInterfaceHeader title="Detalhes da excursão" />

      <Box sx={sharedStyles.verticalContent}>
        <Box sx={{ marginBottom: 2 }}>
          <Tiptap
            onChange={(e) => (descriptionRef.current = e)}
            placeholder="Descreva os detalhes da excursão..."
          />
        </Box>

        <Box sx={sharedStyles.dividedFormContainer}>
          <Box sx={{ ...sharedStyles.verticalContent, width: "100%" }}>
            <LocalizationProvider
              dateAdapter={AdapterDayjs}
              adapterLocale="pt-br"
            >
              <DatePicker
                label="Data de início *"
                format="DD/MM/YYYY"
                disablePast
                dayOfWeekFormatter={(e: any) => e}
                slotProps={{
                  textField: {
                    size: "small",
                    fullWidth: true,
                  },
                }}
                value={startDate}
                onChange={(newStartDate) => setStartDate(newStartDate)}
              />
            </LocalizationProvider>

            <LocalizationProvider
              dateAdapter={AdapterDayjs}
              adapterLocale="pt-br"
            >
              <DatePicker
                label="Data de término *"
                format="DD/MM/YYYY"
                disablePast
                dayOfWeekFormatter={(e: any) => e}
                slotProps={{
                  textField: {
                    size: "small",
                    fullWidth: true,
                    helperText:
                      startDateIsBeforeEndDateError &&
                      "A data de término deve ser posterior a de início.",
                  },
                }}
                value={endDate}
                onChange={(newEndDate) => setEndDate(newEndDate)}
                minDate={startDate}
              />
            </LocalizationProvider>

            <Box sx={sharedStyles.dividedFormContainer}>
              <TextField
                label="Mín. de vagas *"
                size="small"
                type="number"
                fullWidth
                inputRef={minVacanciesRef}
                {...register("minVacancies")}
                error={!!errors.minVacancies}
                helperText={errors.minVacancies?.message}
                inputProps={{ min: 1 }}
                defaultValue={1}
              />
              <TextField
                label="Máx. de vagas *"
                size="small"
                type="number"
                fullWidth
                inputRef={maxVacanciesRef}
                {...register("maxVacancies")}
                error={!!errors.maxVacancies}
                helperText={errors.maxVacancies?.message}
                inputProps={{ min: minVacanciesRef?.current?.value }}
                defaultValue={10}
              />
            </Box>
          </Box>

          <Box sx={{ ...sharedStyles.verticalContent, width: "100%" }}>
            <TextField
              label="Serviço de transporte *"
              size="small"
              fullWidth
              inputRef={transportServiceRef}
              {...register("transportService")}
              error={!!errors.transportService}
              helperText={errors.transportService?.message}
              autoComplete="off"
            />
            <TextField
              label="Serviço de hospedagem *"
              size="small"
              fullWidth
              inputRef={accommodationServiceRef}
              {...register("accommodationService")}
              error={!!errors.accommodationService}
              helperText={errors.accommodationService?.message}
              autoComplete="off"
            />
            <FormControl fullWidth size="small">
              <InputLabel>Serviço de alimentação *</InputLabel>
              <Select
                label="Serviço de alimentação"
                defaultValue={FoodService[0]}
                inputRef={foodServiceRef}
              >
                {FoodService.map((serviceType, index) => (
                  <MenuItem value={FoodService[index]} key={index}>
                    {serviceType}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        </Box>

        <TextField
          label="Link do grupo"
          size="small"
          fullWidth
          inputRef={groupLinkRef}
        />
        <TextField
          label="Contrato da excursão"
          size="small"
          fullWidth
          inputRef={excursionContractRef}
        />
      </Box>
      <ExcursionRouter router={router} setRouter={setRouter} />
    </>
  );

  const renderExcursionGeographicData = (
    <>
      <ComercialInterfaceHeader title="Dados geográficos" />

      <Box sx={sharedStyles.verticalContent}>
        <Box sx={sharedStyles.horizontalContent}>
          <TextField
            label="Cidade *"
            size="small"
            fullWidth
            inputRef={cityRef}
            {...register("city")}
            error={!!errors.city}
            helperText={errors.city?.message}
            autoComplete="off"
          />
          <TextField
            label="Estado *"
            size="small"
            fullWidth
            inputRef={stateRef}
            {...register("state")}
            error={!!errors.state}
            helperText={errors.state?.message}
            autoComplete="off"
          />
        </Box>

        <Box sx={sharedStyles.horizontalContent}>
          <TextField
            label="Região *"
            size="small"
            fullWidth
            inputRef={regionRef}
            {...register("region")}
            error={!!errors.region}
            helperText={errors.region?.message}
            autoComplete="off"
          />
          <TextField
            label="País *"
            size="small"
            fullWidth
            inputRef={countryRef}
            {...register("country")}
            error={!!errors.country}
            helperText={errors.country?.message}
            autoComplete="off"
          />
        </Box>
      </Box>
    </>
  );

  const renderExcursionImageGallery = (
    <Box sx={{ ...sharedStyles.verticalContent, gap: 4 }}>
      <ImageGalleryHeader
        icon={<AddPhotoAlternateIcon />}
        setImages={setImages}
      />

      {images.length > 0 ? (
        <ImageGalleryContainer>
          <GalleryImageList images={images} setImages={setImages} interable />
        </ImageGalleryContainer>
      ) : (
        <EmptyGallery />
      )}
    </Box>
  );

  const renderAddExcursionPageContent = (
    <>
      <LeftMarginBackButton />

      <form
        onSubmit={onSubmit(handleExcursionCreation)}
        style={sharedStyles.form}
      >
        {renderExcursionData}

        {renderExcursionDetails}

        {renderExcursionGeographicData}

        {renderExcursionImageGallery}

        <Box sx={sharedStyles.dividedFormContainer}>
          <PrimaryButton
            label="Confirmar adição"
            icon={<AddIcon />}
            isLoading={submitLoading}
            onClickAction={() => !submittedOnce && setSubmittedOnce(true)}
            styles={sharedStyles.secondaryColor}
          />
          <SecondaryButton
            label="Cancelar"
            onClickAction={() => navigate("/excursions")}
            isDisabled={submitLoading}
          />
        </Box>
      </form>
    </>
  );

  return <ComercialDrawer content={renderAddExcursionPageContent} />;
};

export default AddExcursion;
