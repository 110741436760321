import {
  uploadBytes,
  getDownloadURL,
  deleteObject,
  ref,
  listAll,
} from "firebase/storage";
import { storage } from "./config";
import { v4 as uuidv4 } from "uuid";
import { resizeMultipleImages } from "../image/resizeImage";
import {
  ImageOriginalResolutions,
  ImagePreviewResolutions,
} from "../../types/types";

type FirebaseUploadedImageResponse = {
  uuid: string;
  url: string;
};

export const uploadSingleImageToFirebase = async (
  image: HTMLCanvasElement,
  path: string,
  originalUuid?: string
): Promise<FirebaseUploadedImageResponse> => {
  const blobImage = await new Promise<Blob>((resolve) => image.toBlob(resolve));

  const uuid = originalUuid || uuidv4();

  const storageRef = ref(
    storage,
    `${path}/${uuid}${originalUuid ? "_preview" : "_original"}`
  );

  const snapshot = await uploadBytes(storageRef, blobImage);
  const uploadedImageUrl = await getDownloadURL(snapshot.ref);

  return { uuid: uuid, url: uploadedImageUrl };
};

type OriginalAndPreviewImagesUploadResponse = {
  originalImageUrls: string[];
  previewImageUrls: string[];
};

export const uploadOriginalAndPreviewImagesToFirebase = async (
  images: HTMLCanvasElement[],
  path: string
): Promise<OriginalAndPreviewImagesUploadResponse> => {
  const resizedOriginalImages = resizeMultipleImages(
    images,
    ImageOriginalResolutions[0],
    ImageOriginalResolutions[1]
  );

  const resizedPreviewImages = resizeMultipleImages(
    images,
    ImagePreviewResolutions[0],
    ImagePreviewResolutions[1]
  );

  let iterableUploadedImageResponse: FirebaseUploadedImageResponse | null =
    null;

  let originalImageUrls: string[] = [];
  let previewImageUrls: string[] = [];

  for (let i = 0; i < images.length; i++) {
    iterableUploadedImageResponse = await uploadSingleImageToFirebase(
      resizedOriginalImages[i],
      path
    );
    originalImageUrls.push(iterableUploadedImageResponse.url);

    iterableUploadedImageResponse = await uploadSingleImageToFirebase(
      resizedPreviewImages[i],
      path,
      iterableUploadedImageResponse.uuid
    );
    previewImageUrls.push(iterableUploadedImageResponse.url);
  }

  return { originalImageUrls, previewImageUrls };
};

export const deleteImageFromFirebase = async (imageUrl: string) => {
  try {
    await deleteObject(ref(storage, imageUrl));
  } catch (error) {
    console.warn(`Error deleting ${imageUrl} from Firebase:`, error);
  }
};

export const clearFirebaseFolder = async (path: string) => {
  let imagesRef = ref(storage, path);

  try {
    const currentFolderFileList = await listAll(imagesRef);

    const currentDeletionPromises = currentFolderFileList.items.map((image) =>
      deleteObject(image)
    );

    await Promise.all(currentDeletionPromises);

    const deleteSubDirectoriesPromises = currentFolderFileList.prefixes.map((directory) => clearFirebaseFolder(directory.fullPath))

    await Promise.all(deleteSubDirectoriesPromises);
  } catch (error) {
    console.error(`Error deleting ${path} from firebase:`, error);
  }
};
