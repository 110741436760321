import { Box, Chip, Divider, LinearProgress, Typography } from "@mui/material";
import ComercialDrawer from "../../../../../components/turism/ComercialDrawer";
import IExcursion from "../../../../../services/api/Excursion/IExcursion";
import ImageCarousel from "../../../../../components/general/ImageCarousel";
import { useState } from "react";
import { PrimaryButton } from "../../../../../components/general/buttons/PrimaryButton";
import ExcursionServiceCard from "../../../../../components/comercial/ExcursionServiceCard";
import { ExcursionServiceType } from "../../../../../types/types";
import EventIcon from "@mui/icons-material/Event";
import ConfirmationNumberIcon from "@mui/icons-material/ConfirmationNumber";
import { priceFormatter } from "../../../../../utils/priceFormatter";
import { LeftMarginBackButton } from "@/components/general/buttons/BackPageButton";
import RichTextRenderer from "@/components/general/Tiptap/RichTextRenderer";
import { sharedStyles } from "@/styles/shared";
import { formatDateString } from "@/utils/stringUtils";
import HotelIcon from "@mui/icons-material/Hotel";
import RestaurantIcon from "@mui/icons-material/Restaurant";
import DirectionsBusIcon from "@mui/icons-material/DirectionsBus";
import CollapseWrapper from "@/components/general/Tiptap/RichTextDescriptionWrapper";
import RoomIcon from "@mui/icons-material/Room";

const ExcursionDetails = () => {
  const selectedExcursion: IExcursion = JSON.parse(
    localStorage.getItem("selectedExcursion")
  );

  const sectionDivider = (
    <Divider
      sx={{
        width: "100%",
        color: "text.secondary",
      }}
    />
  );

  const renderExcursionShowcase = (
    <Box sx={{ ...sharedStyles.verticalContent, gap: 5 }}>
      <Box sx={sharedStyles.verticalContent}>
        <Box>
          <Typography variant="h5" fontWeight="bold">
            {selectedExcursion.title}{" "}
            <span>
              <Chip label={selectedExcursion.mainCategory} variant="outlined" />
            </span>
          </Typography>
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", md: "column", lg: "row" },
            gap: 5,
          }}
        >
          {selectedExcursion.imageOriginal.length > 0 && (
            <Box
              sx={{
                ...sharedStyles.verticalContent,
                width: { xs: "100%", lg: "70%" },
              }}
            >
              <ImageCarousel imageUrls={selectedExcursion.imageOriginal} />
            </Box>
          )}

          <Box
            sx={{
              ...sharedStyles.verticalContent,
              width: {
                xs: "100%",
                lg: selectedExcursion.imageOriginal.length > 0 ? "30%" : "100%",
              },
              alignSelf: "flex-start",
            }}
          >
            <Typography
              variant="h5"
              fontWeight="bold"
              color="primary"
            >{`R$ ${priceFormatter(selectedExcursion.price)}`}</Typography>

            <Typography sx={sharedStyles.horizontalContent}>
              <EventIcon />

              <Typography>{`${formatDateString(
                selectedExcursion.startDate
              )} - ${formatDateString(selectedExcursion.endDate)}`}</Typography>
            </Typography>

            <Box sx={sharedStyles.horizontalContent}>
              <ConfirmationNumberIcon />

              <Typography>{`${selectedExcursion.currentVacancies} vagas disponíveis (${selectedExcursion.maxVacancies} totais)`}</Typography>
            </Box>

            <LinearProgress
              variant="determinate"
              value={
                (selectedExcursion.currentVacancies /
                  selectedExcursion.maxVacancies) *
                100
              }
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );

  const renderExcursionDetails = (
    <Box sx={sharedStyles.form}>
      {selectedExcursion.description.length > 0 && (
        <Box>
          <CollapseWrapper
            label="Descrição da excursão"
            content={
              <RichTextRenderer htmlContent={selectedExcursion?.description} />
            }
          />
        </Box>
      )}

      {sectionDivider}

      <Typography variant="h6" fontWeight="bold">
        Quais serviços estão inclusos?
      </Typography>

      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", lg: "row" },
          gap: 2,
        }}
      >
        <ExcursionServiceCard
          serviceType={ExcursionServiceType.ACCOMMODATION}
          service={selectedExcursion.accommodationService}
          icon={<HotelIcon />}
        />
        <ExcursionServiceCard
          serviceType={ExcursionServiceType.TRANSPORT}
          service={selectedExcursion.transportService}
          icon={<DirectionsBusIcon />}
        />
        <ExcursionServiceCard
          serviceType={ExcursionServiceType.FOOD}
          service={selectedExcursion.foodService}
          icon={<RestaurantIcon />}
        />
      </Box>

      <Typography variant="h6" fontWeight="bold">
        Restrição
      </Typography>

      <Typography>
        {selectedExcursion.restriction.length > 0
          ? selectedExcursion.restriction
          : "Esta excursão não possui restrições"}
      </Typography>

      {selectedExcursion?.router.length > 0 && (
        <>
          {sectionDivider}

          <Box sx={{ ...sharedStyles.verticalContent }}>
            <Typography variant="h5" fontWeight="bold">
              Roteiro
            </Typography>

            <ul>
              {selectedExcursion?.router.map((route, index) => (
                <li key={`${index}_${route}`}>{route}</li>
              ))}
            </ul>
          </Box>
        </>
      )}

      {sectionDivider}

      <Typography variant="h5" fontWeight="bold">
        Explore seu destino!
      </Typography>

      <PrimaryButton
        label={`${selectedExcursion?.city} - ${selectedExcursion?.state} (${selectedExcursion?.region}) | ${selectedExcursion?.country}`}
        icon={<RoomIcon />}
        onClickAction={() =>
          window.open(
            `https://www.google.com/maps/place/${selectedExcursion?.city} - ${selectedExcursion?.state} (${selectedExcursion?.region}) | ${selectedExcursion?.country}`,
            "_blank"
          )
        }
        styles={{ color: "secondary.main" }}
      />
    </Box>
  );

  const renderServiceViewContent = (
    <>
      <LeftMarginBackButton />

      <div style={sharedStyles.form}>
        {renderExcursionShowcase}
        {renderExcursionDetails}
      </div>
    </>
  );

  return <ComercialDrawer content={renderServiceViewContent} />;
};

export default ExcursionDetails;
