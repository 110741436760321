import { useEffect, useState } from "react";
import { useAuth } from "../../context/AuthProvider/useAuth";
import { fetchManagerStores } from "../../utils/fetchEntities";
import useManagerStores from "../context-hooks/useManagerStores";
import { areStringifiedObjectsEqual } from "../../utils/miscelaneous";

const useFetchManagerStores = () => {
  const auth = useAuth();

  const { stores, setStores } = useManagerStores();
  const [loadingManagerStores, setLoadingManagerStores] = useState(false);

  const fetchStores = () => {
    setLoadingManagerStores(true);

    fetchManagerStores(auth.user.id)
      .then(
        (storesResponse) =>
          !areStringifiedObjectsEqual(storesResponse, stores) &&
          setStores(storesResponse)
      )
      .finally(() => setLoadingManagerStores(false));
  };

  useEffect(() => {
    if (auth.user.id) fetchStores();
  }, [auth.user.id]);

  return { fetchStores, loadingManagerStores };
};

export default useFetchManagerStores;
