import { Dispatch, SetStateAction } from "react";
import { handleRemoveImageFromArray } from "../../../../utils/image/imageFunctions";

export const handleDeleteExistingOriginalAndPreviewImageClick = (
  index: number,
  setOriginalImages: Dispatch<SetStateAction<string[]>>,
  setPreviewImages: Dispatch<SetStateAction<string[]>>
) => {
  setOriginalImages((prevUrls) => handleRemoveImageFromArray(prevUrls, index));
  setPreviewImages((prevUrls) => handleRemoveImageFromArray(prevUrls, index));
};
