import { ChangeEvent, Dispatch, SetStateAction } from "react";

export const handleSelectHTMLCanvaImage = async (
  e: ChangeEvent<HTMLInputElement>,
  setImageArray: Dispatch<SetStateAction<HTMLCanvasElement[]>>
) => {
  const files = e.target.files;

  if (!files) return;

  const selectedImages: HTMLCanvasElement[] = [];

  await Promise.all(
    Array.from(files).map(
      (file) =>
        new Promise<void>((resolve) => {
          const img = new Image();

          img.src = URL.createObjectURL(file);

          img.decode().then(() => {
            const canvas = document.createElement("canvas");

            Object.assign(canvas, { width: img.width, height: img.height });

            canvas.getContext("2d")?.drawImage(img, 0, 0);

            selectedImages.push(canvas);
            resolve();
          });
        })
    )
  );

  setImageArray((previousImages) => [...previousImages, ...selectedImages]);
};

export const handleRemoveImageFromArray = (
  images: any[],
  index: number
): any[] => images.filter((_, i) => i !== index);
