import { Box, TextField, Typography } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import LoginIcon from "@mui/icons-material/Login";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import Alert from "@mui/material/Alert";
import { PrimaryButton } from "../../../components/general/buttons/PrimaryButton";
import { useAuth } from "../../../context/AuthProvider/useAuth";
import { OneClickPlatform, UserRole } from "../../../types/types";
import { loginSchema } from "../../../utils/yupSchemas";
import {
  CURRENT_SUBDOMAIN,
  DEMANDORIA_SUBDOMAINS,
} from "../../../utils/stringUtils";
import { sharedStyles } from "@/styles/shared";
import FullBrandLogo from "@/components/turism/FullBrandLogo";
import { GoogleOAuthProvider, GoogleLogin } from "@react-oauth/google";
import GoogleLoginComercialOptionModal from "./GoogleLoginComercialOptionModal";

const Login = () => {
  const auth = useAuth();
  const navigate = useNavigate();

  const emailRef = useRef<HTMLInputElement>(null);
  const passwordRef = useRef<HTMLInputElement>(null);
  const {
    register,
    handleSubmit: onSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(loginSchema) });

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [requestErrorFlag, setRequestErrorFlag] = useState<boolean>(false);

  const [openComercialLoginOptionModal, setOpenComercialLoginOptionModal] =
    useState<string | null>(null);

  useEffect(() => {
    if (auth.isLoggedIn) {
      switch (auth.user.role) {
        case UserRole.CLIENT:
          navigate("/home");
          break;
        case UserRole.COMERCIAL:
          CURRENT_SUBDOMAIN === DEMANDORIA_SUBDOMAINS[0] ||
          CURRENT_SUBDOMAIN === DEMANDORIA_SUBDOMAINS[1]
            ? navigate("/appointments")
            : navigate("/agencies");
        default:
          break;
      }
    }
  }, [auth]);

  const handleSubmit = async () => {
    const email = emailRef.current.value;
    const password = passwordRef.current.value;

    setRequestErrorFlag(false);
    setIsLoading(true);

    try {
      await auth.authenticate(email, password);
    } catch (error) {
      setRequestErrorFlag(true);
      setIsLoading(false);
      console.warn("Error on user login: ", error);
    }
  };

  useEffect(() => {
    setRequestErrorFlag(false);
  }, [emailRef.current?.value, passwordRef.current?.value]);

  return (
    <Box
      sx={{
        ...sharedStyles.generalContainer,
        ...sharedStyles.form,
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <GoogleLoginComercialOptionModal
        credential={openComercialLoginOptionModal}
        setOpen={setOpenComercialLoginOptionModal}
      />

      <Box
        sx={{
          width: "100%",
          maxWidth: "600px",
          ...sharedStyles.form,
          paddingX: { xs: 3, md: 3, lg: 10 },
        }}
      >
        <Box sx={{ ...sharedStyles.verticalContent, alignItems: "center" }}>
          {CURRENT_SUBDOMAIN === DEMANDORIA_SUBDOMAINS[0] ? (
            <Typography variant="h4" fontWeight="bold" textAlign="center">
              Demandoria Beleza
            </Typography>
          ) : CURRENT_SUBDOMAIN === DEMANDORIA_SUBDOMAINS[1] ? (
            <Typography variant="h4" fontWeight="bold" textAlign="center">
              Demandoria PET
            </Typography>
          ) : (
            <FullBrandLogo
              style={{
                width: 200,
                height: "auto",
              }}
            />
          )}

          <Typography>Conecte-se à sua conta</Typography>
        </Box>

        <form onSubmit={onSubmit(handleSubmit)} style={sharedStyles.form}>
          <TextField
            label="Email*"
            type="email"
            inputRef={emailRef}
            {...register("email")}
            error={!!errors.email || requestErrorFlag}
            helperText={errors.email?.message}
            fullWidth
          />

          <Box sx={sharedStyles.verticalContent}>
            <TextField
              label="Senha*"
              type="password"
              inputRef={passwordRef}
              {...register("password")}
              error={!!errors.password || requestErrorFlag}
              helperText={errors.password?.message}
              fullWidth
            />
            <Typography
              onClick={() => navigate("/password-recovery")}
              component={"span"}
              variant="body2"
              sx={{
                alignSelf: "flex-end",
                fontWeight: "bold",
                color: "text.secondary",
                cursor: "pointer",
              }}
            >
              Esqueceu sua senha?
            </Typography>
          </Box>

          <Box sx={sharedStyles.verticalContent}>
            <PrimaryButton
              label="Entrar"
              icon={<LoginIcon />}
              isLoading={isLoading}
              styles={sharedStyles.secondaryColor}
            />

            {requestErrorFlag && (
              <Alert
                severity="error"
                sx={{ width: "100%", padding: "0px 10px" }}
              >
                Email ou senha inválidos.
              </Alert>
            )}
          </Box>
        </form>

        <Typography sx={{ alignSelf: "center" }}>
          Usuário novo?{" "}
          <Typography
            onClick={() => navigate("/register")}
            component={"span"}
            sx={{
              fontWeight: "bold",
              color: "primary.main",
              cursor: "pointer",
            }}
          >
            Cadastre-se
          </Typography>
        </Typography>

        <Box
          sx={{ ...sharedStyles.horizontalContent, justifyContent: "center" }}
        >
          <GoogleOAuthProvider
            clientId={import.meta.env.VITE_GOOGLE_THIRD_PARTY_LOGIN_CLIENT_ID}
          >
            <GoogleLogin
              onSuccess={async (credentialResponse) => {
                const accessToken = credentialResponse.credential;

                try {
                  await auth.authenticateWithGoogle(accessToken);
                } catch (error) {
                  setOpenComercialLoginOptionModal(accessToken);
                }
              }}
              onError={() => alert("Falha no login com Google")}
              useOneTap
              size="medium"
            />
          </GoogleOAuthProvider>
        </Box>
      </Box>
    </Box>
  );
};

export default Login;
