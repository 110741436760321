import { useAuth } from "../../../context/AuthProvider/useAuth";
import { Navigate, Outlet } from "react-router-dom";

const ProtectedComponent = () => {
  const auth = useAuth();

  const isLoading = () => auth.loading;

  const isUserLoggedIn = () => auth.isLoggedIn;

  return isLoading() ? (
    <></>
  ) : isUserLoggedIn() ? (
    <Outlet />
  ) : (
    <Navigate to="/login" />
  );
};

export default ProtectedComponent;
