import {
  invalidAvailability,
  invalidAvailabilityBorder,
  validAvailability,
  validAvailabilityBorder,
} from "@/styles/colors";
import { sharedStyles } from "@/styles/shared";
import { CURRENT_SUBDOMAIN, DEMANDORIA_SUBDOMAINS } from "@/utils/stringUtils";
import { Box, SxProps, Typography } from "@mui/material";

const WorkingScaleCaption = () => {
  return (
    <Box sx={{ ...sharedStyles.horizontalContent, gap: 3 }}>
      <Box sx={sharedStyles.horizontalContent}>
        <Box
          sx={{
            ...styles.captionRectangle,
            backgroundColor: validAvailability,
            border: `1px solid ${validAvailabilityBorder}`,
          }}
        />
        <Typography>
          {CURRENT_SUBDOMAIN === DEMANDORIA_SUBDOMAINS[2]
            ? "Aberto"
            : "Disponível"}
        </Typography>
      </Box>

      <Box sx={sharedStyles.horizontalContent}>
        <Box
          sx={{
            ...styles.captionRectangle,
            backgroundColor: invalidAvailability,
            border: `1px solid ${invalidAvailabilityBorder}`,
          }}
        />
        <Typography>
          {" "}
          {CURRENT_SUBDOMAIN === DEMANDORIA_SUBDOMAINS[2]
            ? "Fechado"
            : "Ocupado"}
        </Typography>
      </Box>
    </Box>
  );
};

export default WorkingScaleCaption;

const styles = {
  captionRectangle: {
    width: "30px",
    height: "20px",
  } as SxProps,
};
