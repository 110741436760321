import {
  Box,
  Button,
  Chip,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import AddLocationAltIcon from "@mui/icons-material/AddLocationAlt";
import { Dispatch, SetStateAction, useRef, useState } from "react";
import MoreTimeIcon from '@mui/icons-material/MoreTime';
import { sharedStyles } from "@/styles/shared";
import { ALL_TIMES } from "@/pages/turism/Comercial/workingTimeConstants";
import { getHoursFromRouter, sortPossibleHoursArray } from "./functions";

interface ExcursionRouterProps {
  router: string[];
  setRouter: Dispatch<SetStateAction<string[]>>;
}

const ExcursionRouter = (props: ExcursionRouterProps) => {
  const { router, setRouter } = props;

  const routerRef = useRef<HTMLInputElement>(null);
  const hourRef = useRef<HTMLSelectElement>(null);

  const [possibleTimes, setPossibleTimes] = useState<string[]>(
    ALL_TIMES.filter((hour) => !getHoursFromRouter(router).includes(hour))
  );

  const handleAddRoute = () => {
    if (
      routerRef.current.value.length > 0 &&
      hourRef.current.value.length > 0
    ) {
      setPossibleTimes((previousPossibleTimes) =>
        previousPossibleTimes.filter((time) => time !== hourRef.current.value)
      );

      setRouter((previousRouter) =>
        sortPossibleHoursArray([
          ...previousRouter,
          `${hourRef.current.value} - ${routerRef.current.value}`,
        ])
      );

      routerRef.current.value = "";
    }
  };

  const handleDeleteRoute = (route: string, index: number) => {
    const hour = route.split(" ")[0];

    setPossibleTimes((previousPossibleTimes) =>
      sortPossibleHoursArray([...previousPossibleTimes, hour])
    );
    setRouter((previousRouter) => previousRouter.toSpliced(index, 1));
  };

  return (
    <Box sx={sharedStyles.verticalContent}>
      <Box sx={sharedStyles.horizontalContent}>
        <FormControl size="small" sx={{ width: "30%", maxWidth: "100px" }}>
          <InputLabel>Horário *</InputLabel>
          <Select label="Horário *" inputRef={hourRef}>
            {possibleTimes.map((hour) => (
              <MenuItem value={hour}>{hour}</MenuItem>
            ))}
          </Select>
        </FormControl>

        <Box sx={{ ...sharedStyles.horizontalContent, gap: 0, width: "100%" }}>
          <TextField
            label="Adicione fases ao roteiro"
            fullWidth
            size="small"
            inputRef={routerRef}
          />
          <Button
            onClick={handleAddRoute}
            variant="contained"
            disableElevation
            sx={{ minHeight: "40px" }}
          >
            <MoreTimeIcon sx={{ color: "secondary.main" }} />
          </Button>
        </Box>
      </Box>

      <Box>
        {router.map((route, index) => (
          <Chip
            label={route}
            onDelete={() => handleDeleteRoute(route, index)}
            sx={{
              margin: "5px",
              marginLeft: 0,
              fontSize: "18px",
              padding: "5px",
            }}
            key={index}
          />
        ))}
      </Box>
    </Box>
  );
};

export default ExcursionRouter;
