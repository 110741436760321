import {
  Box,
  Divider,
  SxProps,
  Typography,
} from "@mui/material";
import { ReactNode } from "react";

interface ComercialInterfaceHeaderProps {
  title: string;
  actionNode?: ReactNode;
}

const ComercialInterfaceHeader = (props: ComercialInterfaceHeaderProps) => {
  const { title, actionNode } = props;

  return (
    <Box sx={styles.container}>
      <Box sx={styles.innerContainer}>
        <Typography variant="h6" fontWeight="bold">
          {title}
        </Typography>
        {actionNode}
      </Box>
      <Divider />
    </Box>
  );
};

export default ComercialInterfaceHeader;

const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    gap: "5px",
  } as SxProps,

  innerContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  } as SxProps,
};
