import { useNavigate } from "react-router-dom";
import { setLocalStorageItem } from "../../../../../hooks/localStorageHooks";
import { IStore } from "../../../../../services/api/Store/types";
import { handleDeleteStore } from "@/pages/petAndBeauty/Comercial/StoresPages/Stores/functions";

const functions = () => {
  const navigate = useNavigate();

  const handleSelectStore = (store: IStore) =>
    setLocalStorageItem("selectedStore", store);

  const handleSeeStoreDetailsClick = (store: IStore) => {
    handleSelectStore(store);
    navigate(`/agencies/details?id=${store.id}`);
  };

  const handleEditStoreClick = (store: IStore) => {
    handleSelectStore(store);
    navigate(`/agencies/edit?id=${store.id}`);
  };

  const handleDeleteManagerStore = (store: IStore) => handleDeleteStore(store);

  return {
    handleSeeStoreDetailsClick,
    handleEditStoreClick,
    handleDeleteManagerStore,
  };
};

export default functions;
