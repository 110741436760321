import axios from "axios";
import { useEffect, useState } from "react";

const NOMINATIM_BASE_URL = "https://nominatim.openstreetmap.org/";

export interface AddressProps {
  street?: string;
  number?: string;
  district?: string;
  city?: string;
  state?: string;
  region?: string;
  country?: string;
}

export const getCoordinatesFromAddress = async (props: AddressProps) => {
  let latitude: number = 0;
  let longitude: number = 0;

  try {
    const response = await axios.get(
      `${NOMINATIM_BASE_URL}search?q=${`${props.city} ${props.state} ${props.region} ${props.country}`}&format=json`
    );

    latitude = Number(response.data[0].lat);
    longitude = Number(response.data[0].lon);
  } catch (error) {
    console.warn("Error fetching nominatim coordinates by address: ", error);
  }

  return [latitude, longitude];
};

const fetchCoordinatesFromAdress = (props: AddressProps) => {
  const { city } = props;

  const [coordinates, setCoordinates] = useState([0, 0]);

  useEffect(() => {
    city &&
      getCoordinatesFromAddress(props).then((response) =>
        setCoordinates(response)
      );
  }, [city]);

  return { coordinates };
};

export default fetchCoordinatesFromAdress;
