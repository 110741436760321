import DemandoriaIcon from "../../../assets/svg/logo-full.svg";
import Link from "@mui/material/Link";
import { Box, SxProps, Typography } from "@mui/material";
import { sharedStyles } from "@/styles/shared";
import { filteredTurismSecondary, generalWhite } from "@/styles/colors";
import FullBrandLogo from "@/components/turism/FullBrandLogo";

const footerContainer: SxProps = {
  width: "100%",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  gap: 2,
  padding: 4,
  color: "text.secondary",
  backgroundColor: "text.primary",
  textAlign: "center",
};

const PeregrinFooter = () => {
  return (
    <Box sx={footerContainer}>
      <FullBrandLogo
        style={{
          width: 150,
          height: "auto",
        }}
        color={filteredTurismSecondary}
      />

      <Box sx={{ ...sharedStyles.verticalContent, gap: 1 }}>
        <Typography variant="body2">
          Copyright © {new Date().getFullYear()} Peregrin
        </Typography>

        {/* <Typography variant="body2">
          Contact:{" "}
          <Link
            href="mailto:demandoria@gmail.com"
            color="secondary.main"
            underline="none"
            sx={{ fontWeight: "bold" }}
          >
            demandoria@gmail.com
          </Link>
        </Typography> */}
      </Box>
    </Box>
  );
};

export default PeregrinFooter;
