import { Box, Divider, Typography } from "@mui/material";
import ComercialDrawer from "../../../../../components/turism/ComercialDrawer";
import { type IStore } from "../../../../../services/api/Store/types";
import ImageCarousel from "../../../../../components/general/ImageCarousel";
import { generalDarkGrey } from "../../../../../styles/colors";
import TimeRangeInterpreter from "../../../../../components/comercial/TimeRangeInterpreter";
import CustomCard from "../../../../../components/general/CustomCard";
import { MapContainer, Marker, TileLayer } from "react-leaflet";
import defaultExcursionUrl from "../../../../../assets/svg/defaultExcursion.svg";
import NearMeIcon from "@mui/icons-material/NearMe";
import ArticleIcon from "@mui/icons-material/Article";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import EmailIcon from "@mui/icons-material/Email";
import { getLocalStorageItem } from "../../../../../hooks/localStorageHooks";
import useFetchExcursionsFromStore from "@/hooks/entities/useFetchExcursionsFromStore";
import { sharedStyles } from "@/styles/shared";
import { LeftMarginBackButton } from "@/components/general/buttons/BackPageButton";
import RichTextRenderer from "@/components/general/Tiptap/RichTextRenderer";
import { priceFormatter } from "@/utils/priceFormatter";
import CollapseWrapper from "@/components/general/Tiptap/RichTextDescriptionWrapper";

const AgencyDetails = () => {
  const selectedStore: IStore = getLocalStorageItem("selectedStore");

  const { excursions } = useFetchExcursionsFromStore(selectedStore.id);

  const sectionDivider = (
    <Divider
      sx={{
        width: "100%",
        color: generalDarkGrey,
      }}
    />
  );

  const renderStoreData = (
    <Box sx={{ ...sharedStyles.verticalContent, gap: 3 }}>
      <Typography variant="h4" fontWeight="bold" textAlign="center">
        {selectedStore.name}
      </Typography>

      {selectedStore.imageOriginal.length > 0 && (
        <Box width="100%" height="300px">
          <ImageCarousel imageUrls={selectedStore.imageOriginal} />
        </Box>
      )}

      {selectedStore.description.length > 0 && (
        <Box width="100%">
          <CollapseWrapper
            label="Descrição da agência"
            content={
              <RichTextRenderer htmlContent={selectedStore?.description} />
            }
          />
        </Box>
      )}
    </Box>
  );

  const renderStoreLocation = (
    <Box sx={sharedStyles.verticalContent}>
      <MapContainer
        zoom={17}
        center={[selectedStore.lat, selectedStore.lng]}
        style={{ height: "400px" }}
        dragging={false}
      >
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        <Marker
          key={selectedStore.id}
          position={[selectedStore.lat, selectedStore.lng]}
        />
      </MapContainer>

      <Box sx={sharedStyles.horizontalContent}>
        <NearMeIcon />

        <Typography textAlign="justify">{`${selectedStore.logradouro}, ${
          selectedStore.number || "s/n"
        } - ${selectedStore.district}, ${selectedStore.city} - ${
          selectedStore.state
        }, ${selectedStore.CEP}`}</Typography>
      </Box>
    </Box>
  );

  const renderStoreWorkscale = (
    <Box sx={sharedStyles.verticalContent}>
      <Typography variant="h5" fontWeight="bold" textAlign="center">
        Horários de funcionamento
      </Typography>

      <TimeRangeInterpreter
        timeMonday={selectedStore.timeMonday}
        timeTuesday={selectedStore.timeTuesday}
        timeWednesday={selectedStore.timeWednesday}
        timeThursday={selectedStore.timeThursday}
        timeFriday={selectedStore.timeFriday}
        timeSaturday={selectedStore.timeSaturday}
        timeSunday={selectedStore.timeSunday}
      />
    </Box>
  );

  const renderStoreExcursions = (
    <Box sx={sharedStyles.verticalContent}>
      <Typography variant="h5" fontWeight="bold" textAlign="center">
        Nossas excursões
      </Typography>

      <Box sx={sharedStyles.horizontalScrollSection}>
        {excursions &&
          excursions.map((excursion) => (
            <CustomCard
              photoURL={excursion.imagePreview[0]}
              primaryText={excursion.title}
              header={`R$ ${priceFormatter(excursion?.price)}`}
              secondaryText={`${excursion.city} - ${excursion.country}`}
              defaultPhoto={defaultExcursionUrl}
              key={excursion.id}
            />
          ))}
      </Box>
    </Box>
  );

  const renderStoreContacts = (
    <Box sx={sharedStyles.verticalContent}>
      <Typography fontWeight="bold" sx={{ marginBottom: "15px" }}>
        Informações de contato:
      </Typography>

      <Box sx={sharedStyles.horizontalContent}>
        <ArticleIcon sx={{ color: "primary.main" }} />
        <Typography>{selectedStore.CNPJ} (CNPJ)</Typography>
      </Box>

      <Box sx={sharedStyles.horizontalContent}>
        <LocalPhoneIcon sx={{ color: "primary.main" }} />
        <Typography>{selectedStore.phone}</Typography>
      </Box>

      <Box sx={sharedStyles.horizontalContent}>
        <EmailIcon sx={{ color: "primary.main" }} />
        <Typography>{selectedStore.email}</Typography>
      </Box>
    </Box>
  );

  const renderAddStorePageContent = (
    <>
      <LeftMarginBackButton />

      <Box sx={sharedStyles.form}>
        {renderStoreData}

        {renderStoreLocation}

        {sectionDivider}

        {renderStoreWorkscale}

        {sectionDivider}

        {renderStoreExcursions}

        {sectionDivider}

        {renderStoreContacts}
      </Box>
    </>
  );

  return <ComercialDrawer content={renderAddStorePageContent} />;
};

export default AgencyDetails;
