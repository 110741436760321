import { Api } from "../..";
import { OneClickPlatform, UserRole } from "../../../types/types";
import { responseBody } from "../util";

export const login = async (email: string, password: string) => {
  return await Api.post("/login", { email, password }).then(responseBody);
};

export const refreshToken = async (oldToken: string) => {
  return await Api.put("token/refresh", { oldToken }).then(responseBody);
};

const thirdPartyLogin = async (
  oneClickPlatform: OneClickPlatform,
  token: string
) => {
  return await Api.post("/login-third-party", {
    oneClickPlatform,
    token,
  }).then(responseBody);
};

export const googleLogin = async (token: string) => {
  const response = await thirdPartyLogin(OneClickPlatform.GOOGLE, token);

  return response.access_token;
};

const createThirdParty = async (
  oneClickPlatform: OneClickPlatform,
  token: string,
  role: UserRole
) => {
  return await Api.post("/create-third-party", {
    oneClickPlatform,
    token,
    role,
  })
    .then(responseBody)
    .catch((error) => console.error(error));
};

export const createGoogleLogin = async (token: string, role: UserRole) => {
  const response = await createThirdParty(OneClickPlatform.GOOGLE, token, role);

  return response.access_token;
};
