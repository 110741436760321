import { Dispatch, SetStateAction } from "react";
import storeApi from "../services/api/Store";
import { IServiceStore } from "../services/api/ServiceStore/types";
import serviceStoreApi from "../services/api/ServiceStore";
import employeeApi from "../services/api/Employee";
import { IEmployee } from "../services/api/Employee/types";
import { IRating } from "../services/api/Rating/types";
import ratingApi from "../services/api/Rating";
import { userApi } from "../services";
import { IUser } from "../services/api/User/types";
import excursionApi from "../services/api/Excursion";
import appointmentApi from "../services/api/Appointment";
import serviceApi from "../services/api/Service";
import dayjs from "dayjs";
import cartApi from "../services/api/Cart";

export const fetchStore = async (id: string) => {
  try {
    const storeResponse = await storeApi.getStore(id);
    return storeResponse;
  } catch (error) {
    console.warn("Error fetching store: ", error);
  }
};

export const fetchManagerStores = async (id: string) => {
  try {
    const managerStoresResponse = await storeApi.getManagerStores(id);
    return managerStoresResponse;
  } catch (error) {
    console.warn("Error fetching manager's stores: ", error);
  }
};

export const fetchServicesForAutocomplete = async (
  serviceNameInput: string
) => {
  try {
    const servicesResponse = await serviceApi.searchServices(serviceNameInput);
    return servicesResponse;
  } catch (error) {
    console.warn("Error fetching searched services: ", error);
  }
};

export const fetchServiceStore = async (
  id: string,
  setServiceStore: Dispatch<SetStateAction<IServiceStore>>
) => {
  try {
    const serviceStoreResponse = await serviceStoreApi.getServiceStore(id);
    setServiceStore(serviceStoreResponse);
  } catch (error) {
    console.warn("Error fetching service-store: ", error);
  }
};

export const fetchEmployeesFromAStore = async (storeId: string) => {
  try {
    const employeesResponse = await storeApi.getEmployeesFromStore(storeId);
    return employeesResponse;
  } catch (error) {
    console.warn("Error fetching employees from store: ", error);
  }
};

export const fetchServiceStoresFromStore = async (storeId: string) => {
  try {
    const serviceStoreResponse =
      await serviceStoreApi.getServiceStoresFromStoreId(storeId);
    return serviceStoreResponse;
  } catch (error) {
    console.warn("Error fetching store's service-stores: ", error);
  }
};

export const fetchServiceStoreRating = async (
  id: string,
  setRatings: Dispatch<SetStateAction<IRating[]>>
) => {
  try {
    const ratingsResponse = await ratingApi.getRatingFromServiceStore(id);
    setRatings(ratingsResponse);
  } catch (error) {
    console.warn("Error fetching service-store ratings: ", error);
  }
};

export const fetchExcursion = async (id: string) => {
  try {
    const response = await excursionApi.getExcursion(id);
    return response;
  } catch (error) {
    console.warn("Error fetching excursion: ", error);
  }

  return null;
};

export const fetchExcursionsFromStore = async (storeId: string) => {
  try {
    const storeExcursionResponse =
      await excursionApi.getExcursionsFromAStore(storeId);
    return storeExcursionResponse;
  } catch (error) {
    console.warn("Error fetching store's excursions: ", error);
  }
};

export const fetchEmployee = async (
  id: string,
  setEmployee: Dispatch<SetStateAction<IEmployee>>
) => {
  try {
    const employeeResponse = await employeeApi.getEmployee(id);
    setEmployee(employeeResponse);
  } catch (error) {
    console.warn("Error fetching employee: ", error);
  }
};

export const fetchEmployeeUser = async (
  userId: string,
  setEmployeeUser: Dispatch<SetStateAction<IUser>>
) => {
  try {
    const userResponse = await userApi.getUser(userId);
    setEmployeeUser(userResponse);
  } catch (error) {
    console.warn("Error fetching selected employee user: ", error);
  }
};

export const fetchEmployeesFromStore = async (
  id: string,
  setStoreEmployees: Dispatch<SetStateAction<IEmployee[]>>
) => {
  try {
    const employeeRes = await employeeApi.getEmployeesFromStoreId(id);
    setStoreEmployees(employeeRes);
  } catch (error) {
    console.warn("Error fetching store's employees: ", error);
  }
};

export const fetchUserEmployees = async (userId: string) => {
  try {
    const userEmployeeResponse =
      await employeeApi.getEmployeeFromUserId(userId);
    return userEmployeeResponse;
  } catch (error) {
    console.warn("Error fetching user employee: ", error);
  }
};

export const fetchAppointmentRating = async (
  appointmentId: string,
  userId: string
) => {
  try {
    const appointmentRatingResponse =
      await ratingApi.getRatingFromUserRatedAppointment(appointmentId, userId);

    return appointmentRatingResponse;
  } catch (error) {
    console.error("Error fetching appointment rating: ", error);
  }
};

export const searchPromotionalServices = async (
  serviceId: string,
  startDate: string,
  endDate: string
) => {
  const highlightedDays = [];
  let intervalAppointments = null;

  try {
    const intervalAppointmentsResponse =
      await appointmentApi.getIntervalAppointments(
        serviceId,
        startDate,
        endDate
      );

    intervalAppointments = intervalAppointmentsResponse;

    intervalAppointments.promotion.forEach((promotion) =>
      highlightedDays.push(promotion.date)
    );
  } catch (error) {
    console.warn("Error fetching promotional services: ", error);
  }

  return {
    promotion: intervalAppointments.promotion,
    schedule: intervalAppointments.schedule,
  };
};

export const fetchUserAppointments = async (userId: string) => {
  try {
    const userAppointmentsResponse =
      await appointmentApi.getAppointmentsFromClient(
        userId,
        new Date(dayjs().toISOString())
      );

    return userAppointmentsResponse;
  } catch (error) {
    console.warn("Error fetching user appointments: ", error);
  }
};

export const fetchShoppingCart = async (userId: string) => {
  try {
    const shoppingCartResponse = await cartApi.getUserCart(userId);
    return shoppingCartResponse;
  } catch (error) {
    console.warn("Error fetching user's shopping cart: ", error);
  }
};
