import { useEditor, EditorContent } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import TiptapToolbar from "./TiptapToolbar";
import { Box } from "@mui/material";
import { sharedStyles } from "@/styles/shared";
import Placeholder from "@tiptap/extension-placeholder";
import { useRef } from "react";

interface TiptapProps {
  defaultValue?: string;
  onChange: (richText: string) => void;
  placeholder?: string;
}

const Tiptap = (props: TiptapProps) => {
  const { defaultValue, placeholder, onChange } = props;
  const descriptionRef = useRef("");

  const editor = useEditor({
    extensions: [
      StarterKit,
      Placeholder.configure({ placeholder: placeholder }),
    ],
    content: defaultValue || "",
    onUpdate({ editor }) {
      descriptionRef.current = editor.getHTML();
      onChange(descriptionRef.current);
    },
  });

  return (
    <Box
      sx={{ ...sharedStyles.verticalContent, gap: 0, color: "text.primary" }}
    >
      <TiptapToolbar editor={editor} />
      <EditorContent editor={editor} autoFocus />
    </Box>
  );
};

export default Tiptap;
