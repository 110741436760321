import { useNavigate } from "react-router-dom";
import { setLocalStorageItem } from "../../../../../hooks/localStorageHooks";
import excursionApi from "../../../../../services/api/Excursion";
import IExcursion from "../../../../../services/api/Excursion/IExcursion";
import { IStore } from "../../../../../services/api/Store/types";
import { clearFirebaseFolder } from "../../../../../utils/firebase/functions";
import { CURRENT_SUBDOMAIN } from "../../../../../utils/stringUtils";

const functions = () => {
  const navigate = useNavigate();

  const handleSelectStore = (store: IStore) => {
    const { id, name } = store;
    setLocalStorageItem("selectedStore", { id, name });
  };

  const handleSelectExcursion = (excursion: IExcursion) =>
    setLocalStorageItem("selectedExcursion", excursion);

  const handleSeeExcursionDetailsClick = (excursion: IExcursion) => {
    handleSelectExcursion(excursion);
    navigate("/excursions/details");
  };

  const handleAddExcursionClick = (store: IStore) => {
    handleSelectStore(store);
    navigate("/excursions/add");
  };

  const handleEditExcursionClick = (store: IStore, excursion: IExcursion) => {
    setLocalStorageItem("storeData", {
      storeName: store.name,
      storeId: store.id,
    });
    handleSelectExcursion(excursion);
    navigate("/excursions/edit");
  };

  const handleDeleteExcursionClick = async (
    excursion: IExcursion,
    store: IStore,
    reloadFunction: VoidFunction
  ) => {
    if (
      window.confirm(
        `Tem certeza que deseja excluir a excursão ${excursion.title}?`
      )
    ) {
      try {
        const excursionDeletionPromises = [];

        if (excursion.imageOriginal.length > 0)
          excursionDeletionPromises.push(
            clearFirebaseFolder(
              `${CURRENT_SUBDOMAIN}/stores/${store.name}_${store.id}/excursions/${excursion.title}_${excursion.id}`
            )
          );

        excursionDeletionPromises.push(
          excursionApi.deleteExcursion(excursion.id)
        );

        await Promise.all(excursionDeletionPromises);

        reloadFunction();
      } catch (error) {
        console.warn("Error deleting an excursion: ", error);
      }
    }
  };

  return {
    handleAddExcursionClick,
    handleEditExcursionClick,
    handleDeleteExcursionClick,
    handleSeeExcursionDetailsClick,
  };
};

export default functions;
