import { Box, Divider, Typography } from "@mui/material";
import { type IStore } from "../../../../services/api/Store/types";
import { MapContainer, Marker, TileLayer } from "react-leaflet";
import { lazy, useEffect, useState } from "react";
import { priceFormatter } from "../../../../utils/priceFormatter";
import { useNavigate, useSearchParams } from "react-router-dom";
import TimeRangeInterpreter from "../../../../components/comercial/TimeRangeInterpreter";
import {
  fetchExcursionsFromStore,
  fetchStore,
} from "../../../../utils/fetchEntities";
import IExcursion from "../../../../services/api/Excursion/IExcursion";
import defaultExcursionImage from "../../../../assets/svg/defaultExcursion.svg";
import { sharedStyles } from "@/styles/shared";
import RichTextRenderer from "@/components/general/Tiptap/RichTextRenderer";
import NearMeIcon from "@mui/icons-material/NearMe";
import ArticleIcon from "@mui/icons-material/Article";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import EmailIcon from "@mui/icons-material/Email";
import BackPageButton, {
  backButtonContainer,
} from "@/components/general/buttons/BackPageButton";
import CollapseWrapper from "@/components/general/Tiptap/RichTextDescriptionWrapper";
import PeregrinClientNavbar from "@/components/general/Navbars/ClientNavbar/PeregrinClientNavbar";
const CustomCard = lazy(
  () => import("../../../../components/general/CustomCard")
);
const ImageCarousel = lazy(
  () => import("../../../../components/general/ImageCarousel")
);

const TurismStorePage = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const selectedStoreId = searchParams.get("id");

  const navigate = useNavigate();

  const [store, setStore] = useState<IStore | null>(null);

  const [excursions, setExcursions] = useState<IExcursion[]>([]);

  useEffect(() => {
    const fetchStoreData = async () => {
      await Promise.all([
        fetchStore(selectedStoreId!).then((response) => setStore(response)),
        fetchExcursionsFromStore(selectedStoreId!).then((response) =>
          setExcursions(response)
        ),
      ]);
    };

    fetchStoreData();
  }, []);

  const handleSelectExcursionClick = (excursionId: string) =>
    navigate(`/excursion?id=${excursionId}`);

  const renderStoreData = (
    <Box sx={{ ...sharedStyles.verticalContent, gap: 3 }}>
      <Typography variant="h4" fontWeight="bold" textAlign="center">
        {store?.name}
      </Typography>

      {store?.imageOriginal.length > 0 && (
        <Box width="100%" height="300px">
          <ImageCarousel imageUrls={store?.imageOriginal} />
        </Box>
      )}

      {store?.description.length > 0 && (
        <Box width="100%">
          <CollapseWrapper
            label="Descrição da agência"
            content={<RichTextRenderer htmlContent={store?.description} />}
          />
        </Box>
      )}
    </Box>
  );

  const renderStoreLocation = (
    <Box sx={sharedStyles.verticalContent}>
      <MapContainer
        zoom={17}
        center={[store?.lat, store?.lng]}
        style={{ height: "400px" }}
        dragging={false}
      >
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        <Marker key={store?.id} position={[store?.lat, store?.lng]} />
      </MapContainer>

      <Box sx={sharedStyles.horizontalContent}>
        <NearMeIcon />

        <Typography textAlign="justify">{`${store?.logradouro}, ${
          store?.number || "s/n"
        } - ${store?.district}, ${store?.city} - ${
          store?.state
        }, ${store?.CEP}`}</Typography>
      </Box>
    </Box>
  );

  const renderStoreContacts = (
    <Box sx={sharedStyles.verticalContent}>
      <Typography fontWeight="bold" sx={{ marginBottom: "15px" }}>
        Informações de contato:
      </Typography>

      <Box sx={sharedStyles.horizontalContent}>
        <ArticleIcon sx={{ color: "primary.main" }} />
        <Typography>{store?.CNPJ} (CNPJ)</Typography>
      </Box>

      <Box sx={sharedStyles.horizontalContent}>
        <LocalPhoneIcon sx={{ color: "primary.main" }} />
        <Typography>{store?.phone}</Typography>
      </Box>

      <Box sx={sharedStyles.horizontalContent}>
        <EmailIcon sx={{ color: "primary.main" }} />
        <Typography>{store?.email}</Typography>
      </Box>
    </Box>
  );

  const renderWorkingScale = (
    <Box sx={sharedStyles.verticalContent}>
      <Typography variant="h5" fontWeight="bold" textAlign="center">
        Horários de funcionamento
      </Typography>

      <TimeRangeInterpreter
        timeMonday={store?.timeMonday}
        timeTuesday={store?.timeTuesday}
        timeWednesday={store?.timeWednesday}
        timeThursday={store?.timeThursday}
        timeFriday={store?.timeFriday}
        timeSaturday={store?.timeSaturday}
        timeSunday={store?.timeSunday}
      />
    </Box>
  );

  const renderOurServices = (
    <Box sx={sharedStyles.verticalContent}>
      <Typography variant="h5" fontWeight="bold" textAlign="center">
        Nossas excursões
      </Typography>

      {excursions && excursions.length > 0 ? (
        <Box sx={sharedStyles.horizontalScrollSection}>
          {excursions.map((excursion, index) => (
            <Box
              key={index}
              onClick={() => handleSelectExcursionClick(excursion.id)}
            >
              <CustomCard
                photoURL={excursion.imagePreview[0]}
                defaultPhoto={defaultExcursionImage}
                header={`R$ ${priceFormatter(excursion.price)}`}
                primaryText={excursion.title}
                secondaryText={`${excursion.city} - ${excursion.country}`}
              />
            </Box>
          ))}
        </Box>
      ) : (
        <Typography color="text.secondary">
          Este estabelecimento não possui nenhuma excursão.
        </Typography>
      )}
    </Box>
  );

  return (
    <Box
      sx={{
        overflow: "hidden",
      }}
    >
      <PeregrinClientNavbar />

      <Box sx={backButtonContainer}>
        <BackPageButton />
      </Box>

      {store && (
        <Box
          sx={{
            ...sharedStyles.form,
            marginX: { xs: 3, md: 3, lg: 10 },
            marginBottom: 3,
          }}
        >
          {renderStoreData}

          {renderStoreLocation}

          <Divider />

          {renderWorkingScale}

          <Divider />

          {renderOurServices}

          <Divider />

          {renderStoreContacts}
        </Box>
      )}
    </Box>
  );
};

export default TurismStorePage;
