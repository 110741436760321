import { object, string, date, ref, number } from "yup";

export const loginSchema = object({
  email: string()
    .required("Campo obrigatório.")
    .email("Email digitado não é um endereço válido."),
  password: string().required("Campo obrigatório."),
});

export const firstStepPasswordRecoverySchema = object({
  email: string()
    .required("Campo obrigatório.")
    .email("Email digitado não é um endereço válido."),
});

export const secondStepPasswordRecoverySchema = object({
  password: string()
    .required("Campo obrigatório.")
    .min(8, "A senha deve conter pelo menos 8 caracteres."),
  confirmedPassword: string()
    .required("Campo obrigatório.")
    .oneOf([ref("password"), null], "As senhas devem ser iguais."),
});

export const addServiceSchema = object({
  name: string().required("Campo obrigatório."),
});

export const registerSchema = object({
  name: string().required("Campo obrigatório."),
  yearBirth: date().typeError("O campo deve ser uma data válida."),
  email: string()
    .required("Campo obrigatório.")
    .email("Email digitado não é um endereço válido."),
  password: string()
    .required("Campo obrigatório.")
    .min(8, "A senha deve conter pelo menos 8 caracteres."),
  confirmedPassword: string()
    .required("Campo obrigatório.")
    .oneOf([ref("password"), null], "As senhas devem ser iguais."),
});

export const accountDetailsSchema = object({
  name: string().required("Campo obrigatório."),
});

export const createAppointmentModalSchema = object({
  clientName: string().required("Campo obrigatório."),
});
