import { number, object, string, ref } from "yup";

export const addAndEditExcursionSchema = object({
  title: string().required("Campo obrigatório."),
  agent: string().required("Campo obrigatório."),
  mainCategory: string().required("Campo obrigatório."),
  minVacancies: number()
    .typeError("Campo obrigatório.")
    .min(1, "A excursão deve ter pelo menos 1 vaga."),
  maxVacancies: number()
    .typeError("Campo obrigatório.")
    .min(
      ref("minVacancies"),
      "O número máximo deve ser maior ou igual ao mínimo."
    ),
  transportService: string().required("Campo obrigatório."),
  accommodationService: string().required("Campo obrigatório."),
  city: string().required("Campo obrigatório."),
  state: string().required("Campo obrigatório."),
  region: string().required("Campo obrigatório."),
  country: string().required("Campo obrigatório."),
});

export const isPriceInvalid = (submittedOnce: boolean, price: number) => {
  return submittedOnce && (price < 0 || !price);
};
