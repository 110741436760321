import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  IconButton,
  ListItemText,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import ClientNavbar from "../../../components/general/Navbars/ClientNavbar";
import useFetchCart from "../../../hooks/entities/useFetchCart";
import ComercialTable from "../../../components/comercial/ComercialTable";
import { sharedStyles } from "../../../styles/shared";
import { formatDateAndTimeDayDateToString } from "../../petAndBeauty/Client/ScheduilingPage/timeUtils";
import { priceFormatter } from "../../../utils/priceFormatter";
import ClearIcon from "@mui/icons-material/Clear";
import { handleClearCartClick, handleRemoveCartItem } from "./functions";
import { PrimaryButton } from "../../../components/general/buttons/PrimaryButton";
import emptyCartImage from "../../../assets/img/empty-cart.png";
import BackPageButton, {
  backButtonContainer,
} from "@/components/general/buttons/BackPageButton";

const ShoppingCart = () => {
  const { shoppingCart, isCartLoading, fetchUserShoppingCart } = useFetchCart();

  const cartTableHeader = (
    <TableRow>
      <TableCell align="left">Serviço</TableCell>
      <TableCell align="center">Preço</TableCell>
      <TableCell align="right"></TableCell>
    </TableRow>
  );

  const cartTableBody = (
    <>
      {shoppingCart?.items.map((item) => (
        <TableRow
          key={item.id}
          sx={{
            "&:last-child td, &:last-child th": { border: 0 },
          }}
        >
          <TableCell
            component="th"
            sx={{ ...sharedStyles.horizontalContent }}
            align="left"
          >
            <Avatar
              src={item.imagePreview}
              sx={sharedStyles.tableAvatarStyle}
            />

            <ListItemText
              primary={item.name}
              secondary={`${formatDateAndTimeDayDateToString(item.date)} com ${
                item.employeeName
              }`}
              primaryTypographyProps={{ fontWeight: 500 }}
            />
          </TableCell>

          <TableCell align="center">
            <ListItemText
              primary={`R$ ${priceFormatter(item.price)}`}
              secondary={""}
              primaryTypographyProps={{ fontWeight: 500 }}
            />
          </TableCell>

          <TableCell align="right">
            <IconButton
              onClick={() => {
                handleRemoveCartItem(item.id).then(() =>
                  fetchUserShoppingCart()
                );
              }}
            >
              <ClearIcon />
            </IconButton>
          </TableCell>
        </TableRow>
      ))}
    </>
  );

  return (
    <Box sx={{ overflowX: "hidden" }}>
      <ClientNavbar />

      <Box sx={backButtonContainer}>
        <BackPageButton />
      </Box>

      <Box sx={{ ...sharedStyles.clientMainContainer }}>
        {shoppingCart?.items.length > 0 ? (
          <>
            {!isCartLoading ? (
              <>
                <Box
                  sx={{
                    ...sharedStyles.horizontalContent,
                    justifyContent: "space-between",
                    width: "100%",
                  }}
                >
                  <Typography variant="h5" fontWeight="bold">
                    Seu carrinho
                  </Typography>
                  <Button
                    sx={{ textTransform: "none" }}
                    onClick={() =>
                      handleClearCartClick(shoppingCart.id).then(() =>
                        fetchUserShoppingCart()
                      )
                    }
                  >
                    Limpar carrinho
                  </Button>
                </Box>
                <ComercialTable header={cartTableHeader} body={cartTableBody} />

                <Box
                  sx={{
                    ...sharedStyles.horizontalContent,
                    justifyContent: "space-between",
                    width: "100%",
                  }}
                >
                  <Box />

                  <Box sx={sharedStyles.verticalContent}>
                    <Box
                      sx={{
                        ...sharedStyles.horizontalContent,
                        justifyContent: "space-between",
                        width: "100%",
                      }}
                    >
                      <Typography>Total:</Typography>

                      <Typography
                        variant="h5"
                        fontWeight="bold"
                      >{`R$ ${priceFormatter(
                        shoppingCart?.total
                      )}`}</Typography>
                    </Box>
                    <PrimaryButton label="Realizar pagamento" />
                  </Box>
                </Box>
              </>
            ) : (
              <CircularProgress />
            )}
          </>
        ) : (
          <Box sx={{ ...sharedStyles.form, alignItems: "center", mt: "120px" }}>
            <img
              src={emptyCartImage}
              alt="Carrinho vazio"
              style={{ width: "200px", height: "200px" }}
            />
            <Typography variant="h6" color="text.secondary" textAlign="center">
              Não há serviços no seu carrinho!
            </Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default ShoppingCart;
