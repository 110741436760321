import { ThemeProvider } from "@mui/material/styles";
import { AuthProvider } from "./context/AuthProvider";
import AppRoutes from "./routes";
import { beautyTheme, petTheme, turismTheme } from "./styles/themes";
import { CURRENT_SUBDOMAIN, DEMANDORIA_SUBDOMAINS } from "./utils/stringUtils";
import { CssBaseline } from "@mui/material";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

const queryClient = new QueryClient();

const App = () => {
  // <ThemeProvider
  //   theme={
  //     CURRENT_SUBDOMAIN === DEMANDORIA_SUBDOMAINS[0]
  //       ? beautyTheme
  //       : CURRENT_SUBDOMAIN === DEMANDORIA_SUBDOMAINS[1]
  //         ? petTheme
  //         : turismTheme
  //   }
  // >
  {
    /* geralmente o erro de styled_function (Popper.js) 
      é derivado da mudança de localização do ThemeProvider,
      para correção, realizar a reimportação. */
  }

  return (
    <ThemeProvider theme={turismTheme}>
      <CssBaseline />
      <AuthProvider>
        <QueryClientProvider client={queryClient}>
          <AppRoutes />
        </QueryClientProvider>
      </AuthProvider>
    </ThemeProvider>
  );
};

export default App;
