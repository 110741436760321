export enum DocType {
  CPF = "CPF",
  CNPJ = "CNPJ",
  PASSPORT = "PASSPORT",
}

export enum UserStatus {
  OK = "OK",
  PENDENT = "PENDENT",
}

export enum UserRole {
  GUEST = "GUEST",
  CLIENT = "CLIENT",
  COMERCIAL = "COMERCIAL",
  MODERATOR = "MODERATOR",
  ADMIN = "ADMIN",
}

export enum PrefNotify {
  ENABLED = "ENABLED",
  DISABLED = "DISABLED",
}

export enum AppointmentStatus {
  PENDENT = "PENDENT",
  CONFIRMED = "CONFIRMED",
  CANCELED = "CANCELED",
  FINISHED = "FINISHED",
}

export type ImageFormat = {
  ORIGINAL;
  PREVIEW;
};

export const ImageOriginalResolutions = [800, 600];
export const ImagePreviewResolutions = [320, 200];

export enum OneClickPlatform {
  GOOGLE = "GOOGLE",
  APPLE = "APPLE",
}

export enum PaymentMethod {
  PIX,
  CREDIT_CARD,
  DEBIT_CARD,
  BANK_SLIP,
  MONEY,
  CRIPTO,
}

export const FoodService = [
  "Não incluso",
  "All inclusive",
  "Café da manhã",
  "Meia pensão",
  "Pensão completa",
];

export enum ExcursionStatus {
  ACTIVE = "ACTIVE",
  CANCELED = "CANCELED",
  FINISHED = "FINISHED",
  SOLD_OUT = "SOLD_OUT",
}

export enum ExcursionServiceType {
  ACCOMMODATION = "Hospedagem",
  FOOD = "Alimentação",
  TRANSPORT = "Transporte",
}

export enum PetAndBeautySearchType {
  PROMOTIONAL = 0,
  STORES = 1,
  SERVICES = 2,
  EMPLOYEES = 3,
}

export enum TurismSearchType {
  Excursions = 0,
  STORES = 1,
}

export interface BrazilianAddress {
  cep?: string;
  state: string;
  city: string;
  neighborhood: string;
  street: string;
  service?: string;
}

export type StoreData = {
  id: string;
  name: string;
  managerId?: string;
};
