import { Box, Button, Collapse } from "@mui/material";
import { ReactNode, useState } from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { sharedStyles } from "@/styles/shared";

interface CollapseWrapperProps {
  label: string;
  content: ReactNode;
}

const CollapseWrapper = (props: CollapseWrapperProps) => {
  const { label, content } = props;

  const [open, setOpen] = useState(true);

  return (
    <Box sx={sharedStyles.verticalContent}>
      <Button
        onClick={() => setOpen(!open)}
        fullWidth
        sx={{ ...sharedStyles.horizontalContent, textTransform: "none" }}
      >
        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
        {label}
      </Button>

      <Collapse in={open} timeout="auto" unmountOnExit>
        {content}
      </Collapse>
    </Box>
  );
};

export default CollapseWrapper;
