import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import {
  Box,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs, { Dayjs } from "dayjs";
import { useState, useRef, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import ComercialDrawer from "../../../../../components/turism/ComercialDrawer";
import ComercialInterfaceHeader from "../../../../../components/comercial/ComercialInterfaceHeader";
import ImageGalleryHeader from "../../../../../components/comercial/ImageGallery/ImageGalleryHeader";
import { PrimaryButton } from "../../../../../components/general/buttons/PrimaryButton";
import { SecondaryButton } from "../../../../../components/general/buttons/SecondaryButton";
import { ExcursionStatus, FoodService } from "../../../../../types/types";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import EditIcon from "@mui/icons-material/Edit";
import IExcursion from "../../../../../services/api/Excursion/IExcursion";
import excursionApi from "../../../../../services/api/Excursion";
import printResponseError from "../../../../../utils/printResponseError";
import {
  addAndEditExcursionSchema,
  isPriceInvalid,
} from "../excursionValidationSchemas";
import { yupResolver } from "@hookform/resolvers/yup";
import PriceInput from "../../../../../components/general/PriceInput";
import { getExcursionsFirebasePath } from "../../../../../utils/stringUtils";
import { IStore } from "../../../../../services/api/Store/types";
import {
  deleteImageFromFirebase,
  uploadOriginalAndPreviewImagesToFirebase,
} from "../../../../../utils/firebase/functions";
import { sharedStyles } from "../../../../../styles/shared";
import GalleryImageList from "../../../../../components/comercial/ImageGallery";
import { handleDeleteExistingOriginalAndPreviewImageClick } from "../../../../petAndBeauty/Comercial/ServicesPages/sharedFunctions";
import { getLocalStorageItem } from "../../../../../hooks/localStorageHooks";
import ImageGalleryContainer from "../../../../../components/comercial/ImageGallery/ImageGalleryContainer";
import { LeftMarginBackButton } from "@/components/general/buttons/BackPageButton";
import Tiptap from "@/components/general/Tiptap";
import DOMPurify from "dompurify";
import EmptyGallery from "@/components/comercial/ImageGallery/EmptyGallery";
import ExcursionRouter from "@/components/turism/ExcursionRouter";

const EditExcursion = () => {
  const navigate = useNavigate();

  const selectedExcursion: IExcursion =
    getLocalStorageItem("selectedExcursion");

  const storeData: IStore = getLocalStorageItem("storeData");

  const {
    register,
    handleSubmit: onSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(addAndEditExcursionSchema) });

  const [submitLoading, setSubmitLoading] = useState<boolean>(false);

  const [newImages, setNewImages] = useState<HTMLCanvasElement[]>([]);

  const [originalImageUrls, setOriginalImageUrls] = useState<string[]>(
    selectedExcursion.imageOriginal
  );
  const [previewImageUrls, setPreviewImageUrls] = useState<string[]>(
    selectedExcursion.imagePreview
  );

  const titleRef = useRef<HTMLInputElement>(null);
  const descriptionRef = useRef("");
  const agentRef = useRef<HTMLInputElement>(null);
  const [price, setPrice] = useState(selectedExcursion.price / 100);
  const categoryRef = useRef<HTMLInputElement>(null);
  const restrictionRef = useRef<HTMLInputElement>(null);

  const [startDate, setStartDate] = useState<Dayjs | null>(
    dayjs(selectedExcursion.startDate)
  );
  const [endDate, setEndDate] = useState<Dayjs | null>(
    dayjs(selectedExcursion.endDate)
  );

  const maxVacanciesRef = useRef<HTMLInputElement>(null);
  const minVacanciesRef = useRef<HTMLInputElement>(null);
  const transportServiceRef = useRef<HTMLInputElement>(null);
  const accommodationServiceRef = useRef<HTMLInputElement>(null);
  const foodServiceRef = useRef<HTMLSelectElement>(null);
  const groupLinkRef = useRef<HTMLInputElement>(null);
  const excursionContractRef = useRef<HTMLInputElement>(null);

  const cityRef = useRef<HTMLInputElement>(null);
  const stateRef = useRef<HTMLInputElement>(null);
  const regionRef = useRef<HTMLInputElement>(null);
  const countryRef = useRef<HTMLInputElement>(null);
  const [router, setRouter] = useState(selectedExcursion.router);

  const [submittedOnce, setSubmittedOnce] = useState(false);

  const [startDateIsBeforeEndDateError, setStartDateIsBeforeEndDateError] =
    useState(false);

  const handleExcursionUpdate = async () => {
    !submittedOnce && setSubmittedOnce(true);

    if (price > 0 && price) {
      setSubmitLoading(true);

      try {
        for (let i = 0; i < selectedExcursion.imageOriginal.length; i++)
          !originalImageUrls.includes(selectedExcursion.imageOriginal[i]) &&
            (await Promise.all([
              deleteImageFromFirebase(selectedExcursion.imageOriginal[i]),
              deleteImageFromFirebase(selectedExcursion.imagePreview[i]),
            ]));

        let finalOriginalImages = originalImageUrls;
        let finalPreviewImages = previewImageUrls;

        if (newImages.length > 0) {
          const response = await uploadOriginalAndPreviewImagesToFirebase(
            newImages,
            getExcursionsFirebasePath(
              storeData.name,
              storeData.id,
              selectedExcursion.title,
              selectedExcursion.id
            )
          );

          finalOriginalImages.push(...response.originalImageUrls);
          finalPreviewImages.push(...response.previewImageUrls);
        }

        const updateExcursion: IExcursion = {
          title: titleRef.current.value,
          description: DOMPurify.sanitize(descriptionRef.current),
          restriction: restrictionRef.current.value,
          transportService: transportServiceRef.current.value,
          foodService: foodServiceRef.current.value,
          price: price * 100,
          agent: agentRef.current.value,
          startDate: startDate.toISOString(),
          endDate: endDate.toISOString(),
          mainCategory: categoryRef.current.value,
          router: router,
          country: countryRef.current.value,
          region: regionRef.current.value,
          state: stateRef.current.value,
          city: cityRef.current.value,
          maxVacancies: Number(maxVacanciesRef.current.value),
          minVacancies: Number(minVacanciesRef.current.value),
          groupLink: groupLinkRef.current.value,
          excursionContract: excursionContractRef.current.value,
          excursionStatus: ExcursionStatus.ACTIVE,
          listTags: [],
          imageOriginal: finalOriginalImages,
          imagePreview: finalPreviewImages,
        };

        await excursionApi.updateExcursion(
          selectedExcursion.id,
          updateExcursion
        );

        window.alert(`${updateExcursion.title} editado com sucesso!`);

        navigate("/excursions");
      } catch (error) {
        setSubmitLoading(false);
        printResponseError("Error updating excursion: ", error);
      }
      setSubmitLoading(false);
    }
  };

  useEffect(() => {
    setStartDateIsBeforeEndDateError(endDate.isBefore(startDate));
  }, [startDate, endDate]);

  const renderExcursionData = (
    <>
      <ComercialInterfaceHeader title="Dados gerais da excursão" />
      <Box sx={sharedStyles.dividedFormContainer}>
        <Box sx={{ ...sharedStyles.verticalContent, width: "100%" }}>
          <TextField
            label="Título *"
            size="small"
            fullWidth
            inputRef={titleRef}
            defaultValue={selectedExcursion.title}
            {...register("title")}
            error={!!errors.title}
            helperText={errors.title?.message}
          />

          <Box sx={sharedStyles.dividedFormContainer}>
            <TextField
              label="Categoria *"
              size="small"
              defaultValue={selectedExcursion.mainCategory}
              fullWidth
              inputRef={categoryRef}
              {...register("mainCategory")}
              error={!!errors.mainCategory}
              helperText={errors.mainCategory?.message}
              autoComplete="off"
            />

            <TextField
              label="Agente *"
              size="small"
              defaultValue={selectedExcursion.agent}
              fullWidth
              inputRef={agentRef}
              {...register("agent")}
              error={!!errors.agent}
              helperText={errors.agent?.message}
              autoComplete="off"
            />
          </Box>
        </Box>
        <Box sx={{ ...sharedStyles.verticalContent, width: "100%" }}>
          <PriceInput
            label="Preço (R$) *"
            size="small"
            placeholder="Ex.: R$ 500,00"
            value={price.toFixed(2)}
            onChange={(e) => setPrice(Number(e.target.value))}
            error={isPriceInvalid(submittedOnce, price)}
            autoComplete="off"
            helperText={
              isPriceInvalid(submittedOnce, price) &&
              "O preço deve ser maior que R$ 0,00"
            }
            inputRef={(input) =>
              input && isPriceInvalid(submittedOnce, price) && input.focus()
            }
          />
          <TextField
            label="Restrição"
            size="small"
            fullWidth
            inputRef={restrictionRef}
            defaultValue={selectedExcursion.restriction}
          />
        </Box>
      </Box>
    </>
  );

  const renderExcursionDetails = (
    <>
      <ComercialInterfaceHeader title="Detalhes da excursão" />

      <Box sx={sharedStyles.verticalContent}>
        <Box sx={{ marginBottom: 2 }}>
          <Tiptap
            defaultValue={selectedExcursion.description}
            onChange={(e) => (descriptionRef.current = e)}
            placeholder="Descreva os detalhes da excursão..."
          />
        </Box>

        <Box sx={sharedStyles.dividedFormContainer}>
          <Box sx={{ ...sharedStyles.verticalContent, width: "100%" }}>
            <LocalizationProvider
              dateAdapter={AdapterDayjs}
              adapterLocale="pt-br"
            >
              <DatePicker
                label="Data de início *"
                format="DD/MM/YYYY"
                disablePast
                dayOfWeekFormatter={(e: any) => e}
                slotProps={{
                  textField: {
                    size: "small",
                    fullWidth: true,
                  },
                }}
                value={startDate}
                onChange={(newStartDate) => setStartDate(newStartDate)}
              />
            </LocalizationProvider>

            <LocalizationProvider
              dateAdapter={AdapterDayjs}
              adapterLocale="pt-br"
            >
              <DatePicker
                label="Data de término *"
                format="DD/MM/YYYY"
                disablePast
                dayOfWeekFormatter={(e: any) => e}
                slotProps={{
                  textField: {
                    size: "small",
                    fullWidth: true,
                    helperText:
                      startDateIsBeforeEndDateError &&
                      "A data de término deve ser posterior a de início.",
                  },
                }}
                value={endDate}
                onChange={(newEndDate) => setEndDate(newEndDate)}
                minDate={startDate}
              />
            </LocalizationProvider>

            <Box sx={sharedStyles.dividedFormContainer}>
              <TextField
                label="Mín. de vagas *"
                size="small"
                type="number"
                fullWidth
                inputRef={minVacanciesRef}
                defaultValue={selectedExcursion.minVacancies}
                {...register("minVacancies")}
                error={!!errors.minVacancies}
                helperText={errors.minVacancies?.message}
                inputProps={{ min: 1 }}
              />
              <TextField
                label="Máx. de vagas *"
                size="small"
                type="number"
                fullWidth
                inputRef={maxVacanciesRef}
                defaultValue={selectedExcursion.maxVacancies}
                {...register("maxVacancies")}
                error={!!errors.maxVacancies}
                helperText={errors.maxVacancies?.message}
                inputProps={{ min: minVacanciesRef?.current?.value }}
              />
            </Box>
          </Box>

          <Box sx={{ ...sharedStyles.verticalContent, width: "100%" }}>
            <TextField
              label="Serviço de transporte *"
              size="small"
              fullWidth
              inputRef={transportServiceRef}
              defaultValue={selectedExcursion.transportService}
              {...register("transportService")}
              error={!!errors.transportService}
              helperText={errors.transportService?.message}
            />

            <TextField
              label="Serviço de hospedagem *"
              size="small"
              fullWidth
              inputRef={accommodationServiceRef}
              defaultValue={selectedExcursion.accommodationService}
              {...register("accommodationService")}
              error={!!errors.accommodationService}
              helperText={errors.accommodationService?.message}
            />
            <FormControl fullWidth size="small">
              <InputLabel>Serviço de alimentação *</InputLabel>
              <Select
                label="Serviço de alimentação"
                defaultValue={selectedExcursion.foodService}
                inputRef={foodServiceRef}
              >
                {FoodService.map((serviceType, index) => (
                  <MenuItem value={FoodService[index]} key={index}>
                    {serviceType}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        </Box>

        <TextField
          label="Link do grupo"
          size="small"
          fullWidth
          inputRef={groupLinkRef}
          defaultValue={selectedExcursion.groupLink}
        />
        <TextField
          label="Contrato da excursão"
          size="small"
          fullWidth
          inputRef={excursionContractRef}
          defaultValue={selectedExcursion.excursionContract}
        />
      </Box>
      <ExcursionRouter router={router} setRouter={setRouter} />
    </>
  );

  const renderExcursionGeographicData = (
    <>
      <ComercialInterfaceHeader title="Dados geográficos" />
      <Box sx={sharedStyles.verticalContent}>
        <Box sx={sharedStyles.horizontalContent}>
          <TextField
            label="Cidade *"
            size="small"
            fullWidth
            inputRef={cityRef}
            defaultValue={selectedExcursion.city}
            {...register("city")}
            error={!!errors.city}
            helperText={errors.city?.message}
          />
          <TextField
            label="Estado *"
            size="small"
            fullWidth
            inputRef={stateRef}
            defaultValue={selectedExcursion.state}
            {...register("state")}
            error={!!errors.state}
            helperText={errors.state?.message}
          />
        </Box>

        <Box sx={sharedStyles.horizontalContent}>
          <TextField
            label="Região *"
            size="small"
            fullWidth
            inputRef={regionRef}
            defaultValue={selectedExcursion.region}
            {...register("region")}
            error={!!errors.region}
            helperText={errors.region?.message}
          />
          <TextField
            label="País *"
            size="small"
            fullWidth
            inputRef={countryRef}
            defaultValue={selectedExcursion.country}
            {...register("country")}
            error={!!errors.country}
            helperText={errors.country?.message}
          />
        </Box>
      </Box>
    </>
  );

  const renderExcursionImageGallery = (
    <Box sx={{ ...sharedStyles.verticalContent, gap: 4 }}>
      <ImageGalleryHeader
        icon={<AddPhotoAlternateIcon />}
        setImages={setNewImages}
      />

      {originalImageUrls.length === 0 && newImages.length === 0 ? (
        <EmptyGallery />
      ) : (
        <ImageGalleryContainer>
          <GalleryImageList
            images={originalImageUrls}
            setImages={setOriginalImageUrls}
            removeImageFunction={(index) =>
              handleDeleteExistingOriginalAndPreviewImageClick(
                index,
                setOriginalImageUrls,
                setPreviewImageUrls
              )
            }
            interable
          />
          <GalleryImageList
            images={newImages}
            setImages={setNewImages}
            interable
          />
        </ImageGalleryContainer>
      )}
    </Box>
  );

  const renderEditExcursionPageContent = (
    <>
      <LeftMarginBackButton />

      <form
        onSubmit={onSubmit(handleExcursionUpdate)}
        style={sharedStyles.form}
      >
        {renderExcursionData}

        {renderExcursionDetails}

        {renderExcursionGeographicData}

        {renderExcursionImageGallery}

        <Box sx={sharedStyles.dividedFormContainer}>
          <PrimaryButton
            label="Confirmar edição"
            icon={<EditIcon />}
            isLoading={submitLoading}
            onClickAction={() => !submittedOnce && setSubmittedOnce(true)}
            styles={sharedStyles.secondaryColor}
          />

          <SecondaryButton
            label="Cancelar"
            onClickAction={() => navigate("/excursions")}
            isDisabled={submitLoading}
          />
        </Box>
      </form>
    </>
  );

  return <ComercialDrawer content={renderEditExcursionPageContent} />;
};

export default EditExcursion;
