import { RefObject, useEffect, useState } from "react";
import { BrazilianAddress } from "../types/types";
import {
  cleanAddressRefs,
  fetchAddressByCEP,
  formatCEP,
} from "../utils/cepUtils";

const useCep = (
  cep: string,
  streetRef: RefObject<HTMLInputElement>,
  districtRef: RefObject<HTMLInputElement>,
  cityRef: RefObject<HTMLInputElement>,
  stateRef: RefObject<HTMLInputElement>,
  canFetch?: boolean
) => {
  const [foundedCepAddress, setFoundedCepAddress] =
    useState<BrazilianAddress | null>(null);

  const formattedCep = formatCEP(cep);

  useEffect(() => {
    if (canFetch) {
      if (formattedCep.length === 8)
        fetchAddressByCEP(formattedCep).then((foundedAddress) => {
          setFoundedCepAddress(foundedAddress);
          streetRef.current.value = foundedAddress.street;
          districtRef.current.value = foundedAddress.neighborhood;
          cityRef.current.value = foundedAddress.city;
          stateRef.current.value = foundedAddress.state;
        });

      if (foundedCepAddress !== null) {
        setFoundedCepAddress(null);
        cleanAddressRefs(streetRef, districtRef, cityRef, stateRef);
      }
    }
  }, [cep]);

  return foundedCepAddress;
};

export default useCep;
