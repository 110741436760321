import {
  Box,
  Button,
  CircularProgress,
  TextField,
  Typography,
} from "@mui/material";
import InputMask from "react-input-mask";
import { useNavigate } from "react-router-dom";
import { lazy, useState } from "react";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import defaultEmployeeImage from "../../../../../assets/svg/defaultUser.svg";
import ComercialDrawer from "../../../../../components/pet-beauty/CormercialDrawer";
import ComercialInterfaceHeader from "../../../../../components/comercial/ComercialInterfaceHeader";
import { IStore } from "../../../../../services/api/Store/types";
import { PrimaryButton } from "../../../../../components/general/buttons/PrimaryButton";
import { SecondaryButton } from "../../../../../components/general/buttons/SecondaryButton";
import { getLocalStorageItem } from "../../../../../hooks/localStorageHooks";
import WorkingScaleSelector from "../../../../../components/comercial/WorkingScaleSelector";
import { DocType } from "../../../../../types/types";
import useSearchUserByDocNumber from "../../../../../hooks/search/useSearchUserByDocNumber";
import employeeApi from "../../../../../services/api/Employee";
import { IEmployee } from "../../../../../services/api/Employee/types";
import { sharedStyles } from "../../../../../styles/shared";
import BackPageButton, {
  LeftMarginBackButton,
} from "../../../../../components/general/buttons/BackPageButton";
const CustomCard = lazy(
  () => import("../../../../../components/general/CustomCard")
);

const AddEmployee = () => {
  const navigate = useNavigate();

  const selectedStore: IStore = getLocalStorageItem("selectedStore");

  const {
    foundedUser,
    docNumber,
    setDocNumber,
    searchingUser,
    setSearchingUser,
  } = useSearchUserByDocNumber(DocType.CPF);

  const [timeMonday, setTimeMonday] = useState<string[]>(
    selectedStore?.timeMonday
  );
  const [timeTuesday, setTimeTuesday] = useState<string[]>(
    selectedStore?.timeTuesday
  );
  const [timeWednesday, setTimeWednesday] = useState<string[]>(
    selectedStore?.timeWednesday
  );
  const [timeThursday, setTimeThursday] = useState<string[]>(
    selectedStore?.timeThursday
  );
  const [timeFriday, setTimeFriday] = useState<string[]>(
    selectedStore?.timeFriday
  );
  const [timeSaturday, setTimeSaturday] = useState<string[]>(
    selectedStore?.timeSaturday
  );
  const [timeSunday, setTimeSunday] = useState<string[]>(
    selectedStore?.timeSunday
  );

  const handleUserAndEmployeeCreation = async () => {
    setSearchingUser(true);

    try {
      await employeeApi.createEmployee({
        userId: foundedUser.id,
        storeId: selectedStore.id,
        timeMonday,
        timeTuesday,
        timeWednesday,
        timeThursday,
        timeFriday,
        timeSaturday,
        timeSunday,
      } as IEmployee);
      navigate("/employees");
    } catch (error) {
      alert("Não foi possível adicionar o funcionário.");
      console.warn("Error creating a employee: ", error);
    }

    setSearchingUser(false);
  };

  const CpfField = (
    <InputMask
      mask="999.999.999-99"
      disabled={false}
      maskChar="_"
      value={docNumber}
      onChange={(e) => setDocNumber(e.target.value)}
    >
      <TextField
        label="CPF*"
        fullWidth
        size="small"
        sx={{ maxWidth: "400px" }}
      />
    </InputMask>
  );

  const renderEmployeeData = (
    <>
      <ComercialInterfaceHeader title="Busque funcionário pelo CPF" />

      <Box sx={{ ...sharedStyles.verticalContent, alignItems: "center" }}>
        <Box
          sx={{
            ...sharedStyles.verticalContent,
            gap: "10px",
            width: "100%",
            alignItems: "center",
          }}
        >
          {CpfField}
          {!foundedUser && (
            <Button
              variant="text"
              onClick={() => navigate("/register")}
              sx={{ textTransform: "none" }}
            >
              Funcionário não encontrado? Cadastre agora!
            </Button>
          )}
        </Box>

        <Box
          sx={{
            ...sharedStyles.verticalContent,
            marginTop: "10px",
            gap: "10px",
            width: "100%",
            alignItems: "center",
          }}
        >
          {!searchingUser ? (
            <>
              {foundedUser && (
                <>
                  <Typography fontWeight="bold">
                    Funcionário encontrado:
                  </Typography>
                  <CustomCard
                    photoURL={foundedUser.imageOriginal}
                    defaultPhoto={defaultEmployeeImage}
                    header={foundedUser.name}
                    primaryText={foundedUser.docNumber}
                    secondaryText={""}
                    isSelected
                  />
                </>
              )}
            </>
          ) : (
            <>
              <CircularProgress />
              <Typography color="text.secondary">
                Buscando profissional...
              </Typography>
            </>
          )}
        </Box>
      </Box>
    </>
  );

  const renderWorkScaleSelector = (
    <>
      <ComercialInterfaceHeader title="Defina a escala de trabalho" />
      <Box sx={sharedStyles.verticalContent}>
        <WorkingScaleSelector
          timeMonday={timeMonday}
          setTimeMonday={setTimeMonday}
          timeTuesday={timeTuesday}
          setTimeTuesday={setTimeTuesday}
          timeWednesday={timeWednesday}
          setTimeWednesday={setTimeWednesday}
          timeThursday={timeThursday}
          setTimeThursday={setTimeThursday}
          timeFriday={timeFriday}
          setTimeFriday={setTimeFriday}
          timeSaturday={timeSaturday}
          setTimeSaturday={setTimeSaturday}
          timeSunday={timeSunday}
          setTimeSunday={setTimeSunday}
          possibleMondayTimes={selectedStore?.timeMonday}
          possibleTuesdayTimes={selectedStore?.timeTuesday}
          possibleWednesdayTimes={selectedStore?.timeWednesday}
          possibleThursdayTimes={selectedStore?.timeThursday}
          possibleFridayTimes={selectedStore?.timeFriday}
          possibleSaturdayTimes={selectedStore?.timeSaturday}
          possibleSundayTimes={selectedStore?.timeSunday}
        />
      </Box>
    </>
  );

  const renderOptionButtons = (
    <Box sx={sharedStyles.dividedFormContainer}>
      <PrimaryButton
        label="Confirmar adição"
        onClickAction={handleUserAndEmployeeCreation}
        icon={<PersonAddIcon />}
        isLoading={searchingUser}
        isDisabled={searchingUser}
      />
      <SecondaryButton
        label="Cancelar"
        isDisabled={searchingUser}
        onClickAction={() => navigate("/employees")}
      />
    </Box>
  );
  const renderAddStorePageContent = (
    <>
      <LeftMarginBackButton />

      <form style={sharedStyles.form}>
        {renderEmployeeData}
        {renderWorkScaleSelector}
        {renderOptionButtons}
      </form>
    </>
  );

  return (
    <Box sx={sharedStyles.sharedMainContainer}>
      <ComercialDrawer content={renderAddStorePageContent} />
    </Box>
  );
};

export default AddEmployee;
