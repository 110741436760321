import peregrinBrandLogo from "@/assets/svg/peregrin-brand-logo.svg";
import { CSSProperties } from "react";

interface BrandLogoProps {
  color?: string;
  style?: CSSProperties;
}

const BrandLogo = (props: BrandLogoProps) => {
  const { color, style } = props;

  return (
    <img
      src={peregrinBrandLogo}
      style={{ ...style, filter: color }}
      alt="Peregrin logo"
    />
  );
};

export default BrandLogo;
