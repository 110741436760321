import {
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  Typography,
} from "@mui/material";
import { type Editor } from "@tiptap/react";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import FormatListNumberedIcon from "@mui/icons-material/FormatListNumbered";
import RedoIcon from "@mui/icons-material/Redo";
import UndoIcon from "@mui/icons-material/Undo";
import HorizontalRuleIcon from "@mui/icons-material/HorizontalRule";

interface TiptapToolbarProps {
  editor: Editor | null;
}

const TiptapToolbar = (props: TiptapToolbarProps) => {
  const { editor } = props;

  if (!editor) return null;

  const editorOptions = [
    {
      value: "heading",
      selected: editor.isActive("heading", { level: 2 }),
      disabled: false,
      onClick: () => editor.chain().focus().toggleHeading({ level: 2 }).run(),
      label: (
        <Typography fontWeight="bold" textTransform="none">
          h2
        </Typography>
      ),
      tooltip: "Título",
    },
    {
      value: "bold",
      selected: editor.isActive("bold"),
      disabled: false,
      onClick: () => editor.chain().focus().toggleBold().run(),
      label: <Typography fontWeight="bold">B</Typography>,
      tooltip: "Negrito",
    },
    {
      value: "italic",
      selected: editor.isActive("italic"),
      disabled: false,
      onClick: () => editor.chain().focus().toggleItalic().run(),
      label: (
        <Typography fontStyle="italic" textTransform="none">
          i
        </Typography>
      ),
      tooltip: "Itálico",
    },
    {
      value: "bulletList",
      selected: editor.isActive("bulletList"),
      disabled: false,
      onClick: () => editor.chain().focus().toggleBulletList().run(),
      label: <FormatListBulletedIcon />,
      tooltip: "Lista",
    },
    {
      value: "orderedList",
      selected: editor.isActive("orderedList"),
      disabled: false,
      onClick: () => editor.chain().focus().toggleOrderedList().run(),
      label: <FormatListNumberedIcon />,
      tooltip: "Lista numerada",
    },
    {
      value: "undo",
      selected: null,
      disabled: !editor.can().chain().focus().undo().run(),
      onClick: () => editor.chain().focus().undo().run(),
      label: <UndoIcon />,
      tooltip: "Desfazer",
    },
    {
      value: "redo",
      selected: null,
      disabled: !editor.can().chain().focus().redo().run(),
      onClick: () => editor.chain().focus().redo().run(),
      label: <RedoIcon />,
      tooltip: "Avançar",
    },
    {
      value: "divider",
      selected: null,
      disabled: false,
      onClick: () => editor.chain().focus().setHorizontalRule().run(),
      label: <HorizontalRuleIcon />,
      tooltip: "Divisória",
    },
  ];

  return (
    <ToggleButtonGroup fullWidth>
      {editorOptions.map((option) => (
        <Tooltip title={option.tooltip}>
          <ToggleButton
            value={option.value}
            onClick={option.onClick}
            disabled={option.disabled}
            selected={option.selected}
            sx={{ paddingY: 0.5, paddingX: 0 }}
          >
            {option.label}
          </ToggleButton>
        </Tooltip>
      ))}
    </ToggleButtonGroup>
  );
};

export default TiptapToolbar;
