import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  ListItemText,
  TableCell,
  TableRow,
  Typography,
  useMediaQuery,
} from "@mui/material";
import AddBusinessIcon from "@mui/icons-material/AddBusiness";
import { useNavigate } from "react-router-dom";
import ComercialDrawer from "../../../../../components/turism/ComercialDrawer";
import ComercialInterfaceHeader from "../../../../../components/comercial/ComercialInterfaceHeader";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import Tooltip from "@mui/material/Tooltip";
import defaultStoreImage from "../../../../../assets/svg/defaultStore.svg";
import ComercialTable from "../../../../../components/comercial/ComercialTable";
import { sharedStyles } from "../../../../../styles/shared";
import VisibilityIcon from "@mui/icons-material/Visibility";
import useManagerStores from "../../../../../hooks/context-hooks/useManagerStores";
import useFetchManagerStores from "../../../../../hooks/entities/useFetchManagerStores";
import functions from "./functions";

const Agencies = () => {
  const navigate = useNavigate();

  const isMobile = useMediaQuery("(min-width:700px)");

  const managerStoresContext = useManagerStores();
  const { fetchStores, loadingManagerStores } = useFetchManagerStores();

  const {
    handleSeeStoreDetailsClick,
    handleEditStoreClick,
    handleDeleteManagerStore,
  } = functions();

  const addAgencyButton = isMobile ? (
    <Button
      onClick={() => navigate("/agencies/add")}
      color="primary"
      disableElevation
      startIcon={<AddBusinessIcon />}
      sx={{ textTransform: "none" }}
    >
      Adicionar agência
    </Button>
  ) : (
    <IconButton color="primary" onClick={() => navigate("/agencies/add")}>
      <AddBusinessIcon />
    </IconButton>
  );

  const storesTableHeader = (
    <TableRow>
      <TableCell align="left">Estabelecimento</TableCell>
      <TableCell align="right">Ações</TableCell>
    </TableRow>
  );

  const storesTableBody = (
    <>
      {managerStoresContext.stores?.length !== 0 ? (
        managerStoresContext.stores?.map((store) => (
          <TableRow
            key={store.id}
            sx={{
              "&:last-child td, &:last-child th": { border: 0 },
            }}
          >
            <TableCell
              component="th"
              sx={{ ...sharedStyles.horizontalContent }}
              align="left"
            >
              <Avatar
                src={store.imagePreview[0] ?? defaultStoreImage}
                sx={sharedStyles.tableAvatarStyle}
              />

              <ListItemText
                primary={store.name}
                secondary={store?.CNPJ}
                primaryTypographyProps={{ fontWeight: 500 }}
              />
            </TableCell>

            <TableCell align="right">
              <Tooltip title="Detalhes">
                <IconButton
                  color="primary"
                  onClick={() => handleSeeStoreDetailsClick(store)}
                >
                  <VisibilityIcon />
                </IconButton>
              </Tooltip>

              <Tooltip title="Editar">
                <IconButton
                  color="primary"
                  onClick={() => handleEditStoreClick(store)}
                >
                  <EditIcon />
                </IconButton>
              </Tooltip>

              <Tooltip title="Excluir">
                <IconButton
                  color="primary"
                  onClick={() =>
                    handleDeleteManagerStore(store).then(() => fetchStores())
                  }
                >
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            </TableCell>
          </TableRow>
        ))
      ) : (
        <TableRow>
          <TableCell>
            <Typography color="text.secondary" variant="body2">
              Ainda não há agências cadastrados
            </Typography>
          </TableCell>
        </TableRow>
      )}
    </>
  );

  const renderStoresPageContent = (
    <div>
      <ComercialInterfaceHeader
        title="Suas agências"
        actionNode={addAgencyButton}
      />

      <Box>
        {!loadingManagerStores ? (
          <ComercialTable header={storesTableHeader} body={storesTableBody} />
        ) : (
          <Box sx={sharedStyles.loadingContainer}>
            <CircularProgress />
          </Box>
        )}
      </Box>
    </div>
  );

  return <ComercialDrawer content={renderStoresPageContent} />;
};

export default Agencies;
