import { Box, IconButton } from "@mui/material";
import { useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

const BackPageButton = () => {
  const navigate = useNavigate();

  return (
    <IconButton
      onClick={() => navigate(-1)}
      color="primary"
      sx={{ width: "100%", height: "100%" }}
    >
      <ArrowBackIcon sx={{ width: "100%", height: "100%" }} />
    </IconButton>
  );
};

export const backButtonContainer = {
  width: "40px",
  height: "40px",
};

export const LeftMarginBackButton = () => {
  return (
    <Box sx={{ ...backButtonContainer, marginLeft: "-12px" }}>
      <BackPageButton />
    </Box>
  );
};

export default BackPageButton;
