"use client";
import { generalBrightGrey, generalWhite } from "@/styles/colors";
import { Box } from "@mui/material";
import { useState } from "react";

const INFERIOR_HEIGHT_LIMIT = 25;
const SUPERIOR_HEIGHT_LIMIT = 90;

const BottomSheet = ({ children }: any) => {
  const [currentHeight, setCurrentHeight] = useState(INFERIOR_HEIGHT_LIMIT);

  const handleDrag = (e: any) => {
    let startY = e.clientY;
    let newHeight;

    document.onpointermove = dragMove;

    function dragMove(e: any) {
      const delta = startY - e.clientY;
      newHeight = currentHeight + (delta / window.innerHeight) * 100;

      newHeight = Math.max(
        INFERIOR_HEIGHT_LIMIT,
        Math.min(newHeight, SUPERIOR_HEIGHT_LIMIT)
      );
      setCurrentHeight(newHeight);
    }

    document.onpointerup = dragEnd;

    function dragEnd() {
      document.onpointermove = null;
      document.onpointerup = null;
    }
  };

  return (
    <Box
      sx={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        zIndex: 1,
        pointerEvents: "none",
      }}
    >
      <Box
        sx={{
          position: "absolute",
          maxWidth: 800,
          bottom: 0,
          left: "50%",
          transform: "translate(-50%)",
          width: "100%",
          height: `${currentHeight}%`,
          padding: "15px 0px",
          borderRadius: "10px 10px 0 0",
          boxShadow: "0 10px 15px rgba(0,0,0,0.1)",
          backgroundColor: generalWhite,
          pointerEvents: "all",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            touchAction: "none",
            cursor: "grab",
          }}
          onPointerDown={handleDrag}
          onDoubleClick={() => setCurrentHeight(INFERIOR_HEIGHT_LIMIT)}
        >
          <Box
            sx={{
              userSelect: "none",
              padding: "15px",
              marginTop: "-15px",
            }}
          >
            <Box
              component="span"
              sx={{
                height: "5px",
                width: "50px",
                display: "block",
                backgroundColor: generalBrightGrey,
                borderRadius: "20px",
              }}
            />
          </Box>
        </Box>
        <Box
          sx={{
            height: "100%",
            width: "100%",
            overflowY: "auto",
            boxShadow: "inset 0 2px 4px 0 rgba(0,0,0,0.05)",
          }}
        >
          {children}
        </Box>
      </Box>
    </Box>
  );
};

export default BottomSheet;
