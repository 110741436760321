import { sharedStyles } from "@/styles/shared";
import { Box, Card, CardContent, Typography } from "@mui/material";
import { ReactNode } from "react";

interface ExcursionServiceCardProps {
  icon: ReactNode;
  serviceType: string;
  service: string;
}

const ExcursionServiceCard = (props: ExcursionServiceCardProps) => {
  const { icon, serviceType, service } = props;

  return (
    <Card variant="outlined" sx={{ width: "100%", borderRadius: "10px" }}>
      <CardContent sx={{...sharedStyles.verticalContent, alignItems: "center", textAlign: "center"}}>
        {icon}

        <Box sx={{...sharedStyles.verticalContent, gap: 0}}>
          <Typography fontWeight={"bold"}>{service}</Typography>
          <Typography>{serviceType}</Typography>
        </Box>
      </CardContent>
    </Card>
  );
};

export default ExcursionServiceCard;
