import {
  ImageListItem,
  ImageListItemBar,
  IconButton,
  SxProps,
} from "@mui/material";
import { generalWhite } from "../../../styles/colors";
import ClearIcon from "@mui/icons-material/Clear";
import { Dispatch, SetStateAction } from "react";
import { handleRemoveImageFromArray } from "../../../utils/image/imageFunctions";

interface ImageGalleryProps {
  images: HTMLCanvasElement[] | string[];
  setImages?:
    | Dispatch<SetStateAction<HTMLCanvasElement[]>>
    | Dispatch<SetStateAction<string[]>>;
  removeImageFunction?: (index: number) => void;
  interable?: boolean;
}

const ImageGallery = (props: ImageGalleryProps) => {
  const { images, setImages, removeImageFunction, interable } = props;

  const handleRemoveImageClick = (index: number) =>
    removeImageFunction
      ? removeImageFunction(index)
      : images.length > 0 &&
        setImages(handleRemoveImageFromArray(images, index));

  return (
    <>
      {images.length > 0 &&
        images.map((image, index) => (
          <ImageListItem key={index}>
            <img
              src={
                image instanceof HTMLCanvasElement ? image.toDataURL() : image
              }
              alt={`Image ${index}`}
              loading="lazy"
            />
            {interable && (
              <ImageListItemBar
                sx={styles.imageListItemBar}
                position="top"
                actionIcon={
                  <IconButton
                    sx={{ color: generalWhite }}
                    onClick={() => handleRemoveImageClick(index)}
                  >
                    <ClearIcon />
                  </IconButton>
                }
                actionPosition="left"
              />
            )}
          </ImageListItem>
        ))}
    </>
  );
};

export default ImageGallery;

const styles = {
  emptyGalleryContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: "10px",
    margin: "40px 0px",
    width: "100%",
  } as SxProps,

  imageListItemBar: {
    background:
      "linear-gradient(to bottom, rgba(0,0,0,0.2) 0%, " +
      "rgba(0,0,0,0.1) 70%, rgba(0,0,0,0) 100%)",
  } as SxProps,
};
