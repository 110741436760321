import { SxProps } from "@mui/material";

export const sharedStyles = {
  generalContainer: {
    minHeight: "100vh",
    width: "100%",
  } as SxProps,

  innerContainer: {
    minHeight: "100vh",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    gap: 10,
    padding: 20,
  } as SxProps,

  secondaryColor: {
    color: "secondary.main",
  } as SxProps,

  sharedMainContainer: {
    overflowX: "hidden",
    height: "100vh",
    pb: "40px",
  } as SxProps,

  tableAvatarStyle: { width: "60px", height: "60px" } as SxProps,

  horizontalContent: {
    display: "flex",
    alignItems: "center",
    gap: 2,
  } as SxProps,

  form: {
    display: "flex",
    flexDirection: "column",
    gap: "40px",
  },

  verticalContent: {
    display: "flex",
    flexDirection: "column",
    gap: 2,
  } as SxProps,

  dividedFormContainer: {
    display: "flex",
    gap: "20px",

    "@media(max-width:800px)": { flexDirection: "column" },
  } as SxProps,

  dynamicPriceInputsContainer: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    justifyContent: "space-around",
    gap: "10px",

    "@media(max-width:600px)": { flexDirection: "column" },
  } as SxProps,

  serviceEmployeesGrid: {
    display: "flex",
    overflowX: "auto",
    whiteSpace: "nowrap",
    width: "100%",
    p: "10px 0px",
    gap: "20px",
  } as SxProps,

  appointmentsList: {
    overflowY: "auto",
    boxShadow:
      "inset 0px 15px 10px -10px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -10px rgba(0, 0, 0, 0.05)",
    "@media (max-width: 900px)": { width: "100%" },
    borderRadius: "10px",
  } as SxProps,

  clientMainContainer: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    margin: "10px 0px",
    padding: "0px 30px",
  } as SxProps,

  loadingContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    marginY: 10,
  } as SxProps,

  horizontalScrollSection: {
    display: "flex",
    overflowX: "auto",
    whiteSpace: "nowrap",
    width: "100%",
    p: "10px 0px",
    gap: "20px",
  } as SxProps,
};

export const BACK_BUTTON_CONTAINER_STYLE =
  "max-w-[40px] max-h-[40px] ml-[-12px]";

export const GENERAL_CONTAINER_STYLE = "w-full min-h-screen";

export const INNER_CONTAINER_STYLE =
  "w-full flex flex-col items-center justify-center gap-10 p-10";

export const SINGLE_FORM_STYLE = "flex flex-col gap-[20px]";

export const MULTIPLE_FORM_STYLE = "flex flex-col gap-10";

export const FORM_BUTTONS_CONTAINER_STYLE = "flex flex-col md:flex-row gap-4";

export const STORE_CONTACTS_CONTAINER_STYLE = "flex items-center gap-1";
