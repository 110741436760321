import GenericModal from "@/components/general/GenericModal";
import {
  Box,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  SxProps,
  Typography,
} from "@mui/material";
import { Dispatch, SetStateAction, useRef } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { sharedStyles } from "@/styles/shared";
import { useAuth } from "@/context/AuthProvider/useAuth";
import { UserRole } from "@/types/types";
import { PrimaryButton } from "@/components/general/buttons/PrimaryButton";
import { createGoogleLogin } from "@/services/api/Auth";

interface GoogleLoginComercialOptionModalProps {
  credential: string | null;
  setOpen: Dispatch<SetStateAction<string | null>>;
}

const GoogleLoginComercialOptionModal = (
  props: GoogleLoginComercialOptionModalProps
) => {
  const { credential, setOpen } = props;

  const auth = useAuth();

  const roleRef = useRef<HTMLSelectElement>(null);

  const handleOnClose = () => setOpen(null);

  const handleGoogleLoginCreation = async () => {
    try {
      const accessTokenResponse = await createGoogleLogin(
        credential,
        roleRef.current.value as UserRole
      );

      auth.setGoogleUserContext(accessTokenResponse);
    } catch (error) {
      console.error("Error creating google login: ", error);
    }
  };

  const modalContent = (
    <Box sx={containerStyles}>
      <IconButton
        onClick={handleOnClose}
        sx={{ position: "absolute", right: 0, top: 0 }}
      >
        <CloseIcon />
      </IconButton>

      <Typography variant="h6">Primeiro acesso?</Typography>

      <FormControl fullWidth>
        <InputLabel>Tipo de conta *</InputLabel>

        <Select
          label="Tipo de conta *"
          defaultValue={UserRole.COMERCIAL}
          inputRef={roleRef}
        >
          <MenuItem value={UserRole.CLIENT}>Cliente</MenuItem>
          <MenuItem value={UserRole.COMERCIAL}>Comercial</MenuItem>
        </Select>
      </FormControl>

      <PrimaryButton
        label="Entrar"
        styles={sharedStyles.secondaryColor}
        onClickAction={handleGoogleLoginCreation}
      />
    </Box>
  );

  return (
    <GenericModal
      content={modalContent}
      open={credential}
      onClose={handleOnClose}
    />
  );
};

export default GoogleLoginComercialOptionModal;

const containerStyles: SxProps = { ...sharedStyles.verticalContent, gap: 3 };
