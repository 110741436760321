import ComercialTable from "@/components/comercial/ComercialTable";
import BackPageButton, {
  backButtonContainer,
} from "@/components/general/buttons/BackPageButton";
import { PrimaryButton } from "@/components/general/buttons/PrimaryButton";
import PeregrinClientNavbar from "@/components/general/Navbars/ClientNavbar/PeregrinClientNavbar";
import useFetchCart from "@/hooks/entities/useFetchCart";
import { handleRemoveCartItem } from "@/pages/common/ShoppingCart/functions";
import { sharedStyles } from "@/styles/shared";
import { priceFormatter } from "@/utils/priceFormatter";
import {
  Avatar,
  Box,
  CircularProgress,
  IconButton,
  ListItemText,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import { ClearIcon } from "@mui/x-date-pickers";
import excursionDefaultImage from "@/assets/svg/defaultExcursion.svg";
import excursionNotFound from "@/assets/img/excursionNotFound.png";

const Checkout = () => {
  const { shoppingCart, fetchUserShoppingCart } = useFetchCart();

  const cartTableHeader = (
    <TableRow>
      <TableCell align="left">Excursão</TableCell>
      <TableCell align="center">Qtd.</TableCell>
      <TableCell align="center">Preço (uni.)</TableCell>
      <TableCell align="right"></TableCell>
    </TableRow>
  );

  const cartTableBody = (
    <>
      {shoppingCart?.items.map((item) => (
        <TableRow
          key={item.id}
          sx={{
            "&:last-child td, &:last-child th": { border: 0 },
          }}
        >
          <TableCell
            component="th"
            sx={{ ...sharedStyles.horizontalContent }}
            align="left"
          >
            <Avatar
              src={item.imagePreview ?? excursionDefaultImage}
              sx={sharedStyles.tableAvatarStyle}
            />

            <ListItemText
              primary={item.name}
              secondary={`agente: ${
                item.employeeName
              } (${shoppingCart?.storeName})`}
              primaryTypographyProps={{ fontWeight: 500 }}
            />
          </TableCell>

          <TableCell align="center">
            <Typography>{item.quantity}</Typography>
          </TableCell>

          <TableCell align="center">
            <ListItemText
              primary={`R$ ${priceFormatter(item.price)}`}
              secondary={""}
              primaryTypographyProps={{ fontWeight: 500 }}
            />
          </TableCell>

          <TableCell align="right">
            <IconButton
              onClick={() => {
                handleRemoveCartItem(item.id).then(() =>
                  fetchUserShoppingCart()
                );
              }}
            >
              <ClearIcon />
            </IconButton>
          </TableCell>
        </TableRow>
      ))}
    </>
  );

  return (
    <Box>
      <PeregrinClientNavbar />

      <Box sx={backButtonContainer}>
        <BackPageButton />
      </Box>

      <Box
        sx={{
          ...sharedStyles.form,
          justifyContent: "center",
          alignItems: "center",
          minHeight: "50vh",
          marginX: { xs: 3, md: 3, lg: 10 },
          marginY: 5,
          gap: 1,
        }}
      >
        {shoppingCart !== null ? (
          <>
            {shoppingCart?.items.length > 0 ? (
              <>
                <Box
                  sx={{
                    ...sharedStyles.verticalContent,
                    gap: 0,
                    width: "100%",
                  }}
                >
                  <Box
                    sx={{
                      ...sharedStyles.horizontalContent,
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography variant="h5" fontWeight="bold">
                    🧳 Sua mala
                    </Typography>

                    {/* <Button
                      sx={{ textTransform: "none", alignSelf: "flex-end" }}
                      onClick={() =>
                        handleRemoveAllCartItems(shoppingCart.items).then(() =>
                          fetchUserShoppingCart()
                        )
                      }
                      startIcon={<HighlightOffIcon />}
                    >
                      Limpar itens
                    </Button> */}
                  </Box>

                  <ComercialTable
                    header={cartTableHeader}
                    body={cartTableBody}
                  />
                </Box>

                <Box
                  sx={{
                    ...sharedStyles.verticalContent,
                    gap: 0,
                    alignSelf: "flex-end",
                    textAlign: "right",
                  }}
                >
                  <Typography>
                    Total{" "}
                    <Typography
                      variant="h6"
                      component="span"
                      color="primary"
                    >{`R$ ${priceFormatter(shoppingCart?.total)}`}</Typography>
                  </Typography>

                  <Box maxWidth="400px">
                    <PrimaryButton
                      label="Escolher forma de pagamento"
                      styles={{ ...sharedStyles.secondaryColor, marginTop: 3 }}
                      onClickAction={() =>
                        alert("Funcionalidade em desenvolvimento")
                      }
                    />
                  </Box>
                </Box>
              </>
            ) : (
              <Box
                sx={{
                  ...sharedStyles.verticalContent,
                  gap: 0,
                  marginTop: 8,
                  alignItems: "center",
                }}
              >
                <img
                  src={excursionNotFound}
                  alt="Excursion Not Found"
                  style={{ maxWidth: "250px" }}
                />
                <Typography variant="h6" textAlign="center">
                  Nenhuma excursão adquirida
                </Typography>
              </Box>
            )}
          </>
        ) : (
          <CircularProgress />
        )}
      </Box>
    </Box>
  );
};

export default Checkout;
