import BackPageButton, { backButtonContainer } from "@/components/general/buttons/BackPageButton";
import PeregrinClientNavbar from "@/components/general/Navbars/ClientNavbar/PeregrinClientNavbar";
import { Box } from "@mui/material";

const ExcursionsHistory = () => {
  return (
    <Box>
      <PeregrinClientNavbar />

      <Box sx={backButtonContainer}>
        <BackPageButton />
      </Box>
    </Box>
  );
};

export default ExcursionsHistory;
