import {
  Box,
  Chip,
  CircularProgress,
  Divider,
  LinearProgress,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import ClientNavbar from "../../../../components/general/Navbars/ClientNavbar";
import { useNavigate, useSearchParams } from "react-router-dom";
import useFetchExcursion from "../../../../hooks/entities/useFetchExcursion";
import BackPageButton, {
  backButtonContainer,
} from "../../../../components/general/buttons/BackPageButton";
import { PrimaryButton } from "../../../../components/general/buttons/PrimaryButton";
import { priceFormatter } from "../../../../utils/priceFormatter";
import { ExcursionServiceType } from "../../../../types/types";
import { formatDateString } from "../../../../utils/stringUtils";
import EventIcon from "@mui/icons-material/Event";
import ConfirmationNumberIcon from "@mui/icons-material/ConfirmationNumber";
import HotelIcon from "@mui/icons-material/Hotel";
import DirectionsBusIcon from "@mui/icons-material/DirectionsBus";
import RestaurantIcon from "@mui/icons-material/Restaurant";
import { sharedStyles } from "@/styles/shared";
import RichTextRenderer from "@/components/general/Tiptap/RichTextRenderer";
import ImageCarousel from "@/components/general/ImageCarousel";
import ExcursionServiceCard from "../../../../components/comercial/ExcursionServiceCard";
import { handleCreateUserExcursion } from "./functions";
import { useAuth } from "@/context/AuthProvider/useAuth";
import CollapseWrapper from "@/components/general/Tiptap/RichTextDescriptionWrapper";
import RoomIcon from "@mui/icons-material/Room";
import { useEffect, useMemo, useState } from "react";
import PeregrinClientNavbar from "@/components/general/Navbars/ClientNavbar/PeregrinClientNavbar";
import GroupIcon from "@mui/icons-material/Group";
import userExcursionApi from "@/services/api/UserExcursion";

const ExcursionPage = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { excursion, loading } = useFetchExcursion(searchParams.get("id"));

  const [quantity, setQuantity] = useState(1);

  const navigate = useNavigate();

  const { user } = useAuth();

  const handleCreateUserExcursionClick = async () => {
    try {
      userExcursionApi
        .createUserExcursion({
          userId: user.id,
          excursionId: excursion.id,
          companionsIds: [],
          quantity: quantity - 1,
        })
        .catch(() => alert("Não foi possível adquirir esta excursão"))
        .finally(() => navigate("/checkout"));
    } catch (error) {
      console.error(error);
    }
  };

  const handleCompanionsQuantityChange = (
    _: React.MouseEvent<HTMLElement>,
    operation: string
  ) => {
    setQuantity((prev) => {
      const newValue =
        operation === "-"
          ? Math.max(1, prev - 1)
          : Math.min(excursion.currentVacancies - 1, prev + 1);

      return newValue !== prev ? newValue : prev;
    });
  };

  const isExcursionAvailableYet =
    new Date(excursion?.startDate) > new Date() &&
    excursion?.currentVacancies > 1;

  const sectionDivider = (
    <Divider
      sx={{
        width: "100%",
        color: "text.secondary",
      }}
    />
  );

  const excursionShowcase = (
    <Box sx={{ ...sharedStyles.verticalContent, gap: 5 }}>
      <Box sx={sharedStyles.verticalContent}>
        <Box>
          <Typography variant="h5" fontWeight="bold">
            {excursion?.title}{" "}
            <span>
              <Chip label={excursion?.mainCategory} variant="outlined" />
            </span>
          </Typography>
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", md: "column", lg: "row" },
            gap: 5,
          }}
        >
          {excursion?.imageOriginal.length > 0 && (
            <Box
              sx={{
                ...sharedStyles.verticalContent,
                width: { xs: "100%", lg: "70%" },
              }}
            >
              <ImageCarousel imageUrls={excursion?.imageOriginal} />
            </Box>
          )}

          <Box
            sx={{
              ...sharedStyles.verticalContent,
              width: {
                xs: "100%",
                lg: excursion?.imageOriginal.length > 0 ? "30%" : "100%",
              },
              justifyContent: "space-between",
            }}
          >
            <Box sx={sharedStyles.verticalContent}>
              <Typography
                variant="h5"
                fontWeight="bold"
                color="primary"
              >{`R$ ${priceFormatter(excursion?.price)}`}</Typography>

              <Typography sx={sharedStyles.horizontalContent}>
                <EventIcon />

                <Typography>{`${formatDateString(
                  excursion?.startDate
                )} - ${formatDateString(excursion?.endDate)}`}</Typography>
              </Typography>

              <Box sx={sharedStyles.horizontalContent}>
                <ConfirmationNumberIcon />

                <Typography>{`${excursion?.currentVacancies} vagas disponíveis (máx. ${excursion?.maxVacancies})`}</Typography>
              </Box>

              <LinearProgress
                variant="determinate"
                value={
                  (excursion?.currentVacancies / excursion?.maxVacancies) * 100
                }
              />
            </Box>

            {isExcursionAvailableYet && (
              <Box sx={{ ...sharedStyles.verticalContent, marginY: 4 }}>
                <ToggleButtonGroup
                  color="primary"
                  value={quantity}
                  exclusive
                  onChange={handleCompanionsQuantityChange}
                  fullWidth
                >
                  <ToggleButton value="-" sx={{ maxWidth: "50px" }}>
                    -
                  </ToggleButton>

                  <TextField
                    value={quantity}
                    onChange={(e) => setQuantity(Number(e.target.value))}
                    InputProps={{
                      inputProps: {
                        min: 1,
                        max: excursion.currentVacancies - 1,
                      },
                    }}
                    type="number"
                    fullWidth
                    sx={{ maxWidth: "80px" }}
                  />

                  <ToggleButton value="+" sx={{ maxWidth: "50px" }}>
                    +
                  </ToggleButton>
                </ToggleButtonGroup>

                <Typography>
                  Subtotal:{" "}
                  <Typography variant="Span" fontWeight="bold" color="primary">
                    R$
                    {priceFormatter(excursion?.price * quantity)}
                  </Typography>
                </Typography>
              </Box>
            )}

            {isExcursionAvailableYet ? (
              <PrimaryButton
                label="Adquirir excursão"
                styles={{
                  ...sharedStyles.secondaryColor,
                  alignSelf: "stretch",
                }}
                onClickAction={handleCreateUserExcursionClick}
              />
            ) : (
              <Typography color="text.secondary">
                Esta excursão não está mais disponível
              </Typography>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );

  const excursionDetails = (
    <Box sx={sharedStyles.form}>
      {excursion?.description.length > 0 && (
        <Box width="100%">
          <CollapseWrapper
            label="Descrição da excursão"
            content={<RichTextRenderer htmlContent={excursion?.description} />}
          />
        </Box>
      )}

      {sectionDivider}

      <Typography variant="h6" fontWeight="bold">
        Quais serviços estão inclusos?
      </Typography>

      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", lg: "row" },
          gap: 2,
        }}
      >
        <ExcursionServiceCard
          serviceType={ExcursionServiceType.ACCOMMODATION}
          service={excursion?.accommodationService}
          icon={<HotelIcon />}
        />
        <ExcursionServiceCard
          serviceType={ExcursionServiceType.TRANSPORT}
          service={excursion?.transportService}
          icon={<DirectionsBusIcon />}
        />
        <ExcursionServiceCard
          serviceType={ExcursionServiceType.FOOD}
          service={excursion?.foodService}
          icon={<RestaurantIcon />}
        />
      </Box>

      <Typography variant="h6" fontWeight="bold">
        Restrição
      </Typography>

      <Typography>
        {excursion?.restriction.length > 0
          ? excursion?.restriction
          : "Esta excursão não possui restrições"}
      </Typography>

      {excursion?.router.length > 0 && (
        <>
          {sectionDivider}

          <Box sx={{ ...sharedStyles.verticalContent }}>
            <Typography variant="h5" fontWeight="bold">
              Roteiro
            </Typography>

            <ul>
              {excursion?.router.map((route, index) => (
                <li key={`${index}_${route}`}>{route}</li>
              ))}
            </ul>
          </Box>
        </>
      )}

      {sectionDivider}

      <Typography variant="h5" fontWeight="bold">
        Explore seu destino!
      </Typography>

      <PrimaryButton
        label={`${excursion?.city} - ${excursion?.state} (${excursion?.region}) | ${excursion?.country}`}
        icon={<RoomIcon />}
        onClickAction={() =>
          window.open(
            `https://www.google.com/maps/place/${excursion?.city} - ${excursion?.state} (${excursion?.region}) | ${excursion?.country}`,
            "_blank"
          )
        }
        styles={{ color: "secondary.main" }}
      />
    </Box>
  );

  return (
    <Box sx={{ overflowX: "hidden" }}>
      <PeregrinClientNavbar />

      <Box sx={backButtonContainer}>
        <BackPageButton />
      </Box>

      {!loading ? (
        <Box
          sx={{
            ...sharedStyles.form,
            justifyContent: "center",
            marginX: { xs: 3, md: 3, lg: 10 },
            marginBottom: 3,
          }}
        >
          {excursionShowcase}
          {excursionDetails}
        </Box>
      ) : (
        <Box sx={sharedStyles.clientMainContainer}>
          <CircularProgress />
        </Box>
      )}
    </Box>
  );
};

export default ExcursionPage;
