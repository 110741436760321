import {
  Box,
  Typography,
  List,
  ListItemButton,
  ListItemText,
  SxProps,
  Alert,
  Snackbar,
  CircularProgress,
  useMediaQuery,
} from "@mui/material";
import dayjs, { Dayjs } from "dayjs";
import { useState, lazy, Suspense } from "react";
import { AppointmentDetailsModal } from "../../../../components/general/AppointmentDetailsModal";
import AppointmentStatusChip from "../../../../components/general/AppointmentStatusChip";
import ClientNavbar from "../../../../components/general/Navbars/ClientNavbar";
import { IAppointment } from "../../../../services/api/Appointment/types";
import { formatDateAndTimeDayDateToString } from "../ScheduilingPage/timeUtils";
import useFetchClientAppointments from "../../../../hooks/entities/useFetchClientAppointments";
import { sharedStyles } from "../../../../styles/shared";
import {
  certainDateHasAppointments,
  isAppointmentFromCertainDate,
} from "../../../../utils/dateFunctions";
import { generalDarkGrey } from "../../../../styles/colors";
import { LeftMarginBackButton } from "@/components/general/buttons/BackPageButton";
const AppointmentsCalendar = lazy(
  () => import("../../../../components/general/AppointmentsCalendar")
);

const ClientAppointments = () => {
  const isMobile = useMediaQuery("(max-width:1000px)");

  const [selectedDate, setSelectedDate] = useState<Dayjs | null>(dayjs());

  const [succeededRatingPost, setSucceededRatingPost] = useState(false);

  const {
    userAppointments,
    userAppointmentsLoading,
    appointmentDates,
    getUserAppointmentsInfo,
  } = useFetchClientAppointments();

  const [selectedUserAppointment, setSelectedUserAppointment] = useState(null);

  const handleOpenModal = (selectedAppointment: IAppointment) =>
    setSelectedUserAppointment(selectedAppointment);

  const handleFinishService = () => getUserAppointmentsInfo();

  const AppointmentsModal = (
    <>
      <AppointmentDetailsModal
        appointment={selectedUserAppointment}
        onClose={() => setSelectedUserAppointment(null)}
        onFinish={handleFinishService}
        setSucceededRatingPost={setSucceededRatingPost}
      />
      <Snackbar
        open={succeededRatingPost}
        autoHideDuration={4000}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        onClose={() => setSucceededRatingPost(false)}
      >
        <Alert severity="success" variant="filled" sx={{ width: "100%" }}>
          Serviço avaliado com sucesso!
        </Alert>
      </Snackbar>
    </>
  );

  return (
    <Box sx={{ overflowX: "hidden" }}>
      {AppointmentsModal}
      <ClientNavbar hideMidButton />

      <LeftMarginBackButton />

      <Box sx={styles.mainContainer}>
        {!userAppointmentsLoading ? (
          <>
            <Typography variant="h5" fontWeight="bold">
              Seus agendamentos
            </Typography>
            <Box
              sx={{
                ...sharedStyles.dividedFormContainer,
                flexDirection: isMobile ? "column" : "row",
              }}
            >
              <Suspense fallback={"carregando..."}>
                <AppointmentsCalendar
                  pendentAppointmentDates={appointmentDates?.pendent}
                  confirmedAppointmentDates={appointmentDates?.confirmed}
                  finishedAppointementDates={appointmentDates?.finished}
                  canceledAppointmentDates={appointmentDates?.canceled}
                  selectedDate={selectedDate}
                  setSelectedDate={setSelectedDate}
                />
              </Suspense>

              <Box
                width={isMobile ? "100%" : "38%"}
                sx={{ ...sharedStyles.verticalContent }}
              >
                <Typography variant="h6" fontWeight={500}>
                  Dia {selectedDate?.format("DD/MM/YYYY")}
                </Typography>

                {certainDateHasAppointments(userAppointments, selectedDate) ? (
                  <List
                    sx={{
                      ...sharedStyles.appointmentsList,
                      height: "398px",
                      overflowY: "auto",
                    }}
                    disablePadding
                  >
                    {userAppointments.map(
                      (appointment, index) =>
                        isAppointmentFromCertainDate(
                          appointment,
                          selectedDate
                        ) && (
                          <ListItemButton
                            onClick={() => handleOpenModal(appointment)}
                            key={appointment.id}
                            sx={sharedStyles.horizontalContent}
                          >
                            <ListItemText
                              primary={`${userAppointments[index].serviceEmployee.ServiceStore.Service.name} `}
                              secondary={`${formatDateAndTimeDayDateToString(
                                userAppointments[index].date
                              )} com ${
                                userAppointments[index].serviceEmployee.Employee
                                  .User.name
                              } (${
                                userAppointments[index].serviceEmployee.Employee
                                  .store.name
                              }) `}
                              primaryTypographyProps={{ fontWeight: 500 }}
                            />
                            <AppointmentStatusChip
                              status={userAppointments[index].status}
                            />
                          </ListItemButton>
                        )
                    )}
                  </List>
                ) : (
                  <Box>
                    <Typography color={generalDarkGrey}>
                      Nenhum agendamento para esta data.
                    </Typography>
                  </Box>
                )}
              </Box>
            </Box>
          </>
        ) : (
          <Box>
            <CircularProgress />
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default ClientAppointments;

const styles = {
  mainContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    gap: "30px",
    margin: "0px 30px 80px 30px",
  } as SxProps,

  appointmentsBoard: {
    display: "flex",
    justifyContent: "center",
    gap: "30px",

    "@media(max-width:1000px)": {
      flexDirection: "column",
    },
  } as SxProps,
};
