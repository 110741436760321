import { CSSProperties } from "react";
import peregrinFullBrandLogo from "@/assets/svg/peregrin-full-logo.svg";

interface FullBrandLogoProps {
  color?: string;
  style?: CSSProperties;
}

const FullBrandLogo = (props: FullBrandLogoProps) => {
  const { color, style } = props;

  return (
    <img
      src={peregrinFullBrandLogo}
      style={{ ...style, filter: color }}
      alt="Peregrin full logo"
    />
  );
};

export default FullBrandLogo;
