import { Box, SxProps } from "@mui/material";
import { useEffect, useState } from "react";
import ClientNavbar from "../../../components/general/Navbars/ClientNavbar";
import { cleanLocalStorage } from "../../../utils/cleanLocalStorage";
import { useAuth } from "../../../context/AuthProvider/useAuth";
import { fetchNearbyStores, getUserAddressInCoordinates } from "./functions";
import NearbyStoreList from "../../../components/pet-beauty/NearbyStoreList";
import NearbyStoresMap from "../../../components/general/NearbyStoresMap";
import BottomSheet from "../../../components/general/BottomSheet";
import { CURRENT_SUBDOMAIN, DEMANDORIA_SUBDOMAINS } from "@/utils/stringUtils";
import PeregrinClientNavbar from "@/components/general/Navbars/ClientNavbar/PeregrinClientNavbar";

const Home = () => {
  cleanLocalStorage();

  const auth = useAuth();

  const [nearbyStores, setNearbyStores] = useState([]);

  const [map, setMap] = useState(null);
  const [userCoordinates, setUserCoordinates] = useState<
    [number, number] | null
  >(null);
  const [mapCenter, setMapCenter] = useState<[number, number]>([0, 0]);

  const onGetUserLocationSuccess = (position) =>
    setUserCoordinates([position.coords.latitude, position.coords.longitude]);

  const onGetUserLocationError = async () => {
    const { latitude, longitude } = await getUserAddressInCoordinates(
      auth.user
    );
    setUserCoordinates([latitude, longitude]);
  };

  const getUserLocation = () => {
    if ("geolocation" in navigator)
      navigator.geolocation.getCurrentPosition(
        onGetUserLocationSuccess,
        onGetUserLocationError
      );
  };

  const loadStoreList = async () => {
    const stores = await fetchNearbyStores(
      userCoordinates[0],
      userCoordinates[1]
    );

    setNearbyStores(stores);
  };

  useEffect(() => {
    if (auth.user.id) getUserLocation();
  }, [auth.user.id]);

  useEffect(() => {
    if (userCoordinates !== null) {
      loadStoreList();
      setMapCenter(userCoordinates);
    }
  }, [userCoordinates]);

  const renderNearbyStoreList = (
    <NearbyStoreList
      stores={nearbyStores}
      userCoordinates={userCoordinates}
      mapReference={map}
    />
  );

  const renderMap = (
    <NearbyStoresMap
      stores={nearbyStores}
      userCoordinates={userCoordinates}
      mapCenter={mapCenter}
      setMap={setMap}
    />
  );

  return (
    <Box sx={{ overflow: "hidden" }}>
      {CURRENT_SUBDOMAIN === DEMANDORIA_SUBDOMAINS[2] ? (
        <PeregrinClientNavbar />
      ) : (
        <ClientNavbar />
      )}

      <Box>
        <BottomSheet>
          <Box sx={styles.storeList}>{renderNearbyStoreList}</Box>
        </BottomSheet>

        {renderMap}
      </Box>
    </Box>
  );
};

export default Home;

const styles = {
  storeList: {} as SxProps,
};
