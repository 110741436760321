import { Box, Divider, Typography } from "@mui/material";
import AddImageInput from "../AddImageInput";
import { Dispatch, ReactNode, SetStateAction } from "react";
import { sharedStyles } from "@/styles/shared";

interface ImageGalleryHeaderProps {
  icon?: ReactNode;
  setImages: Dispatch<SetStateAction<HTMLCanvasElement[]>>;
}

const ImageGalleryHeader = (props: ImageGalleryHeaderProps) => {
  const { icon, setImages } = props;

  return (
    <Box sx={{ ...sharedStyles.verticalContent, gap: 1 }}>
      <Box
        sx={{
          ...sharedStyles.horizontalContent,
          justifyContent: "space-between",
        }}
      >
        <Typography variant="h6" fontWeight="bold">
          Galeria de fotos
        </Typography>
        
        <AddImageInput setImages={setImages} icon={icon} />
      </Box>

      <Divider />
    </Box>
  );
};

export default ImageGalleryHeader;
