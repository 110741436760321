import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField/TextField";
import { Dispatch, SetStateAction } from "react";

interface SearchAutocompleteProps {
  options: any;
  label: string;
  value: string;
  setValue: Dispatch<SetStateAction<string>>;
  defaultValue?: string;
  error?: boolean;
}

const SearchAutocomplete = (props: SearchAutocompleteProps) => {
  const { options, label, value, setValue, defaultValue, error } = props;

  return (
    <Autocomplete
      freeSolo
      options={options}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          size="small"
          sx={{ width: "100%" }}
          error={error}
          helperText={error && "A busca não pode ser vazia."}
        />
      )}
      inputValue={value || ""}
      onInputChange={(_, value) => setValue(value)}
      defaultValue={defaultValue}
    />
  );
};

export default SearchAutocomplete;
