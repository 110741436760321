import { setLocalStorageItem } from "../../../../../hooks/localStorageHooks";
import storeApi from "../../../../../services/api/Store";
import { IStore } from "../../../../../services/api/Store/types";
import { clearFirebaseFolder } from "../../../../../utils/firebase/functions";
import { CURRENT_SUBDOMAIN } from "../../../../../utils/stringUtils";

export const handleSelectStore = (store: IStore) =>
  setLocalStorageItem("selectedStore", store);

export const handleDeleteStore = async (store: IStore) => {
  if (window.confirm(`Tem certeza que deseja excluir ${store.name}?`)) {
    try {
      const storeDeletionPromises = [];

      storeDeletionPromises.push(
        clearFirebaseFolder(
          `${CURRENT_SUBDOMAIN}/stores/${store.name}_${store.id}`
        )
      );
      storeDeletionPromises.push(storeApi.deleteStore(store.id));

      await Promise.all(storeDeletionPromises)
    } catch (error) {
      console.error("Error deleting the store: ", error);
    }
  }
};
