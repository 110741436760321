import requests from "../util";
import { UserExcursion } from "./type";

const url = "user-excursion";

const userExcursionApi = {
  createUserExcursion: (
    userExcursion: Partial<UserExcursion>
  ): Promise<UserExcursion> => requests.post(url, userExcursion),
};

export default userExcursionApi;
