import { Box, Typography } from "@mui/material";

const EmptyGallery = () => (
  <Box width="100%">
    <Typography textAlign="center" color="text.secondary">
      Nenhuma imagem selecionada
    </Typography>
  </Box>
);

export default EmptyGallery;
