import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import Toolbar from "@mui/material/Toolbar";
import MenuIcon from "@mui/icons-material/Menu";
import { useNavigate } from "react-router-dom";
import { AccountCircle } from "@mui/icons-material";
import { generalWhite } from "../../../styles/colors";
import { CssBaseline, Tooltip } from "@mui/material";
import { useState } from "react";
import Sidebar from "./Sidebar";

const DRAWER_WIDTH = 300;

interface ComercialDrawerProps {
  content: any;
  window?: () => Window;
}

const ComercialDrawer = (props: ComercialDrawerProps) => {
  const navigate = useNavigate();

  const { window } = props;
  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => setMobileOpen(!mobileOpen);

  const handleMyProfileClick = () => navigate("/user/details");

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{
          backgroundColor: generalWhite,
          boxShadow: "none",
          border: "none",
        }}
      >
        <Toolbar sx={{ display: "flex", justifyContent: "space-between" }}>
          <IconButton
            onClick={handleDrawerToggle}
            sx={{ display: { sm: "none" } }}
          >
            <MenuIcon sx={{ color: "text.primary" }} />
          </IconButton>

          <Box sx={{ flex: 1 }} />

          <Tooltip title="Meus dados">
            <IconButton size="large" onClick={handleMyProfileClick}>
              <AccountCircle sx={{ color: "text.primary" }} />
            </IconButton>
          </Tooltip>
        </Toolbar>
      </AppBar>

      <Box
        component="nav"
        sx={{ width: { sm: DRAWER_WIDTH }, flexShrink: { sm: 0 } }}
      >
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              width: DRAWER_WIDTH,
            },
          }}
        >
          <Sidebar />
        </Drawer>

        <Drawer
          variant="permanent"
          sx={{
            display: { xs: "none", sm: "block" },
            "& .MuiDrawer-paper": {
              minWidth: DRAWER_WIDTH,
            },
          }}
          open
        >
          <Sidebar />
        </Drawer>
      </Box>

      <Box
        sx={{
          padding: "70px 20px 20px 20px",
          flexGrow: 1,
          width: `calc(100% - ${DRAWER_WIDTH}px)`,
        }}
      >
        {props.content}
      </Box>
    </Box>
  );
};

export default ComercialDrawer;
