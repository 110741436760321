import { Box, Typography, TextField, SxProps } from "@mui/material";
import { CSSProperties, useRef, useState } from "react";
import InputMask from "react-input-mask";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import PasswordRecoveryIcon from "../../../assets/img/PasswordRecovery.png";
import {
  firstStepPasswordRecoverySchema,
  secondStepPasswordRecoverySchema,
} from "../../../utils/yupSchemas";
import { useNavigate } from "react-router-dom";
import { userApi } from "../../../services";
import { PrimaryButton } from "../../../components/general/buttons/PrimaryButton";
import { SecondaryButton } from "../../../components/general/buttons/SecondaryButton";
import BackPageButton, {
  backButtonContainer,
} from "../../../components/general/buttons/BackPageButton";
import ClientNavbar from "../../../components/general/Navbars/ClientNavbar";
import { sharedStyles } from "@/styles/shared";

const PasswordRecovery = () => {
  const navigate = useNavigate();

  const [
    selectedPasswordEditionStepIndex,
    setSelectedPasswordEditionStepIndex,
  ] = useState(0);

  const [selectedEmailForPasswordEdition, setSelectedEmailForPasswordEdition] =
    useState("");

  const emailRef = useRef<HTMLInputElement>(null);

  const [isVerificationCodeValid, setIsVerificationCodeValid] =
    useState<boolean>(true);
  const [isEmailAddressValid, setIsEmailAddressValid] = useState<boolean>(true);

  const verificationCodeRef = useRef<HTMLInputElement>(null);

  const newPasswordRef = useRef<HTMLInputElement>(null);
  const confirmedPasswordRef = useRef<HTMLInputElement>(null);

  const isFirstStepActive = () => selectedPasswordEditionStepIndex === 0;

  const {
    register,
    handleSubmit: onSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(
      isFirstStepActive()
        ? firstStepPasswordRecoverySchema
        : secondStepPasswordRecoverySchema
    ),
  });

  const handleFirstStepSubmit = async () => {
    setIsEmailAddressValid(true);

    setSelectedEmailForPasswordEdition(emailRef.current?.value);
    const emailRequestBody = { email: emailRef.current?.value };

    try {
      await userApi.sendPasswordRecoveryCode(emailRequestBody);
      setSelectedPasswordEditionStepIndex(1);
    } catch (error) {
      console.warn(
        `Error sending verification code to ${selectedEmailForPasswordEdition}: `,
        error
      );
      setIsEmailAddressValid(false);
    }
  };

  const handleSecondStepSubmit = async () => {
    const formattedVerificationCode =
      verificationCodeRef.current?.value.replaceAll(/[- _]/g, "");

    setIsVerificationCodeValid(!(formattedVerificationCode?.length !== 6));

    const validationRequestBody = {
      token: formattedVerificationCode,
      email: selectedEmailForPasswordEdition,
      password: newPasswordRef.current?.value,
    };

    try {
      await userApi.validatePasswordRecovery(validationRequestBody);
      navigate("/login");
      alert("Senha alterada com sucesso!");
    } catch (error) {
      console.warn(`Error confirming the new password: `, error);
      setIsVerificationCodeValid(false);
    }
  };

  const renderFirstStepPasswordEdition = (
    <>
      <TextField
        label="Digite seu email"
        inputRef={emailRef}
        {...register("email")}
        error={!!errors.email ? errors.email : !isEmailAddressValid}
        helperText={
          errors.email?.message
            ? errors.email?.message
            : !isEmailAddressValid && "Email não cadastrado."
        }
        fullWidth
      />

      <PrimaryButton
        label="Confirmar"
        icon={null}
        styles={sharedStyles.secondaryColor}
      />
    </>
  );

  const renderSecondStepPasswordEdition = (
    <>
      <InputMask
        mask="9  -  9  -  9  -  9  -  9  -  9"
        disabled={false}
        maskChar="_"
        inputRef={verificationCodeRef}
        error={!isVerificationCodeValid}
        helperText={
          !isVerificationCodeValid && "Código de verificação inválido."
        }
      >
        <TextField label="Código de verificação" fullWidth />
      </InputMask>

      <TextField
        label="Digite a nova senha *"
        type="password"
        {...register("password")}
        error={!!errors.password}
        helperText={errors.password?.message}
        inputRef={newPasswordRef}
        fullWidth
      />

      <TextField
        label="Confirme sua senha *"
        type="password"
        inputRef={confirmedPasswordRef}
        fullWidth
        {...register("confirmedPassword")}
        error={!!errors.confirmedPassword}
        helperText={errors.confirmedPassword?.message}
      />

      <Box sx={sharedStyles.verticalContent}>
        <PrimaryButton
          label="Alterar senha"
          styles={sharedStyles.secondaryColor}
        />
        <SecondaryButton
          label="Voltar"
          onClickAction={() => {
            setSelectedPasswordEditionStepIndex(0);
            setIsVerificationCodeValid(true);
          }}
        />
      </Box>
    </>
  );

  return (
    <Box>
      <ClientNavbar hideMidButton hideMenuButton />

      <Box sx={backButtonContainer}>
        <BackPageButton />
      </Box>

      <Box sx={mainContainer}>
        <Box
          sx={{
            ...sharedStyles.verticalContent,
            maxWidth: "500px",
            alignItems: "center",
            gap: 5,
          }}
        >
          <img
            src={PasswordRecoveryIcon}
            alt="password recovery"
            width={120}
            loading="lazy"
          />

          <Box
            sx={{
              ...sharedStyles.verticalContent,
              gap: 1,
              textAlign: "center",
            }}
          >
            <Typography variant="h5" fontWeight="bold">
              {isFirstStepActive()
                ? "Recuperação de senha"
                : "Verifique o código enviado para:"}
            </Typography>

            <Typography>
              {isFirstStepActive()
                ? "Digite o email para enviarmos o código de verificação"
                : selectedEmailForPasswordEdition}
            </Typography>
          </Box>

          <form
            onSubmit={onSubmit(
              isFirstStepActive()
                ? handleFirstStepSubmit
                : handleSecondStepSubmit
            )}
            style={{ ...sharedStyles.verticalContent, gap: 20, width: "100%" }}
          >
            {isFirstStepActive()
              ? renderFirstStepPasswordEdition
              : renderSecondStepPasswordEdition}
          </form>
        </Box>
      </Box>
    </Box>
  );
};

export default PasswordRecovery;

const mainContainer: SxProps = {
  ...sharedStyles.verticalContent,
  height: "auto",
  alignItems: "center",
  justifyContent: "center",
  marginY: 5,
};
