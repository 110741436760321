import SearchIcon from "@mui/icons-material/Search";
import {
  Badge,
  Box,
  CircularProgress,
  IconButton,
  List,
  ListItem,
  ListItemText,
  SxProps,
  Typography,
} from "@mui/material";
import dayjs, { Dayjs } from "dayjs";
import "dayjs/locale/pt-br";
import { lazy, useState } from "react";
import defaultEmployeeImage from "../../../../assets/svg/defaultUser.svg";
import defaultServiceImage from "../../../../assets/svg/defaultService.svg";
import defaultStoreImage from "../../../../assets/svg/defaultStore.svg";
import utc from "dayjs/plugin/utc";
import { useNavigate, useSearchParams } from "react-router-dom";
import ClientNavbar from "../../../../components/general/Navbars/ClientNavbar";
import { IEmployee } from "../../../../services/api/Employee/types";
import serviceStoreApi from "../../../../services/api/ServiceStore";
import { IServiceStore } from "../../../../services/api/ServiceStore/types";
import { IStore } from "../../../../services/api/Store/types";
import { priceFormatter } from "../../../../utils/priceFormatter";
import {
  IPromotionalSearchResult,
  IPromotionalSearchResultArray,
  IPromotionObject,
} from "../../../../services/api/Appointment/types";
import DmCalendar from "../../../../components/general/DmCalendar";
import { PrimaryButton } from "../../../../components/general/buttons/PrimaryButton";
import { shortenLargeTexts } from "../../../../utils/stringUtils";
import { PickersDay } from "@mui/x-date-pickers";
import useSearchStores from "../../../../hooks/search/useSearchStores";
import useSearchEmployees from "../../../../hooks/search/useSearchEmployees";
import useSearchServiceStores from "../../../../hooks/search/useSearchServiceStores";
import SearchAutocomplete from "../../../../components/general/SearchAutocomplete";
import {
  getLocalStorageItem,
  setLocalStorageItem,
} from "../../../../hooks/localStorageHooks";
import SearchTabs from "../../../../components/general/SearchTabs";
import tabsInfo from "./tabs";
import DateSelector from "../../../../components/general/DateSelector";
import useSearchPromotionalServices from "../../../../hooks/search/useSearchPromotionalServices";
import { searchPromotionalServices } from "../../../../utils/fetchEntities";
import {
  getPromotionalDatesToHighlight,
  getSingleDatePromotionSchedule,
} from "./functions";
const CustomCard = lazy(
  () => import("../../../../components/general/CustomCard")
);

dayjs.extend(utc);

const CURRENT_DAY_INDEX = new Date().getDay();

const today = dayjs();

const SearchPage = () => {
  const navigate = useNavigate();

  const [searchParams, setSearchParams] = useSearchParams();

  const serviceSearchParam = searchParams.get("service");
  const storeSearchParam = searchParams.get("store");
  const promotionalSearchStartDateParam = searchParams.get("startDate");
  const promotionalSearchEndDateParam = searchParams.get("endDate");
  const employeeSearchParam = searchParams.get("employee");

  const searchTypeInitialIndex = Number(getLocalStorageItem("searchType")) ?? 3;

  const [promotionalSearchInput, setPromotionalSearchInput] =
    useState<string>("");
  const { promotionalSearchAutocomplete } = useSearchPromotionalServices(
    promotionalSearchInput
  );

  const [initialPromotionalSearchDate, setInitialPromotionalSearchDate] =
    useState<Dayjs | null>(
      promotionalSearchStartDateParam
        ? dayjs.utc(promotionalSearchStartDateParam)
        : today
    );
  const [endingPromotionalSearchDate, setEndingPromotionalSearchDate] =
    useState<Dayjs | null>(
      dayjs.utc(promotionalSearchEndDateParam ?? initialPromotionalSearchDate)
    );

  const [highlightedDays, setHighlightedDays] = useState([]);
  const [mainPromotions, setMainPromotions] = useState<IPromotionObject[]>([]);
  const [allPromotionResults, setAllPromotionResults] = useState<
    IPromotionalSearchResultArray[]
  >([]);

  const [hasPromotionalSearchedOnce, setHasPromotionalSearchedOnce] =
    useState(false);
  const [promotionalLoading, setPromotionalLoading] = useState(false);

  const [selectedDate, setSelectedDate] = useState<Dayjs>(dayjs());

  const handlePromotionalSearch = async () => {
    if (promotionalSearchInput.length > 0) {
      if (!hasPromotionalSearchedOnce) setHasPromotionalSearchedOnce(true);

      setSearchParams({
        service: promotionalSearchInput,
        startDate: initialPromotionalSearchDate.format("YYYY-MM-DD"),
        endDate: endingPromotionalSearchDate.format("YYYY-MM-DD"),
      });

      setPromotionalLoading(true);

      const promotionalResponse = await searchPromotionalServices(
        promotionalSearchAutocomplete[0]?.id,
        initialPromotionalSearchDate.toISOString(),
        endingPromotionalSearchDate.toISOString()
      );

      if (promotionalResponse) {
        setHighlightedDays(
          getPromotionalDatesToHighlight(promotionalResponse.promotion)
        );
        setMainPromotions(promotionalResponse.promotion);
        setAllPromotionResults(promotionalResponse.schedule);
      }

      setPromotionalLoading(false);
    }
  };

  const handleSelectPromotionalService = async (
    promotionalSearchResult: IPromotionalSearchResult
  ) => {
    const serviceStoreResponse =
      await serviceStoreApi.getServiceStoresFromServiceNameAndStoreId(
        promotionalSearchResult.serviceName,
        promotionalSearchResult.storeId
      );

    handleSelectServiceClick(serviceStoreResponse[0].id);
  };

  const [serviceSearchInput, setServiceSearchInput] = useState<string>("");
  const { serviceStores, serviceStoresLoading } =
    useSearchServiceStores(serviceSearchInput);

  const handleSelectServiceClick = (serviceStoreId: string) => {
    setLocalStorageItem("searchType", searchTypeIndex);
    navigate(
      `/service?id=${serviceStoreId}&selectedDate=${
        selectedDate.toISOString().split("T")[0]
      }`
    );
  };

  const [storeSearchInput, setStoreSearchInput] = useState<string>("");
  const { stores, storesLoading } = useSearchStores(storeSearchInput);

  const handleSelectStoreClick = (storeId: string) => {
    setLocalStorageItem("searchType", searchTypeIndex);
    navigate(`/store?id=${storeId}`);
  };

  const [employeeSearchInput, setEmployeeSearchInput] = useState<string>("");
  const { employees, employeesLoading } =
    useSearchEmployees(employeeSearchInput);

  const handleSelectEmployeeClick = (employeeId: string) => {
    setLocalStorageItem("searchType", searchTypeIndex);
    navigate(`/employee?id=${employeeId}`);
  };

  const [searchTypeIndex, setSearchTypeIndex] = useState<number>(
    searchTypeInitialIndex
  );

  const renderResultsFoundedTypography = (resultsNumber: number) => {
    return (
      <Typography
        variant="h6"
        fontWeight="bold"
      >{`Resultados encontrados (${resultsNumber}):`}</Typography>
    );
  };

  const promotionalSearchResults = (
    <>
      {allPromotionResults[0]?.result.length > 0 ? (
        <Box sx={styles.searchResultsContainer}>
          <Box sx={styles.promotionalSearchCalendarAndPricesContainer}>
            <DmCalendar
              selectedDate={selectedDate}
              setSelectedDate={setSelectedDate}
              highlightedDays={highlightedDays}
              returnFunction={(props) => {
                const { day } = props;
                const iterableDate = day.format("YYYY-MM-DD");
                const isHighlighted = highlightedDays.includes(iterableDate);

                return (
                  <Badge
                    key={day.toString()}
                    overlap={isHighlighted ? "circular" : undefined}
                    variant={isHighlighted ? "dot" : undefined}
                    color={isHighlighted ? "success" : undefined}
                  >
                    <PickersDay {...props} day={day} />
                  </Badge>
                );
              }}
            />
            <Box>
              <Typography variant="h6">
                Melhores preços para {selectedDate?.format("DD/MM/YYYY")}
              </Typography>
              <Typography>Serviço: {serviceSearchParam ?? ""}</Typography>

              <List sx={styles.bestPricesPromotionalSearchList}>
                {getSingleDatePromotionSchedule(
                  selectedDate,
                  allPromotionResults
                )?.map((promotionalSearchResult, index) => (
                  <ListItem key={index}>
                    <ListItemText
                      primary={shortenLargeTexts(
                        promotionalSearchResult.storyName,
                        18
                      )}
                      secondary={`R$ ${priceFormatter(
                        promotionalSearchResult.price
                      )}`}
                      secondaryTypographyProps={{
                        fontWeight: "bold",
                        color: "primary",
                      }}
                    />

                    <Box>
                      <PrimaryButton
                        onClickAction={() => {
                          handleSelectPromotionalService(
                            promotionalSearchResult
                          );
                        }}
                        label="Agendar"
                      />
                    </Box>
                  </ListItem>
                ))}
              </List>
            </Box>
          </Box>
        </Box>
      ) : (
        <Typography variant="h6">
          Nenhuma promoção encontrada para este serviço.
        </Typography>
      )}
    </>
  );

  const promotionalSearch = (
    <Box sx={styles.searchContainer}>
      <Box sx={styles.autoCompleteContainer}>
        <SearchAutocomplete
          defaultValue={serviceSearchParam ?? ""}
          label="Qual serviço você deseja?"
          options={promotionalSearchAutocomplete.map((service) => service.name)}
          value={promotionalSearchInput}
          setValue={setPromotionalSearchInput}
          error={
            promotionalSearchInput.length === 0 && hasPromotionalSearchedOnce
          }
        />
      </Box>

      <Box sx={styles.datesContainer}>
        <Box sx={styles.dateInput}>
          <DateSelector
            value={initialPromotionalSearchDate}
            setValue={setInitialPromotionalSearchDate}
            disablePast
            label="Data de início"
          />
        </Box>
        {"→"}
        <Box sx={styles.dateInput}>
          <DateSelector
            value={endingPromotionalSearchDate}
            setValue={setEndingPromotionalSearchDate}
            disablePast
            label="Data final"
            minDate={initialPromotionalSearchDate}
          />
        </Box>
        <IconButton
          color="primary"
          sx={styles.searchIcon}
          onClick={handlePromotionalSearch}
        >
          <SearchIcon />
        </IconButton>
      </Box>

      {!hasPromotionalSearchedOnce ? null : (
        <Box sx={{ margin: "20px" }}>
          {!promotionalLoading ? (
            promotionalSearchResults
          ) : (
            <CircularProgress />
          )}
        </Box>
      )}
    </Box>
  );

  const storeSearchResults = (
    <Box sx={styles.searchResultsGrid}>
      {stores.map((store: IStore, index) => (
        <Box onClick={() => handleSelectStoreClick(store.id)} key={index}>
          <CustomCard
            photoURL={store.imagePreview[0]}
            header={store.name}
            secondaryText={store.description}
            defaultPhoto={defaultStoreImage}
            primaryText={""}
          />
        </Box>
      ))}
    </Box>
  );

  const storeSearch = (
    <Box sx={styles.searchContainer}>
      <Box sx={styles.autoCompleteContainer}>
        <SearchAutocomplete
          defaultValue={storeSearchParam ?? ""}
          label="Busque por um estabelecimento"
          options={stores.map((store) => store.name)}
          value={storeSearchInput}
          setValue={setStoreSearchInput}
        />
      </Box>

      {!storesLoading ? (
        <>
          {stores.length > 0 ? (
            <Box sx={styles.searchResultsContainer}>
              {renderResultsFoundedTypography(stores.length)}
              {storeSearchResults}
            </Box>
          ) : storeSearchInput.length > 0 ? (
            <Box sx={styles.searchResultsContainer}>
              <Typography variant="h6" textAlign="center">
                Nenhum estabelecimento encontrado.
              </Typography>
            </Box>
          ) : null}
        </>
      ) : (
        <>
          <CircularProgress sx={styles.searchLoadingContainer} />
        </>
      )}
    </Box>
  );

  const serviceSearchResults = (
    <Box sx={styles.searchResultsGrid}>
      {serviceStores.map((serviceStore: IServiceStore, index) => (
        <Box
          onClick={() => handleSelectServiceClick(serviceStore.id)}
          key={index}
        >
          <CustomCard
            photoURL={serviceStore.imagePreview[0]}
            header={`R$ ${priceFormatter(
              serviceStore.priceDay[CURRENT_DAY_INDEX]
            )}`}
            primaryText={serviceStore.Service.name}
            secondaryText={serviceStore.Store.name}
            defaultPhoto={defaultServiceImage}
          />
        </Box>
      ))}
    </Box>
  );

  const serviceSearch = (
    <Box sx={styles.searchContainer}>
      <Box sx={styles.autoCompleteContainer}>
        <SearchAutocomplete
          defaultValue={serviceSearchParam ?? ""}
          label="Qual serviço você deseja?"
          options={serviceStores.map(
            (serviceStore) => serviceStore.Service.name
          )}
          value={serviceSearchInput}
          setValue={setServiceSearchInput}
        />
      </Box>

      {!serviceStoresLoading ? (
        <>
          {serviceStores.length > 0 ? (
            <Box sx={styles.searchResultsContainer}>
              {renderResultsFoundedTypography(serviceStores.length)}
              {serviceSearchResults}
            </Box>
          ) : serviceSearchInput.length > 0 ? (
            <Box sx={styles.searchResultsContainer}>
              <Typography variant="h6">Nenhum serviço encontrado.</Typography>
            </Box>
          ) : null}
        </>
      ) : (
        <>
          <CircularProgress sx={styles.searchLoadingContainer} />
        </>
      )}
    </Box>
  );

  const employeeSearchResults = (
    <Box sx={styles.searchResultsGrid}>
      {employees.map((employee: IEmployee, index) => (
        <Box
          key={index}
          onClick={() => {
            handleSelectEmployeeClick(employee.id);
          }}
        >
          <CustomCard
            photoURL={employee.User.imagePreview}
            header={employee.User.name}
            defaultPhoto={defaultEmployeeImage}
            primaryText={""}
            secondaryText={""}
          />
        </Box>
      ))}
    </Box>
  );

  const employeeSearch = (
    <Box sx={styles.searchContainer}>
      <Box sx={styles.autoCompleteContainer}>
        <SearchAutocomplete
          defaultValue={employeeSearchParam ?? ""}
          label="Busque por um profissional"
          options={employees.map((employee) => employee.User.name)}
          value={employeeSearchInput}
          setValue={setEmployeeSearchInput}
        />
      </Box>

      {!employeesLoading ? (
        <>
          {employees.length > 0 ? (
            <Box sx={styles.searchResultsContainer}>
              {renderResultsFoundedTypography(employees.length)}
              {employeeSearchResults}
            </Box>
          ) : employeeSearchInput.length > 0 ? (
            <Box sx={styles.searchResultsContainer}>
              <Typography variant="h6">
                Nenhum profissional encontrado.
              </Typography>
            </Box>
          ) : null}
        </>
      ) : (
        <>
          <CircularProgress sx={styles.searchLoadingContainer} />
        </>
      )}
    </Box>
  );

  return (
    <Box>
      <Box sx={{ overflowX: "hidden" }}>
        <ClientNavbar hideMidButton />

        <SearchTabs
          value={searchTypeIndex}
          setValue={setSearchTypeIndex}
          tabs={tabsInfo}
        />
      </Box>
      <Box
        width="100%"
        height="100%"
        alignItems="center"
        justifyContent="center"
      >
        {searchTypeIndex === 0
          ? promotionalSearch
          : searchTypeIndex === 1
            ? storeSearch
            : searchTypeIndex === 2
              ? serviceSearch
              : employeeSearch}
      </Box>
    </Box>
  );
};

export default SearchPage;

const styles = {
  searchContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    gap: 2,
    alignItems: "center",
    margin: "40px 0px",
    padding: "0px 20px",
  } as SxProps,

  searchResultsContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
  } as SxProps,

  searchResultsGrid: {
    display: "grid",
    maxWidth: "100%",
    gridTemplateColumns: "repeat(auto-fit, minmax(300px, 1fr))",
    margin: "20px 0px",
    rowGap: 3,
    columnGap: 3,
  } as SxProps,

  autoCompleteContainer: { maxWidth: "450px", width: "100%" } as SxProps,

  dateInput: { width: "100%" } as SxProps,

  datesContainer: {
    display: "flex",
    alignItems: "center",
    gap: 1.5,
    maxWidth: "450px",
  } as SxProps,

  searchIcon: { width: "50px", height: "50px" } as SxProps,

  bestPromotionContainer: {
    display: "flex",
    flexDirection: "column",
    gap: 2,
  } as SxProps,

  bestPromotionCardContent: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  } as SxProps,

  promotionalSearchResultsDivider: {
    margin: "20px 0px",
  } as SxProps,

  promotionalSearchCalendarAndPricesContainer: {
    display: "flex",
    "@media(max-width:700px)": { flexDirection: "column" },
    justifyContent: "space-between",
  } as SxProps,

  bestPricesPromotionalSearchList: {
    margin: "20px 0px",
    width: "100%",
    maxHeight: "240px",
    "@media(max-width:700px)": {
      maxHeight: "400px",
    },
    overflowY: "auto",
    boxShadow:
      "inset 0px 4px 0px 0px rgba(0, 0, 0, 0.05), inset 0px -4px 0px 0px rgba(0, 0, 0, 0.05)",
  } as SxProps,

  searchLoadingContainer: { margin: "35px 0px" } as SxProps,
};
